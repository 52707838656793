export const resultData = {
  ISTJ: {
    tag: '<h1>ISTJ（内倾、感觉、思维和判断）</h1><h2>从容不迫地做好自己的工作</h2>',
    subscribe:
      '<p>1.严肃、安静、藉由集中心 志与全力投入、及可被信赖获致成功。</p><p>2.行事务实、有序、实际 、 逻辑、真实及可信赖。</p><p>3.十分留意且乐于任何事（工作、居家、生活均有良好组织及有序。</p><p>4.负责任。</p><p>5.照设定成效来作出决策且不畏阻挠与闲言会坚定为之。</p><p>6.重视传统与忠诚。</p><p>7.传统性的思考者或经理。</p>',
    ms: `<p>ISTJ型的人是严肃的、有责任心的和通情达理的社会坚定分子。他们值得信赖，他们重视承诺，对他们来说，言语就是庄严的宣誓。</p>
    <p>ISTJ型的人工作缜密，讲求实际，很有头脑也很现实。他们具有很强的集中力、条理性和准确性。无沦他们做什么，都相当有条理和可靠。他们具有坚定不移、深思熟虑的思想，一旦他们着手自己相信是最好的行动方法时，就很难转变或变得沮丧。</p>
    <p>ISTJ型的人特别安静和勤奋，对于细节有很强的汇记忆和判断。他们能够引证准确的事实支持自己的观点，把过去的经历运用到现在的决策中。他们重视和利用符合逻辑、客观的分析，以坚持不懈的态度准时地完成工作，并且总是安排有序，很有条理。他们重视必要的理论体系和传统惯例，对于那些不是如此做事的人则很不耐烦。</p>
    <p>ISTJ型的人总是很传统、谨小甚微。他们聆听和喜欢确实、清晰地陈述事物。ISTJ型的人天生不喜欢显露，即使危机之时，也显得很平静。他们总是显得责无旁贷、坚定不变，但是在他们冷静的外表之下，也许有强烈却很少表露的反应。</p>`,
    md: `<p>ISTJ型人的一个普遍问题是在计划的细节和每日运行中丧失了自我的倾向。一旦沉浸其中，他们很固执僵化，不愿意适应或接受另外的观点。如果没有看到新想法的直接和有效的运用，他们往往会产生怀疑、他们必须花时间注意全部的客观事情，考虑他们可能没有考虑的可选择的情况，收集范围更广泛的信息，有意识地努力对他们的行为的未来含义做出预测，能够在各方面都增进ISTJ型人的影响。</p>
    <p>有时ISTJ型的人很难理解其他人的需求，尤其是那些与自己差异很大的需求。因为他们对自己的反响很隐蔽，所以他们被视为冷静而无情。他们必须直接地表达出对他人的欣赏，而不是仅仅保留在内心。</p>
    <p>因为ISTJ型的人很有条理，所以他们希望别人也如此。他们冒险把自己的判断强加于别人，无视那些不是十分自信的人的意见。他们强求别人按照他们的方法做事，阻止运用那些更有创造性的、新颖的方法。他们应该对未经考验和非传统的方式持开放的态度，这样才会对人们的差异更加容忍，最终才能做出有效的抉择。</p>`,
    qn: `<p>外向与内向是指我们心理能量的作用方向，判断与知觉是我们做事的两种方式。心理学称之为“态度”。感觉与直觉是我们获取信息的方式，思考和情感是做决定的方式，心理学称之为“功能”，功能是人格理论的精髓、核心。</p>
    <p>每一个人在都会用到四种功能，但对功能应用的喜好程度不一样。等级中第一位的称为“主导功能”，第二位的称为“辅助功能”。每个人都需要运用到这两个过程。</p>
    <p>有效地使用你的类型：(ISTJ)</p>
    <p>ISTJ型的人主导功能是（关注细节），辅助功能是思维。他们倾向于：</p>
    <p>注意细节、重视实际、能记住琐碎细节、耐得住烦闷的工作、有耐性、细心有系统。</p>
    <p>合乎逻辑擅于分析、客观、公正、有逻辑系统的思考、具拟判能力、坚定。</p>
    <p>ISTJ型的人比较少地使用他们的第三、第四功能—情感和直觉。即使在需要这些功能的场合，他们仍然无法有效使用。</p>
    <p>作为一个ISTJ型的人：</p>
    <p>如果你过多的使用感觉功能，你可能失去整体的概念、想不出各种可能解决的途径、不住直觉、不求创新、无法应付太复杂的工作、不喜欢预测未来。</p>
    <p>如果你过多的使用思考功能，你可能在需要的时间忘记去称赞他人，或忽视你决策对其它人的影响。</p>`,
    jjwt: `<p>每个人都会用自己的方式进行决策，决策通常来自于你的主导功能，并且完全忽视你的第四功能。你的功能运用如下：</p>
    <p>主导功能感觉——辅助功能思维——第三功能情感——第四功能直觉</p>
    <p>人格中的每一种功能对解决问题都具有关键和重要作用，忽视任何一个都将导致做出有缺陷的决定，理想化的问题解决功能流程如下：</p>
    <p>一个好的决策通常来自于四个功能的合理运用。当你尝试去解决问题时，你可以利用上表指导这个过程，但在整个的执行过程是非常困难的，因为每个人都不愿意用他不喜欢的功能去解决问题。就算你有意识地努力运用所有这些功能，你也不可能会在“真实生活”中以这种顺序运用它们。关键是要利用它们提醒自己，完善决策，就算你不按这种方式行事。</p>
    <p>工作中选择符合你的主导功能和辅助功能的岗位是非常重要的。第三功能要经过发展锻炼才能合适运用，第四功能可能永远不会在你的职业生涯中起到关键作用，因此你应当避免那种要求长期使用它的工作。</p>`,
    ys: `<p>对于不同的人格类型和不同的动力等极而言，没有“好”与“坏”之分，每一个人都是一个独一无二的个体，都有其特别的优势和劣势，但问题的关键在于如何认识这些优势和劣势,完善自己。</p><p>我们对于成功的建议是：“取已之长，补已之短”。学会了这一点将会影响到你的成败及你对工作的喜好。</p>
    <p class="b">你在工作中的优势：</p>
    <p>1.办事精确，希望第一次就能把工作做好</p>
    <p>2.乐意遵循确定的日常安排和传统的方针政策</p>
    <p>3.每次都能十分专注地把注意力集中在一个项目或任务上</p>
    <p>4.能够独立地工作</p>
    <p>5.灵敏的组织能力</p>
    <p>6.一丝不苟、认真专注地对待具体问题：事实和细节</p>
    <p>7.相信传统模式的可取之处，并且能够遵循传统模式</p>
    <p>8.非常强的责任意识；别人可以信任你去实现自己的诺言</p>
    <p>9.明白清晰的工作伦理；认为高效率和多成果是很重要的</p>
    <p>10.对实现目标有毅力和决心</p>
    <p>11.通情达理，视角现实</p>`,
    ls: `<p>下面列出了你工作中可能存在的缺点，这些缺点有的比较明显，有的并不明显或你没有意识到，目的是为了让你“注意”到它们，并考虑产生的原因。缺点有些是天生的，而有些是长时间形成的，因此你不可能在一两天内改变，而是去提醒、思考。</p><p>注意：其实知道存在的问题就是改变提高中很重要的一步，你会发现你正在慢慢发生变化。</p><p class="b">您工作中的劣势：</p>
    <p>1.不愿意尝试、接受新的和未经考验的观点和想法</p>
    <p>2.对变动感到不安；排斥革新</p>
    <p>3.对需要很长时间才能完成的任务缺乏耐心</p>
    <p>4.有时会由于近期目标而忽略长远需要</p>
    <p>5.办事死板；必要的时候难以或不愿意适应新情况</p>
    <p>6.难以看到问题的整体以及行为的长远影响</p>
    <p>7.对于方针或决定将会对别人造成什么样的影响缺乏敏感</p>
    <p>8.需要的时候不愿意改变努力的方向或调整投入的多少 </p>
    <p>9.不愿意促成必要的改变，也不愿意支持经过仔细考虑的风险行为</p>`,
    gwtz: `<p>研究发现：职业满足会使你的工作主动性更强，积极性更高，更愿意去工作。</p><p>以下不是简单的告诉你什么样的工作适合你，而是细化的帮你分析工作中的哪些特质对你重要，你还需要从中选出你认为最重要的，因为不同经历的人对特质的重要程度要求是不同的。每个岗位的工作内容都在随企业的发展而发展，不是一成不变的，有时候岗位的发展方向需要我们自己去争取。所以找到适合的工作不如找到适合自己发展的岗位更直接。</p><p>这些特质可以帮助明确如何主动的发展或争取你岗位中的那些特质。</p>
    <p>下面的条目从各个侧面上认证了您怎样才能受到真正的职业满足，看完这些条目之后，我们建议您根据它们对您的重要程序进行排序，排序的时候，回想一下您过去的学习、工作经历以及当前学习环境和工作感受，并思考：“哪一些是令你感到特别满意，有哪些令你极其不高兴”。</p><p>试着寻找贯穿这些经历的主题。</p>
    <p class="b">你的岗位特质：</p>
    <p>1.本质上是技术性的，能让你依靠自己的能力来使用和了解重要的事实和细节。</p>
    <p>2.涉及很多实际的产品和服务。这些产品或服务是通过严谨周密、合乎逻辑且效率高的方法——最好是运用标准化的工作程序来生产或实现的。</p>
    <p>3.能让我有大量的时间独立地工作，运用杰出的集中注意力的能力来完成项目和（或）任务。</p>
    <p>4.是在一个稳定和标准化的环境中完成的，做这些工作时我不必去冒不必要的风险、运用未经检验过的或实验性的方法。</p>
    <p>5.能看得见的而且可以衡量的结果，而且需要和重视运用精确的标准来评估工作的多少或好坏。</p>
    <p>6.有明确的目标、清晰的组织制度和模式。</p>
    <p>7.在演示或递交工作成果之前能让我有充足的时间来准备，最好是单独进行或小组进行。</p>
    <p>8.能让我具有越来越大的职责和权力，而且只要要求我参与很少的社会政治方面的活动；我的工作表现是根据我完成工作任务的多少或实现工作目标的好坏来衡量的，而且我的努力和贡献很受欣赏和重视。</p>
    <p>9.在工作过程或气氛中，我的现实的判断和过去的经历很受重视和嘉奖。</p>
    <p>10.能让我拥有必要的资源（人力方面和财力方面）和材料来订立正式的目标，并且实现这些目标。</p>`,
    zy: `<p>也许你会觉得下面的工作种类繁杂，但仔细分析你就会发现，这些工作各自的特色正是你对工作的要求，我们会在下面列出各类工作的特质。</p><p>当然，这不可能是一个完美的适合你的工作的综合，但是，却能够向你展示你以前不曾考虑过的工作的可能性。</p><p>在列出这些工作的同时，我们也要敬告您：每种职业中都有各种性格类型的成功人士；即使是相同的工作，不同公司的要求也不尽相同，所以除了工作名称外，你还要对贵公司要求的工作内容和公司文化作详细的了解。</p><p>我们希望在经济快速发展、新型工作不断涌现将来，这份报告上列举的工作种类不是限制了你的选择，而是告诉你，面对新工作的选择或机会时，应该从哪些因素来分析这些工作对你的适合程度。</p><p class="b">适合ISTJ型人的一般职业:</p>
    <p class="flex"><span>商业</span><span>审计员</span><span>公司经理</span><span>会计</span><span>管理者</span><span>监督人</span><span>文字信息处理专家</span><span>效率专家</span><span>效率分析者</span><span>保险业主(保险商)</span><span>后勤供给经理</span><span>制定规章制度的官员</span><span>信息总管</span><span>会计</span><span>保险统计员</span></p>
    <p>ISTJ经常喜欢与商业有关的一些职业，他们在管理系统和使事物正常运行方面有出色的才能。</p><p>他们经常喜欢到传统的、已经建立多年的团体或公司去工作，他们的加盟有助于团体或公司机构的正常运行。</p><p>他们工作富有效率，他们认真而详尽地记录各种花销和税务，不允许有任何未经检查或未经修正的错误或遗漏出现。</p><p>作为管理者，他们为每一名雇员制定清晰的工作权限、建立起一整套标准的规则。他们喜欢能够生产出具体产品或提供具体服务的商业工作。</p>
    <p class="flex"><span>销售</span><span>服务</span><span>警察局高级职员</span><span>侦探</span><span>情报检索服务社代理人</span><span>政府雇员</span><span>陆军军官</span><span>管教人员</span><span>房地产代理商</span><span>体育设备</span><span>商品销售商</span><span>教养所所长</span></p>
    <p>市政服务工作能够满足ISTJ服务于社区的愿望。他们喜欢维护服务或保护所有人的社会系统。他们喜欢在制度分明的环境中工作。无论是发布命令还是接受命令他们都做得很好。他们运用自己的知识和过去的经验果断地、有效地处理当前的问题。他们对事实和细节有非常好的记忆力，无论做什么，他们都用自己的实践经验做出判断。他们喜欢销售真实有形的产品，从中他们可以获得个人收益。</p>
    <p class="flex"><span>金融</span><span>银行查帐员</span><span>投资担保人</span><span>税收监察员</span><span>预算分析员</span><span>股票经纪人</span><span>房地产策划</span><span>信贷分析员</span></p>
    <p>ISTJ拥有数学方面的天才。他们能记住有关事实和细节的数字，并能在必要时引用它们来支持自己的观点。他们不会轻易转移注意力，工作时特别专心、辛苦，工作成果既准确又细致。金融领域的职业需要以下才能：能很好地独立工作；能够吸收大量数据；能够进行精确的计算，这些都是ISTJ所具有的。</p>
    <p class="flex"><span>教育</span><span>学校校长</span><span>教师</span><span>技术</span><span>工业</span><span>数学</span><span>物理</span><span>图书管理员</span><span>管理者</span></p>
    <p>教育领域的职业对ISTJ是非常适合的，尤其是那些管理和技术方面的工作。ISTJ能够很好地管理学校或课程的运行。他们通过实践寻找维持系统运行的可能性和方法。管理方面和图书馆方面的职业使ISTJ能够独力工作，运用他们客观的分析能力处理数据，如考试成绩、预算等。教书对ISTJ也很适合，特别是一些技术和实践的科目能为他们提供很多学习和教授的机会。</p>
    <p class="flex"><span>法律</span><span>技术</span><span>法律调查员</span><span>律师秘书</span><span>电工</span><span>工程师</span><span>机械师</span><span>计算机程序设计员</span><span>科学作家</span><span>律师秘书</span><span>律师专职助手</span><span>药品经销商</span><span>调查员</span><span>脑电图技术专家</span><span>技师</span><span>地质学家</span><span>气象学家</span><span>航空机械师</span><span>机械</span><span>工业</span><span>电子工程师</span><span>农业科学家</span></p>
    <p>这些职业为ISTJ提供了运用他们技术才能的机会，而且他们的工作结果都是要求高度准确的产品。因为他们从不盲目地接受事物，所以他们能够抓住工作中的疏忽和纰漏，并且忠诚地遵循必要的程序和系统。上述职业中有很多还能为ISTJ提供独自工作的机会，让他们充分发挥专心工作的优点、出色的记忆力及各种技巧。</p>
    <p class="flex"><span>保健</span><span>兽医</span><span>普通外科医师</span><span>牙医</span><span>护理指导员</span><span>保健指导员</span><span>药剂师</span><span>实验室技术人员</span><span>医学研究者</span><span>最初保健护理医师</span><span>生物学和医学技术专家</span><span>运动生理学家</span><span>药剂师</span><span>配药技术员</span></p>
    <p>ISTJ经常投身于医药行业，特别喜欢就职于一些有着传统制度的医院。他们对病人保持密切的关心，耐心地倾听他们的诉说，并给他们提供一些有思想、有节制的建议，制定出恰当的治疗计划。</p><p>ISTJ还是保健方面成功的指导者，他们尽心尽力地工作，承担应尽的义务，履行应负的责任。他们喜欢有条理的工作环境，在这种环境中他们能朝着目标前进，按时完成应做的工作。牙科和药剂学需要更多的技巧，因此为ISTJ所喜爱。因为他们善于抓住事实信息，并且能够永远保留这些信息。</p>`,
    jy: `<p>现在你对自己的人格类型和动力已经有了一个比较清楚的了解，但这还不够。“如何通过这些信息使你在这份工作上取得更大的成功”，这是关键所在。</p><p class="b">运用你的能力非常容易，你成功的秘诀在于：</p>
    <p>1.要敢于探索新的可能性；</p><p>2.更多考虑人性的因素；</p><p>3.要乐于接受新事物。</p>
    <p>个人发展建议是我们咨询师多年测评职业咨询和职业生涯规划的心得体会和经验总结，我们意识到以下的建议中有很多是难以完全照办的，但只要你花时间认真思考，一定会对你有极大的帮助和改变：</p>
    <p class="b">发展建议：</p><p>1.除了眼前的现实，需要关注问题的更广泛的枝节；</p>
    <p>2.需要考虑人的因素；需要尝试新的东西以避免墨守陈规；</p>
    <p>3.需要对那些不太在意规则而努力创新的人保持足够耐心。</p>
    `,
  },
  ISFJ: {
    tag: `<h1>ISFJ（内倾、感觉、情感和判断）</h1>
    <h2>我以名誉担保，履行自己的责任和义务</h2>`,
    subscribe:
      '<p>1.安静、和善、负责任且有良心。</p><p>2.行事尽责投入。</p><p>3.安定性高，常居项目工作或团体之安定力量。</p><p>4.愿投入、吃苦及力求精确。</p><p>5.兴趣通常不在于科技方面。对细节事务有耐心。</p><p>6.忠诚、考虑周到、知性且会关切他人感受。</p><p>7.致力于创构有序及和谐的工作与家庭环境。</p>',
    ms: `<p>ISFJ型的人忠减、有奉献精神和同情心他们意志清醒而有责任心，乐于为人,理解别人的感受。ISFJ型的人十分务实，他们喜欢平和谦逊的人，他们喜欢利用大量的事实情况，对于细节则有很强的记忆力。他们耐心地对待任务的整个阶段，喜欢事情能够清晰明确。</p>
    <p>因为他们具有强烈的职业道德，所以他们如果知道自己的行为真正有用时，会对需要完成之事承担责任。他们准确系统地完成任务。他们具有传统的价值观，十分保守。他们利用符合实际的判断标准做决定，通过出色的注重实际的态度增加了稳定性。</p>
    <p>ISFJ型的人平和谦虚、勤奋严肃。他们温和、圆通，支持朋友和同伴。他们乐于协助别人，喜欢实际可行地帮助他人。他们利用个人热情与人交往，在困难中与他人和睦相处。</p><p>ISFJ型的人不喜欢表达个人情感，但实际上对于大多数的情况和事件都具有强烈的个人反应。他们关心保护朋友，愿意为朋友献身，他们有为他人服务的意识，愿意完成他们的责任和义务。</p>`,
    md: `<p>ISFJ型的人生活在完全现实的生活中，他们很难全面地观察问题，也很难预见情形的可能性结果，尤其是当他们不熟悉情形之时。他们需要看得更远些，想象如果以不同方式做事可能会产生怎样的后果。</p>
    <p>ISFJ型的人为了自己和那些对其应负责的人，每天陷入了劳累和无终止的劳作中。他们常常过多地工作，亲自去做某件事以确保能够一丝不苟地完成。因为他们天生并不是过分自信或意志坚强，所以他们有被别人利用的危险。他们必须表达不断被各种事物缠绕的怨恨之情，所以他们没有发现自己已成为一种帮助的角色。而且他们还必须让其他人知道他们的需求和成就。</p>
    <p>ISFJ型的人经常需要额外的时间以掌握技术性的内容。他们往往过多地计划，所以必须制定有助于重新调整他们那耗费过多、令人担心的精力的计划。ISFJ型的人必须寻找能够得到他们应得的、更多的快乐和放松的方法。</p>`,
    qn: `<p>外向与内向是指我们心理能量的作用方向，判断与知觉是我们做事的两种方式。心理学称之为“态度”。感觉与直觉是我们获取信息的方式，思考和情感是做决定的方式，心理学称之为“功能”，功能是人格理论的精髓、核心。</p>
    <p>每一个人在都会用到四种功能，但对功能应用的喜好程度不一样。等级中第一位的称为“主导功能”，第二位的称为“辅助功能”。每个人都需要运用到这两个过程。</p>
    <p class="b">有效地使用你的类型：(ISFJ)</p>
    <p>ISFJ型的人主导功能是感觉（关注细节），辅助功能是情感。他们倾向于：</p>
    <p>注意细节、重视实际、能记住琐碎细节、耐得住烦闷的工作、有耐性、细心有系统。</p>
    <p>体谅他人感受、了解他人的需要、喜欢和谐的人际关系、易表露情感、喜去说服他人。</p>
    <p>ISFJ型的人比较少地使用他们的第三、第四功能—思维和直觉。即使在需要这些功能的场合，他们仍然无法有效使用。</p>
    <p class="b">作为一个ISFJ型的人：</p>
    <p>如果你过多的使用感觉和情感功能，你可能会失去整体的概念、想不出各种可能解决的途径、不住直觉、不求创新、无法应付太复杂的工作、不喜欢预测未来。</p>`,
    jjwt: `<p>每个人都会用自己的方式进行决策，决策通常来自于你的主导功能，并且完全忽视你的第四功能。你的功能运用如下：</p>
    <p>主导功能感觉——辅助功能情感——第三功能思维——第四功能直觉。</p>
    <p>人格中的每一种功能对解决问题都具有关键和重要作用，忽视任何一个都将导致做出有缺陷的决定，理想化的问题解决功能流程如下：</p>
    <p>一个好的决策通常来自于四个功能的合理运用。当你尝试去解决问题时，你可以利用上表指导这个过程，但在整个的执行过程是非常困难的，因为每个人都不愿意用他不喜欢的功能去解决问题。就算你有意识地努力运用所有这些功能，你也不可能会在“真实生活”中以这种顺序运用它们。关键是要利用它们提醒自己，完善决策，就算你不按这种方式行事。</p>
    <p>工作中选择符合你的主导功能和辅助功能的岗位是非常重要的。第三功能要经过发展锻炼才能合适运用，第四功能可能永远不会在你的职业生涯中起到关键作用，因此你应当避免那种要求长期使用它的工作。</p>`,
    ys: `<p>对于不同的人格类型和不同的动力等极而言，没有“好”与“坏”之分，每一个人都是一个独一无二的个体，都有其特别的优势和劣势，但问题的关键在于如何认识这些优势和劣势,完善自己。我们对于成功的建议是：“取已之长，补已之短”。学会了这一点将会影响到你的成败及你对工作的喜好。</p>
    <p class="b">你在工作中的优势：</p>
    <p>1.能够很好地集中、关注焦点</p>
    <p>2.很强的工作伦理，工作努力而且很负责任</p>
    <p>3.良好的协作技巧，能和别人建立起和谐友好的关系</p>
    <p>4.讲求实效的工作态度，办事方法现实可行</p>
    <p>5.十分关注细节，能够准确地把握事实</p>
    <p>6.乐于助人，给同事和下属职员的工作提供支持和帮助</p>
    <p>7.了解公司（或组织）的经历，能够很好地维护公司（或组织）的传统</p>
    <p>8.杰出的组织才能</p>
    <p>9.愿意在传统的机构中工作，而且兢兢业业、不遗余力</p>
    <p>10.能够连续地工作，对相同的工作任务不会感到厌倦</p>
    <p>11.非常强的责任意识；别人可以信任你去实现自己的诺言</p>
    <p>12.喜欢运用固定的办事程序；尊重别人的地位和能力</p>
    <p>13.通情达理，视角现实</p>`,
    ls: `<p>下面列出了你工作中可能存在的缺点，这些缺点有的比较明显，有的并不明显或你没有意识到，目的是为了让你“注意”到它们，并考虑产生的原因。缺点有些是天生的，而有些是长时间形成的，因此你不可能在一两天内改变，而是去提醒、思考。注意：其实知道存在的问题就是改变提高中很重要的一步，你会发现你正在慢慢发生变化。</p>
    <p class="b">您工作中的劣势：</p>
    <p>1.可能低估自己的能力，那与坚决地维护自己的需要和利益</p>
    <p>2.不愿意尝试、接受新的和未经考验的观点和想法</p>
    <p>3.对反对意见过于敏感；在紧张的工作环境里感到很受压抑</p>
    <p>4.可能只关注细节和眼前之事，而对整体和将来重视不够</p>
    <p>5.倾向于同时投入到过多的任务之中</p>
    <p>6.难以适应新环境，或者在不同工作任务之间来回切换时会有困难</p>
    <p>7.易于被需要同时解决的太多的工作项目或任务弄得晕头转向、无所适从</p>
    <p>8.如果自己得不到充分的重视和赞赏，可能会感到灰心丧气</p>
    <p>9.一经做出决定，就不愿意从头考虑同一个问题</p>`,
    gwtz: `<p>研究发现：职业满足会使你的工作主动性更强，积极性更高，更愿意去工作。</p>
    <p>以下不是简单的告诉你什么样的工作适合你，而是细化的帮你分析工作中的哪些特质对你重要，你还需要从中选出你认为最重要的，因为不同经历的人对特质的重要程度要求是不同的。每个岗位的工作内容都在随企业的发展而发展，不是一成不变的，有时候岗位的发展方向需要我们自己去争取。</p>
    <p>所以找到适合的工作不如找到适合自己发展的岗位更直接。这些特质可以帮助明确如何主动的发展或争取你岗位中的那些特质。</p>
    <p>下面的条目从各个侧面上认证了您怎样才能受到真正的职业满足，看完这些条目之后，我们建议您根据它们对您的重要程序进行排序，排序的时候，回想一下您过去的学习、工作经历以及当前学习环境和工作感受，并思考：“哪一些是令你感到特别满意，有哪些令你极其不高兴”。试着寻找贯穿这些经历的主题。</p>
    <p class="b">你的岗位特质：</p>
    <p>1、求仔细观察、一丝不苟和准确无误，因此我能充分发挥记忆事实和分析细节的能力。</p>
    <p>2、能让我致力于有形的、现实的项目任务，并以此为别人提供帮助和服务；通常需要对细节问题非常关注，对精确性的要求也非常高。</p>
    <p>3、使我通过默默无闻的努力工作，表达自己对别人的同情与对别人的同情与对工作的热忱；同时我做出的贡献也受到重视和赞赏。</p>
    <p>4、工作在一个传统、稳定、有序和制度化的环境中，并且能为别人提供使用假合资非常高的服务。</p>
    <p>5、要求遵循标准化的工作程序，运用现实的判断力，并且仔细、有条不紊地坚持到底。</p>
    <p>6、每次都全身心地投入到一个项目任务或一个人身上，并且做出的产品或提供的服务能够带来可观的结果。</p>
    <p>7、能让我有一个独立的工作空间，这样我就能连续地集中注意力，受到最小限度的干扰和打断。</p>
    <p>8、不论是帮助别人也好，还是与志同道合的人打交道也好，大多数时候都只需要我关注一个对象，而不是同时为好几个人工作或提供服务。</p>
    <p>9、在完成工作任务的过程中，要求我条理清晰并且效率很高。</p>
    <p>10、把工作成果向别人展示之前，能让我事先有充分的时间来准备。</p>`,
    zy: `<p>也许你会觉得下面的工作种类繁杂，但仔细分析你就会发现，这些工作各自的特色正是你对工作的要求，我们会在下面列出各类工作的特质。当然，这不可能是一个完美的适合你的工作的综合，但是，却能够向你展示你以前不曾考虑过的工作的可能性。在列出这些工作的同时，我们也要敬告您：每种职业中都有各种性格类型的成功人士；即使是相同的工作，不同公司的要求也不尽相同，所以除了工作名称外，你还要对贵公司要求的工作内容和公司文化作详细的了解。</p>
    <p>我们希望在经济快速发展、新型工作不断涌现将来，这份报告上列举的工作种类不是限制了你的选择，而是告诉你，面对新工作的选择或机会时，应该从哪些因素来分析这些工作对你的适合程度。</p>
    <p class="b">适合ISFJ型人的一般职业</p>
    <p class="flex">健康护理部分·牙医·家庭运动医生·护士·医务技术专家·理疗法专家·医疗设备推销·提供健康护理人员·饮食专家／营养学家·眼科大夫·医务记录管理人员·药剂师／药剂技术人员·放射专家·呼吸系统专家·兽医·有执照行医护士·初级保健大夫·家庭保健助理·医务／牙科助理</p>
    <p>这些职业允许ISFJ型的人在这样一种环境下工作，在这里他们的贡献通过对别人施加的个人的、直接的影响表现出来。那些职业中的很大一部分要求同客户和患者进行亲身的、一对一的交往。ISFJ型的人喜欢用一种真实的方法帮助别人。那些职位也允许ISFJ型的人在一种传统的，组织化的文化中相对独立地工作。医务领域充分利用了ISFJ型的入学习和实践技术的能力，为他们提供了同别人建立个人联系的机会。</p>
    <p class="flex"><span>社会服务部门</span><span>教育部门</span><span>学前教育／初级学校教师</span><span>图书馆员</span><span>建筑师</span><span>教育管理人员</span><span>社会福利工作人员</span><span>咨询服务人员</span><span>个人咨询人员</span><span>宗教教育者</span><span>言语病理学家</span><span>家庭健康社会服务人员</span><span>儿童福利咨询人员</span><span>戒酒和戒毒咨询人员</span><span>小学教师</span><span>图书管理员</span><span>档案保管员</span><span>特殊教育老师</span><span>(博物馆、美术馆、图书馆等的)馆长</span><span>家谱学家</span><span>教育行政人员</span><span>咨询服务人员</span><span>社会工作者(老年服务)</span><span>神学教育者</span><span>(博物馆、图书馆、美术馆等的)馆长</span><span>社会工作人员(老年和儿童每日看护)</span></p>
    <p>由于教育部门能为ISFJ型的人提供帮助他人，服务社会的机会，所以很吸引这种类型的人。ISFJ型的人经常喜欢教初级中学，由于这可以同学生进行个人交往和发挥他们善长教基本技巧的能力。许多ISFJ型的人喜欢教育管理类的职业。尤其当他们对特定范围(例如特殊教育)或相对小的服务范围(一个小镇而非大城市)负责时更是这样。ISFJ型的人喜欢在组织内独立工作，这种组织必须是认可他们的成绩，使他们明白职业目标的那种。</p>
    <p>研究领域为ISFJ型人提供满足感是因为它们提供了独立工作和调查具体任务的机会。馆员们要求创造性并保持完整而准确的记录，这使他们能利用自己的组织能力和回忆事实的特长。尽管经常面临挑战，社会福利工作仍给予ISFJ型的人获得职业满足的机会。因为通过同客户的交往，ISFJ型的人能对别人的生活给予切实的提高，因此，他们发<p>现贡献是有价值的，这些工作提供了同样的一对一交往和独立工作的环境，这对于ISFJ型的人都具有吸引力。</p>
    <p class="flex"> <span>商业</span><span>秘书</span><span>员工监督人</span><span>顾客服务代表</span><span>人事管理人员</span><span>计算机操作者</span><span>记帐员</span><span>信用顾问</span><span>律师的专职助手</span><span>家用保健品推销</span></p>
    <p>这些职业要求大量的在一对一基础上同别人交往的机会，因此常为ISFJ型的人所喜欢。这些职业中许多都不是独立完成的工作，这能使ISFJ型的人使用组织方面和深入细节的能力。尤其当他们同受他们尊敬和钦佩的人一块工作时。这些职业同样要求知识及技术和交流技巧以帮助别人获取信息或帮助他们满足需求。</p>
    <p class="flex"><span>创造性／技术性</span><span>内部装饰人员</span><span>电工</span><span>零售商</span><span>旅店老板</span><span>艺术家</span><span>音乐家</span><span>优先顾客销售代理人</span><span>商业计划者</span><span>不动产经纪人</span></p>
    <p>这些职业彼此未必有关联但它们有一些共同的特征和要求。每种都要求与同日常生活有关的实际东西打交道。作为·—个内部装饰人员，ISFJ型的人运用他或她的审美感给予客户以合适的装潢。这种工作要求注重细节以及同别人合作向满足客户对他或她室内装饰的需要和愿望迈进的能力。ISFJ型的人很注重自己房间的装饰，因此，他们容易理解客户装饰出使他们舒服的居室的愿望。</p>
    <p>一个电工要求技术准确性和坚持标准程序和代码。ISFJ型的人喜欢注意那些要求亲自动手的工作，也喜欢使用他们掌握的技术。如果ISFJ型的人感到他或她的贡献是有价值的并为人欣赏，如果他或她赢得了同顾客、同伙、同事的有质量的交往，那么电工的工作也是令人满意的。</p>
    <p>零售推销和商业是经常为ISFJ型所喜欢的职业，尤其当他们在小专卖店或大商场的一个部门工作时。ISFJ型的人常可以成为小商店的好老板。他们对诸如商店形象之类细节的关注以对顾客的“感觉”经常为他们赢得长期稳定的买卖。他们喜欢一定时间内同一名顾客交流，寻找合适的装饰品与顾客们已有的相配套。他们喜欢长期维持与顾客的关系，对待顾客十分友好和诚实可信。</p>`,
    jy: ` <p>现在你对自己的人格类型和动力已经有了一个比较清楚的了解，但这还不够。“如何通过这些信息使你在这份工作上取得更大的成功”，这是关键所在。运用你的能力非常容易，你成功的秘诀在于：</p>
    <p>1.清楚地表达自己；</p>
    <p>2.考虑尚不存在的可能之事；</p>
    <p>3.设法更加灵活随和，表现自然一些。</p>
    <p>个人发展建议是我们咨询师多年测评职业咨询和职业生涯规划的心得体会和经验总结，我们意识到以下的建议中有很多是难以完全照办的，但只要你花时间认真思考，一定会对你有极大的帮助和改变：</p>
    <p class="b">发展建议：</p>
    <p>1.在寻求未来工作上需要以积极、全局的态度处之；</p>
    <p>2.需要发展其决断性和直截了当；</p>
    <p>3.需要学会积极面对外界；</p>
    <p>4.需要以更加开放的态度对待其它的做事方式。</p>
    `,
  },
  INFJ: {
    tag: '<h1>INFJ（内倾、直觉、情感和判断）</h1><h2>独立的、有独创性的思想家</h2>',
    subscribe:
      '<p>1.因为坚忍、创意及必须达成的意图而能成功。</p><p>2.会在工作中投注最大的努力。</p><p>3.默默强力的、诚挚的及用心的关切他人。</p><p> 4.因坚守原则而受敬重。</p><p>5.提出造福大众利益的明确远景而为人所尊敬与追随。</p><p>6.追求创见、关系及物质财物的意义及关联。</p><p>7.想了解什么能激励别人及对他人具洞察力。</p><p>8.光明正大且坚信其价值观。</p><p>9.有组织且果断地履行其愿景。 </p>',
    ms: `<p>INFJ型的人生活在思想的世界。他们是独立的、有独创性的思想家，具有强烈的感情、坚定的原则和正直的人性。即使面对怀疑，INFJ型的人仍相信自己的看法与决定。他们的评价高于其他的一切，包括流行的观点和存在的权威，这种内在的观念激发着他们的积极性。通常INFJ型的人具有本能的洞察力，能够看到事物更深层的含义。即使他人无法分享他们的热情，但灵感对于他们重要而令人信服。</p>
    <p>INFJ型的人忠诚、坚定、富有理想化。他们珍视正直，十分坚定以至达到倔强的地步。因为他们的说服能力，以及对于什么对公共利益最有利有清楚的看法，所以INFJ型的人会成为伟大的领导者。由于他们的贡献，他们通常会受到尊重或尊敬。</p>
    <p>因为珍视友好和和睦，INFJ型的人喜欢说服别人，使之相信他们的观点是正确的。通过运用嘉许和赞扬，而不是争吵和威胁，他们赢得了他人的合作。他们愿意毫无保留地激励同伴，避免争吵。通常INFJ型的人是深思熟虑的决策者，他们觉得问题使人兴奋，在行动之前他们通常要仔细地考虑。他们喜欢每次全神贯注于一件事情，这会造成一段时期的专心致志。</p>
    <p>满怀热情与同情心，INFJ型的人强烈地渴望为他人的幸福做贡献。他们注意其他人的情感和利益，能够很好地处理复杂的人。INFJ型的人本身具有深厚复杂的性格．既敏感又热切。他们内向，很难被人—了解，但是愿意同自己信任的人分享内在的自我。他们往往有一个交往深厚、持久的小规模的朋友圈，在合适的氛围中能产生充分的个人热情和激情。</p>`,
    md: `<p>由于往往注意“思想”，INFJ型的人有时不切实际，会忽视需要注意的常规细节。更多地是对周围事物的注意，以及更多地依赖于经证实的消息，这使INFJ型的人在真实的世界中停留于自己创造的思想。</p>
    <p>INFJ型的人对于自己的原则如此坚定，以至于发展到眼光狭窄的地步。他们很顽固地对待变化，一旦决定已经做出，他们可能会拒绝改变。有时他们会忽略不支持他们立场的重要事实，或是拒绝与他们的价值观相冲突的看法。他们不可能听取其他人的异议，因为对他们来说，自己的立场似乎是毫无疑问的。INFJ型的人应该尝试更加客观地看待自我和自己的工作。</p>
    <p>因为他们对于自己的观点如此具有保护性，所以INFJ型的人往往超越常规。他们经常是完美主义者，会对批评过敏。虽然他们意志坚强，但是在处理人际关系中的冲突也会遇到困难，如果冲突发展，他们会变得失望和沮丧。对于他们的人际关系和自我，INFJ型的人越客观，越少受这些伤害的影响。</p>`,
    qn: `<p>外向与内向是指我们心理能量的作用方向，判断与知觉是我们做事的两种方式。心理学称之为“态度”。感觉与直觉是我们获取信息的方式，思考和情感是做决定的方式，心理学称之为“功能”，功能是人格理论的精髓、核心。</p>
    <p>每一个人在都会用到四种功能，但对功能应用的喜好程度不一样。等级中第一位的称为“主导功能”，第二位的称为“辅助功能”。每个人都需要运用到这两个过程。</p>
    <p>有效地使用你的类型：(INFJ)</p>
    <p>INFJ型的人主导功能是直觉，辅助功能是情感。他们倾向于：</p>
    <p>探求隐含的原因、可能性或事物的整体。对事实和经验做出符合逻辑结论。</p>
    <p>INFJ型的人比较少地使用他们的第三、第四功能—思考和感觉。即使在需要这些功能的场合，他们仍然无法有效使用。</p>
    <p>作为一个INFJ型的人：如果你过多地关注直觉，你可能不注重细节、不注意实际、不耐沉闷、不合逻辑、把握不住现在、骤下断语。</p>`,
    jjwt: `<p>每个人都会用自己的方式进行决策，决策通常来自于你的主导功能，并且完全忽视你的第四功能。你的功能运用如下：</p>
    <p>主导功能直觉——辅助功能情感——第三功能思考——第四功能感觉</p>
    <p>人格中的每一种功能对解决问题都具有关键和重要作用，忽视任何一个都将导致做出有缺陷的决定，理想化的问题解决功能流程如下：</p>
    <p>一个好的决策通常来自于四个功能的合理运用。当你尝试去解决问题时，你可以利用上表指导这个过程，但在整个的执行过程是非常困难的，因为每个人都不愿意用他不喜欢的功能去解决问题。就算你有意识地努力运用所有这些功能，你也不可能会在“真实生活”中以这种顺序运用它们。关键是要利用它们提醒自己，完善决策，就算你不按这种方式行事。</p>
    <p>工作中选择符合你的主导功能和辅助功能的岗位是非常重要的。第三功能要经过发展锻炼才能合适运用，第四功能可能永远不会在你的职业生涯中起到关键作用，因此你应当避免那种要求长期使用它的工作。</p>`,
    ys: `<p>对于不同的人格类型和不同的动力等极而言，没有“好”与“坏”之分，每一个人都是一个独一无二的个体，都有其特别的优势和劣势，但问题的关键在于如何认识这些优势和劣势,完善自己。</p>
    <p>我们对于成功的建议是：“取已之长，补已之短”。学会了这一点将会影响到你的成败及你对工作的喜好。</p>
    <p class="b">你在工作中的优势：</p>
    <p>1.诚实正直，从而固定里这人们重视你的想法</p>
    <p>2.对于那些于你很重要的项目你专注且执着</p>
    <p>3.坚决果断，并有高度的组织能力</p>
    <p>4.有创造力，能提出独树一帜的解决问题的方法</p>
    <p>5.与别人感情交融，能预见别人的需要</p>
    <p>6.能以透视法看到事情发展的宏观图像以及意识与行动间未来的潜在联系</p>
    <p>7.有理解复杂概念的能力</p>
    <p>8.对别人真正关心，有帮助别人成长和发展的才能</p>
    <p>9.独立，有很强的个人信念</p>
    <p>10有做出成绩，不达不敌不罢休的干劲</p>
    <p>11.对自己信仰的事业尽职尽责</p>`,
    ls: `<p>下面列出了你工作中可能存在的缺点，这些缺点有的比较明显，有的并不明显或你没有意识到，目的是为了让你“注意”到它们，并考虑产生的原因。缺点有些是天生的，而有些是长时间形成的，因此你不可能在一两天内改变，而是去提醒、思考。注意：其实知道存在的问题就是改变提高中很重要的一步，你会发现你正在慢慢发生变化。</p>
    <p class="b">您工作中的劣势：</p>
    <p>1.过分的专心致志，结果可能导致死板</p>
    <p>2.对于要做完一件事要花多少时间心中没数</p>
    <p>3.很难做与自己价值观相冲突的事</p>
    <p>4.对计划的可行性有不切实际的倾向</p>
    <p>5.一旦做出决定不愿再回头审视一下，更不愿意撤销决定</p>
    <p>6.不会处理矛盾，易于忽略不快</p>
    <p>7.很难拉下面子客观、直接地训诫属下</p>
    <p>8.很难把复杂的想法简明地表达出来</p>
    <p>9.易于仓促下判断</p>`,
    gwtz: `<p>研究发现：职业满足会使你的工作主动性更强，积极性更高，更愿意去工作。以下不是简单的告诉你什么样的工作适合你，而是细化的帮你分析工作中的哪些特质对你重要，你还需要从中选出你认为最重要的，因为不同经历的人对特质的重要程度要求是不同的。每个岗位的工作内容都在随企业的发展而发展，不是一成不变的，有时候岗位的发展方向需要我们自己去争取。所以找到适合的工作不如找到适合自己发展的岗位更直接。这些特质可以帮助明确如何主动的发展或争取你岗位中的那些特质。</p>
    <p>下面的条目从各个侧面上认证了您怎样才能受到真正的职业满足，看完这些条目之后，我们建议您根据它们对您的重要程序进行排序，排序的时候，回想一下您过去的学习、工作经历以及当前学习环境和工作感受，并思考：“哪一些是令你感到特别满意，有哪些令你极其不高兴”。试着寻找贯穿这些经历的主题。</p>
    <p class="b">你的岗位特质：</p>
    <p>1.允许我自己考虑并创立新颖的观点和/或方法来解决工作中出现的各种各样不同的问题，并帮助别人成长和发展。</p>
    <p>2.能让我提供一种我所信仰且引以为豪的产品或服务。</p>
    <p>3.承认我的首创、拥有者身份，以及所作的独有的共享。</p>
    <p>4.能让我自由地表达自己，并让我看到我的洞察力所带来的结果。</p>
    <p>5.能让我为别人的利益或别人提供服务而实施自己的想法，允许我一对一地展开工作。</p>
    <p>6.有一个友好、没有紧张关系的工作氛围，其中我的看法能得到重视，而我的努力能得到别人的精神支持。</p>
    <p>7.允许我独立地工作，且有机会在一个友好、没有人迹冲突的环境中经常与人探讨我的工作。</p>
    <p>8.可以自由地安排自己的工作时间及环境，对自己的工作进程和工作成果有极大的支配权。</p>
    <p>9.给予我足够的时间来制定并审查我的机会，以便使我的计划准备得很充分。</p>
    <p>10.符合我自己的个人价值和观点，能让我在人格上和职业上都保持诚实正直的品质。</p>`,
    zy: `<p>也许你会觉得下面的工作种类繁杂，但仔细分析你就会发现，这些工作各自的特色正是你对工作的要求，我们会在下面列出各类工作的特质。当然，这不可能是一个完美的适合你的工作的综合，但是，却能够向你展示你以前不曾考虑过的工作的可能性。在列出这些工作的同时，我们也要敬告您：每种职业中都有各种性格类型的成功人士；即使是相同的工作，不同公司的要求也不尽相同，所以除了工作名称外，你还要对贵公司要求的工作内容和公司文化作详细的了解。</p>
    <p>我们希望在经济快速发展、新型工作不断涌现将来，这份报告上列举的工作种类不是限制了你的选择，而是告诉你，面对新工作的选择或机会时，应该从哪些因素来分析这些工作对你的适合程度。</p>
    <p class="b">适合INFJ型人的一般职业</p>
    <p class="flex"><span>—般职业咨询</span><span>教育职业</span><span>职业咨询顾问</span><span>心理学家</span><span>教育顾问</span><span>图书管理员</span><span>特殊教育老师</span><span>双语教育老师</span><span>早期教育老师</span><span>雇员帮助顾问</span><span>儿童福利顾问</span><span>酒精和毒品禁戒顾问</span><span>教师</span><span>戏剧</span></p>
    <p>这些职业允许INFJ型人利用他们的想法和知识帮助别人咨询和教育领域非常要求个人之间的交流，常常是一个面对一个的形式，这使INFJ以与他人建立深入的关系。</<p>INFJ型人也乐于研究、学习和从教育行业获得发展的机会，他们通常会在学术环境中感到非常轻松自在。</p>
    <p class="flex"><span>家教职业</span><span>牧师</span><span>教士</span><span>修道士</span><span>修女</span><span>家教工作者</span><span>家教教育指导者</span></p>
    <p>家教工作要求人能够深入而切身地致力于其中，坚持以工作为使命的工作哲学。INFJ型人常常认为他们的工作就是使命，从与他人分享他们的哲学和信念之中获得充分的满足感。</p>
    <p class="flex"><span>创造性职业</span><span>艺术家</span><span>小说家</span><span>设计师</span><span>通用设计建筑师</span><span>编辑、艺术指导(杂志)</span><span>剧作家</span><span>诗人</span><span>信息制图设计者</span><span>自由宣传媒介设计人</span><span>家谱学家(家族研究者)</span></p>
    <p>INFJ型人对艺术的爱好是由于他们具有运用自己的想法和眼光创造独特作品的能力。艺术品使INFJ型人以个人的方式表达自己，结果往往是给予他人一种冲击力。这些职业通常都是独立的工作，使INFJ型人能够组织并控制环境、过程和成果。</p>
    <p class="flex"><span>健康护理</span><span>社会服务职业</span><span>保健管理人</span><span>调解人</span><span>冲突解决人</span><span>社会工作人员</span><span>饮食学家</span><span>营养学家</span><span>全面健康医生</span><span>职业治疗医生</span><span>社会服务代理人</span><span>社会科学家</span><span>心理健康顾问</span><span>语言病理学家</span><span>听觉学家</span><span>信息治疗医生</span></p>
    <p>社会服务职业要求人致力于帮助他人，这常常是通过有组织的机构进行的。多数INFJ型人喜欢在这样的环境中工作，尤其当职员较少而关系密切的时候。社会服务使INFJ型人可以对于个人或社会的问题思考并提出新的解决方法。许多时候，社会工作人员的工作是独立而个别地进行的，使他们可以与客户和同事经常展开一对一的交流。</p>
    <p class="flex"><span>商业</span><span>人事资源经理</span><span>组织机构的发展顾问</span><span>职业分析家</span><span>公司／工作小组培训人</span><span>商业销售计划人</span><span>口译</span><span>翻译</span><span>市场人员(提供服务或点子)</span><span>雇员帮助方案的协调者</span><span>顾问</span><span>人事资源多样化管理人</span><span>优先顾客销售代表</span><span>环境法律师</span></p>
    <p>尽管INFJ型人中并没有大量的人被商业领域的工作所吸引，他们仍然可能在一些商业领域中获得职业满足感。人力资源或人事和组织机构的发展咨询是商业中有关“人”的部分，要求能够对许多不同的人感兴趣并且易于和人们相处。这些职业使INFJ型人能够帮助他人找到工作，建立有效的工作环境，而且可以效力于创造性地解决以人为焦点的问题。</p>
    <p>营销工作使INFJ型人能够通过工作小组发挥其创造性解决问题的能力。如果INFJ型人对于工作过程投入很多而且能够保持令人轻松舒适的个人和工作的统一完整性，他们就会觉得这样的工作是令人满意的。记住，这些仅仅是可以为具有独特天生才能的INFJ型人提供职业感的一部分职业领域。</p>`,
    jy: `<p>现在你对自己的人格类型和动力已经有了一个比较清楚的了解，但这还不够。“如何通过这些信息使你在这份工作上取得更大的成功”，这是关键所在。运用你的能力非常容易，你成功的秘诀在于：</p>
    <p>1.注意细节；</p>
    <p>2.学会灵活一点；</p>
    <p>3.无论做什么事情都要自发自愿，不要让人督促你。</p>
    <p>个人发展建议是我们咨询师多年测评职业咨询和职业生涯规划的心得体会和经验总结，我们意识到以下的建议中有很多是难以完全照办的，但只要你花时间认真思考，一定会对你有极大的帮助和改变：</p>
    <p class="b">发展建议：</p>
    <p>1.需要发展果断性技能；</p>
    <p>2.需要学会在适当的基础上给人以建设性的反馈；</p>
    <p>3.需要和他人一道检讨自己的眼光；</p>
    <p>4.需要放松，对于目前情况下能够完成的事情，应当报有更开放的态度。</p>
    `,
  },
  INTJ: {
    tag: '<h1>INTJ(内倾、直觉、思维和判断)</h1><h2>能力+独立=完美</h2>',
    subscribe:
      '<p>1.具强大动力与本意来达成目的与创意—固执顽固者。</p><p>2.有宏大的愿景且能快速在众多外界事件中找出有意义的模范。</p><p>3.对所承负职务，具良好能力于策划工作并完成。</p><p>4.具怀疑心、挑剔性、独立性、果决，对专业水准及绩效要求高。</p>',
    ms: `<p>INTJ型的人是完美主义者。他们强烈地要求个人自由和能力，同时在他们独创的思想中，不可动摇的信仰促使他们达到目标。</p>
    <p>INTJ型的人思维严谨、有逻辑性、足智多谋，他们能够看到新计划实行后的结果和生活中转变为真实物质的理论体系。他们对自己和别人都很苛求，往往几乎同样强硬地逼迫别人和自己。他们并不十分受冷漠与批评的干扰，作为所有性格类型中最独立的，INTJ型的人更喜欢以自己的方式行事。面对相反意见，他们通常持怀疑态度，十分坚定和坚决。权威本身不能强制他们，只有他们认为这些规则对自己的更重要的目标有用时，才会去遵守。</p>
    <p>INTJ型的人是天生的谋略家，具有独特的思想、伟大的远见和梦想。他们天生精于理论，对于复杂而综合的概念运转灵活。他们是优秀的战略思想家，通常能清楚地看到任何局势的利处和缺陷。对于感兴趣的问题，他们是很好的、具有远见和见解的组织者。如果足他们自己形成的看法和计划，他们会投入不可思议的专心、注意力和能量、积极性。依靠到达或超过自己的高标准的决心和坚韧不拔，他们获得许多成就。</p>`,
    md: `<p>由于有时对一些不切实际的高标准充满幻想，INTJ型的人对自己和他人的期望过多。事实上，他们往往不在意自己如何符合别人的标准，重要的是自己。他们对寸：自己的行为如何影响他人缺乏理解，往往在提供改进意见时挑剔而直率。他们经常不鼓励他人提出自己的观点或表达个人感情。因为INTJ型的人相当冷淡，所以他们错误地推论别人也希望受到同样方式的对待。他们需要学习理解别人貌似“非理性”的感情，认可它们是合理可取的。这有助于防止他们疏远和激怒周围的人。</p>
    <p>因为INTJ型人过多地重视他们对未来的见解和想法，所以很容易忽略现在的重要事情和现实。他们也无法认识到自己思想中事实上的缺点，这会使他们的想法实施更加困难。收集所有相关的和真实的材料有助于确信他们的想法的可操作性。lntj型的人需要减化自己理论性的、复杂的思想，这样才能把自己的想法传达给别人。</p>
    <p>因为INTJ型的人在工作中常常选择孤独，一心一意地努力，所以他们忽视了在活动中邀请别人参加或协助。征求别人的信息和建议会帮助他们在进程中早些认识到不切实际的想法，或者帮助他们在投人大量时间之前，做出一些改动和提高。</p>
    <p>对于INTJ型的人来说，增加影响在于对次要的观点做出让步以赢得更重要的东西。这也会减少INTJ型的人变得太固执的可能性。当他们努力采用一种更易被接受的方式生活和与人相处时，INTJ型的人会得到更多的平衡、自信，成功地使白己更多的创新被社会接受。</p>`,
    qn: `<p>外向与内向是指我们心理能量的作用方向，判断与知觉是我们做事的两种方式。心理学称之为“态度”。感觉与直觉是我们获取信息的方式，思考和情感是做决定的方式，心理学称之为“功能”，功能是人格理论的精髓、核心。</p>
    <p>每一个人在都会用到四种功能，但对功能应用的喜好程度不一样。等级中第一位的称为“主导功能”，第二位的称为“辅助功能”。每个人都需要运用到这两个过程。</p>
    <p>INTJ型的人主导功能是直觉（关注可能性），辅助功能是思维。他们倾向于：</p>
    <p>探求隐含的原因、可能性或事物的整体。对事实和经验做出符合逻辑结论。</p>
    <p>INTJ型的人比较少地使用他们的第三、第四功能--情感和感觉。即使在需要这些功能的场合，他们仍然无法有效使用。</p>
    <p>作为一个entj型的人：如果你过多地关注直觉，你可能错过事物的相关事实、细节或以前的经验。如果你过多的使用思考功能，你可能在需要的时间忘记去称赞他人，或忽视你决策对其它人的影响。</p>`,
    jjwt: `<p>每个人都会用自己的方式进行决策，决策通常来自于你的主导功能，并且完全忽视你的第四功能。你的功能运用如下：</p>
    <p>主导功能直觉——辅助功能思维——第三功能情感——第四功能感觉</p>
    <p>人格中的每一种功能对解决问题都具有关键和重要作用，忽视任何一个都将导致做出有缺陷的决定，理想化的问题解决功能流程如下：</p>
    <p>一个好的决策通常来自于四个功能的合理运用。当你尝试去解决问题时，你可以利用上表指导这个过程，但在整个的执行过程是非常困难的，因为每个人都不愿意用他不喜欢的功能去解决问题。就算你有意识地努力运用所有这些功能，你也不可能会在“真实生活”中以这种顺序运用它们。关键是要利用它们提醒自己，完善决策，就算你不按这种方式行事。</p>
    <p>工作中选择符合你的主导功能和辅助功能的岗位是非常重要的。第三功能要经过发展锻炼才能合适运用，第四功能可能永远不会在你的职业生涯中起到关键作用，因此你应当避免那种要求长期使用它的工作。</p>`,
    ys: `<p>对于不同的人格类型和不同的动力等极而言，没有“好”与“坏”之分，每一个人都是一个独一无二的个体，都有其特别的优势和劣势，但问题的关键在于如何认识这些优势和劣势,完善自己。</p>
    <p>我们对于成功的建议是：“取已之长，补已之短”。学会了这一点将会影响到你的成败及你对工作的喜好。</p>
    <p class="b">你在工作中的优势：</p>
    <p>1.能看到事情的可能发展情况及其潜在含义</p>
    <p>2.喜欢复杂理论及智力上的挑战</p>
    <p>3.有创造性地解决问题的天资，能客观地审查问题</p>
    <p>4.及时在面对阻扰时也会义无反顾地去实现目标</p>
    <p>5.自信，且对自己的设想会不顾一切地采取行动去实行</p>
    <p>6.对于在工作中胜任和胜出有强烈动机</p>
    <p>7.能很好地适应一个人单独工作，独立、自主</p>
    <p>8.标准高，工作原则性强</p>
    <p>9.能创造出方法体系和模式来达到你得目标</p>
    <p>10.擅长于从事技术性工作</p>
    <p>11.能逻辑地、分析地做出决定</p>
    <p>12.坚决果断，有高度的组织能力</p>`,
    ls: `<p>下面列出了你工作中可能存在的缺点，这些缺点有的比较明显，有的并不明显或你没有意识到，目的是为了让你“注意”到它们，并考虑产生的原因。缺点有些是天生的，而有些是长时间形成的，因此你不可能在一两天内改变，而是去提醒、思考。注意：其实知道存在的问题就是改变提高中很重要的一步，你会发现你正在慢慢发生变化。</p>
    <p class="b">您工作中的劣势：</p>
    <p>1.当计划中创造性部分完成后对该计划失去兴趣</p>
    <p>2.易于像紧逼自己工作一样去逼着别人工作</p>
    <p>3.对那些反应不如你敏捷的人缺乏耐心</p>
    <p>4.不愿意和那些你认为能力没有你强的人一起工作</p>
    <p>5.唐突、不机智、缺乏交际手段，尤其在你匆忙时</p>
    <p>6.对一些世俗小事没有兴趣</p>
    <p>7.对自己的观点顽固地坚持</p>
    <p>8.有想要去改变那些根本没有必要改善的事物的倾向</p>
    <p>9.易于过于理论化而不去考虑实际情况</p>
    <p>10.不愿意花时间适当地欣赏、夸奖雇员、同事或别人</p>
    <p>11.对那些既定问题不愿再审查</p>
    <p>12.易于过分强调工作，从而损害了家庭的和谐</p>
    <p>13.对一些工作所要求的“社交细节”没有耐心</p>`,
    gwtz: `<p>研究发现：职业满足会使你的工作主动性更强，积极性更高，更愿意去工作。以下不是简单的告诉你什么样的工作适合你，而是细化的帮你分析工作中的哪些特质对你重要，你还需要从中选出你认为最重要的，因为不同经历的人对特质的重要程度要求是不同的。每个岗位的工作内容都在随企业的发展而发展，不是一成不变的，有时候岗位的发展方向需要我们自己去争取。所以找到适合的工作不如找到适合自己发展的岗位更直接。这些特质可以帮助明确如何主动的发展或争取你岗位中的那些特质。</p>
    <p>下面的条目从各个侧面上认证了您怎样才能受到真正的职业满足，看完这些条目之后，我们建议您根据它们对您的重要程序进行排序，排序的时候，回想一下您过去的学习、工作经历以及当前学习环境和工作感受，并思考：“哪一些是令你感到特别满意，有哪些令你极其不高兴”。试着寻找贯穿这些经历的主题。</p>
    <p>你的岗位特质：作为INTJ型人，职业满足意味着你做的这些工作：</p>
    <p>1、允许我创作并发展一些新颖的、创造的解决问题的方法来改善现行的体系。</p>
    <p>2、允许我集中精力于实施我的想法，以一种逻辑的、系统的方式工作，同时，在工作中我的工作能有所回报。</p>
    <p>3、让我能和别的队工作尽职尽责的人一起工作，同时他们的专业技术及其聪明才干是我所敬佩的。</p>
    <p>4、为我新奇的计划而给我应有的荣誉，让我持有该计划的原始创作全，以及允许我对这一计划的事实持有控制权。</p>
    <p>5、能我让独立工作，但定期与一小群才华横溢的人在一个没有人际冲突的、平和的工作环境中互相交流想法。</p>
    <p>6、能让我平时常受到新的信息流的冲击，提供给我新的途径来提高卧的机制和胜任能力。</p>
    <p>7、允许我创作一项成功，它必须符合我自己高标准的要求，而非为了迎合别人的喜好。</p>
    <p>8、我的工作并不要求我去完成那些琐屑的任务。</p>
    <p>9、给我提供高度的自主与自我支配。允许我自幼地改变意图，发展人们的潜在能力以及开发新的方法体系。</p>
    <p>10、所有的一切都以同样的、公平的标准来评价，对工作情况的评估应该给予既定的标准而非给予个性的角逐，同时我的付出要得到相应的回报。</p>`,
    zy: `<p>也许你会觉得下面的工作种类繁杂，但仔细分析你就会发现，这些工作各自的特色正是你对工作的要求，我们会在下面列出各类工作的特质。当然，这不可能是一个完美的适合你的工作的综合，但是，却能够向你展示你以前不曾考虑过的工作的可能性。在列出这些工作的同时，我们也要敬告您：每种职业中都有各种性格类型的成功人士；即使是相同的工作，不同公司的要求也不尽相同，所以除了工作名称外，你还要对贵公司要求的工作内容和公司文化作详细的了解。我们希望在经济快速发展、新型工作不断涌现将来，这份报告上列举的工作种类不是限制了你的选择，而是告诉你，面对新工作的选择或机会时，应该从哪些因素来分析这些工作对你的适合程度。</p>
    <p class="b">适合INTJ型人的一般职业</p>
    <p class="flex"><span>商业／金融</span><span>电信安全</span><span>经济学家</span><span>国际银行家</span><span>药物研究员</span><span>财务计划人</span><span>投资银行家</span><span>管理顾问：电脑／信息服务</span></p>
    <p>上述商业和金融领域都要求高度发展的分析能力，这是许多INTJ型人所具有的。对于INTJ型人，智力挑战在获得职业满足感的时候必不可少。而在药物研究这一日益发展的高科技领域以及电信保险领域大量存在这样的挑战。不断变化的投资和国际银行业也充分地利用了INTJ型人具有的全球眼光和长期规划的能力。</p>
    <p class="flex"><span>科技</span><span>科学家／科学研究员</span><span>技术人员：电力／电子</span><span>设计工程师</span><span>宇航员</span><span>电脑程序员</span><span>环境规划人</span><span>生物医学研究员／工程师</span><span>电脑系统分析员</span><span>操作研究分析员</span><span>信息服务开发人</span><span>软件和系统研究员和开发员</span><span>网络一体化专家(电信业)</span><span>信息服务——新业务开发人</span>
    <p>科技领域吸引了INTJ型人对逻辑体系的兴趣。这些领域提供了迅速发展高科技产品和设备的工作机会。INTJ型人往往能够发挥他们的创造力来开发天才而富创造的系统。</p>
    <p class="flex"><span>教育</span><span>教师：大学</span><span>学习课程设计人</span><span>行政管理人</span><span>数学家</span></p>
    <p>高等教育比初级教育和中级教育更能吸引INTJ型人，因为它往往涉及到教授更具整体眼光的理论和系统。制定教育课程或系统并且确保它们有效进行允许INTJ型人不断进行改善进步。高等教育领域也使INTJ型人可以收集和管理各种信息，和其他聪明的人打交道，这使他们得以发展自身的能力。</p>
    <p class="flex"><span>健康护理／医药</span><span>精神病学家</span><span>心理学家</span><span>神经病学家</span><span>生物医学工程师</span><span>心病学家</span><span>药物学家</span><span>药物研究员</span><span>生物医学研究员</span></p>
    <p>更要求技术性的医药学领域往往是许多INTJ型人能够获得成功和满足的地方。这些领域包括极其复杂的系统，允许INTJ型人独立地进行工作，而具最小限度的外界打扰或投入。</p>
    <p class="flex"><span>专门职业</span><span>代理人：行政管理／诉讼</span><span>管理顾问</span><span>战略设计人</span><span>投资／商业分析家</span><span>经理</span><span>法官</span><span>新闻分析员／撰稿人</span><span>工程师</span><span>冶金工程师</span><span>知识产权律师</span><span>土木工程师</span></p>
    <p>专门职业提供给INTJ型人各种各样有吸引力的方面。其中只有“经理”要求独立的调查和计划。发展战略、体系和长景计划发挥了INTJ型人的未来倾向性(直觉功能)以及设计如何以有序而逻辑的方式达到目标的能力。</p>
    <p>INTJ型经理往往会在一个雇员和他或她非常相像的小机构里获得职业满足感。如果职员不要求大量的个人支持或手把手的监督，那么INTJ型人在管理中会更可能得到满足感。</p>
    <p class="flex"><span>创造性职业</span><span>作家／社论作家</span><span>艺术家</span><span>发明家</span><span>设计人</span><span>建筑师</span><span>整体设计建筑师</span><span>信息制图设计师</span><span>自由媒体设计人</span><span>编辑／艺术指导</span></p>
    <p>创造性职业的吸引力在于它能让人进行创造性工作。作家和艺术家运用他们的直觉创造新的表现和陈述。发明家能够创造新系统或新装置来改善生活方式或解决令人烦恼的问题。这三种职业都要求INTJ型人独立地工作，达到他们自己的目标和标准，他们自己来做最后的评判人。</p>`,
    jy: `<p>现在你对自己的人格类型和动力已经有了一个比较清楚的了解，但这还不够。“如何通过这些信息使你在这份工作上取得更大的成功”，这是关键所在。运用你的能力非常容易，你成功的秘诀在于：</p>
    <p>1.考虑实际情况；</p>
    <p>2.认同别人所提意见的价值；</p>
    <p>3.平衡你的工作和个人生活。</p>
    <p>个人发展建议是我们咨询师多年测评职业咨询和职业生涯规划的心得体会和经验总结，我们意识到以下的建议中有很多是难以完全照办的，但只要你花时间认真思考，一定会对你有极大的帮助和改变：</p>
    <p>发展建议：</p>
    <p>1.需要引发反馈和建议；</p>           
    <p>2.需要学会欣赏他人；</p>
    <p>3.需要学会在何时放弃不实际的想法；    </p>  
    <p>4.需要更加关注其思想对他人的影响。</p>
    `,
  },
  ISTP: {
    tag: '<h1>ISTP（内倾、感觉、思维和知觉）</h1><h2>用我已经得到的，做到最好</h2>',
    subscribe:
      '<p>1.冷静旁观者—安静、预留余地、弹性及会以无偏见的好奇心与未预期原始的幽默观察与分析。</p><p>2.有兴趣于探索原因及效果，技术事件是为何及如何运作且使用逻辑的原理组构事实、重视效能。</p><p>3.擅长于掌握问题核心及找出解决方式。</p><p>4.分析成事的缘由且能实时由大量资料中找出实际问题的核心。</p>',
    ms: `<p>ISTP型的人坦率、诚实、讲求实效，他们喜欢行动而非漫谈。他们很谦逊，对于完成工作的方法有很好的理解力。</p>
    <p>ISTP型的人擅长分析，所以他们对客观含蓄的原则很有兴趣。他们对于技巧性的事物有天生的理解力，通常精于使用工具和进行手工劳动。他们往往做出有条理而保密的决定，他们仅仅是按照自己所看到的、有条理而直接地陈述事实。</p>
    <p>ISTP型的人好奇心强，而且善于观察，只有理性、可靠的事实才能使他们信服。他们重视事实，简直就是有关他们知之甚深的知识的宝库。他们是现实主义者，所以能够很好地利用可获得的资源，同时他们擅于把握时机，这使他们变得很讲求实效。</p>
    <p>ISTP型的人平和而寡言，往往显得冷酷而清高，而且容易害羞，除了是与好朋友在一起时。他们平等、公正。他们往往受冲动的驱使，对于即刻的挑战和问题具有相当的适应性和反应能力。因为他们喜欢行动和兴奋的事情，所以他们乐于户外活动和运动。</p>`,
    md: `<p>因为ISTP型的人独自做决定，所以他们常常对即使足最重要的事情也只是自我保留，这使得在他们生活中的人对于即将发生的事情茫然不知，他们很难与别人分享行动、情感，也很少关心别人，因为他们认为这些是没有必要的。他们必须接受的足，其他人希望和必须知道自己生活中将要发生的事情，同时ISTP型的人也必须意识到他们正是能够向别人提供一个准确解释的人。</p>
    <p>ISTP型的人是如此的现实，以致于对几乎所有的计划，他们都知道使努力达到最小化的方法。他们渴望拥有自由的时间，所以他们的准备不会多于基本的必要或者不可能坚持到计划得出结果，因为这样的结果是他们能够节省时间和精力。描绘出整个计划，完成所有的步骤和细节，这样会约束他们潜在的主动性的缺乏，淡化他们漠不关心的表象。</p>
    <p>ISTP型的人始终都注意着新的感官信息，喜欢开放地面对所有可进行的选择，所以他们会优柔寡断。对于兴奋事物的需求使得他们很草率而易于厌烦。树立目标，对于他人和事情都做出严肃的承诺，这样会帮助他们避免普通的失望和随意的生活方式的潜在危险。</p>`,
    qn: `<p>外向与内向是指我们心理能量的作用方向，判断与知觉是我们做事的两种方式。心理学称之为“态度”。感觉与直觉是我们获取信息的方式，思考和情感是做决定的方式，心理学称之为“功能”，功能是人格理论的精髓、核心。</p>
    <p>每一个人在都会用到四种功能，但对功能应用的喜好程度不一样。等级中第一位的称为“主导功能”，第二位的称为“辅助功能”。每个人都需要运用到这两个过程。</p>
    <p class="b">有效地使用你的类型：(ISTP)</p>
    <p>ISTP型的人主导功能是思考（喜欢事实），辅助功能是感觉。他们倾向于：</p>
    <p>合乎逻辑擅于分析、客观、公正、有逻辑系统的思考、具拟判能力、坚定。</p>
    <p>注意细节、重视实际、能记住琐碎细节、耐得住烦闷的工作、有耐性、细心有系统。</p>
    <p>ISTP型的人比较少地使用他们的第三、第四功能——感觉和情感。即使在需要这些功能的场合，他们仍然无法有效使用。</p>
    <p>作为一个ISTP型的人：</p>
    <p>如果你过多的使用思考功能，你可能忽略他人感受、误解别人的价值观、不在意和谐的人、不露感情、悯情较少、不能说服他人。</p>
    <p>如果你过多地关注细节，你可能失去整体的概念、想不出各种可能解决的途径、不住直觉、不求创新、无法应付太复杂的工作、不喜欢预测未来。</p>`,
    jjwt: `<p>每个人都会用自己的方式进行决策，决策通常来自于你的主导功能，并且完全忽视你的第四功能。你的功能运用如下：</p>
    <p>主导功能思维——辅助功能感觉——第三功能直觉——第四功能情感</p>
    <p>人格中的每一种功能对解决问题都具有关键和重要作用，忽视任何一个都将导致做出有缺陷的决定，理想化的问题解决功能流程如下：</p>
    <p>一个好的决策通常来自于四个功能的合理运用。当你尝试去解决问题时，你可以利用上表指导这个过程，但在整个的执行过程是非常困难的，因为每个人都不愿意用他不喜欢的功能去解决问题。就算你有意识地努力运用所有这些功能，你也不可能会在“真实生活”中以这种顺序运用它们。关键是要利用它们提醒自己，完善决策，就算你不按这种方式行事。</p>
    <p>工作中选择符合你的主导功能和辅助功能的岗位是非常重要的。第三功能要经过发展锻炼才能合适运用，第四功能可能永远不会在你的职业生涯中起到关键作用，因此你应当避免那种要求长期使用它的工作。</p>`,
    ys: `<p>对于不同的人格类型和不同的动力等极而言，没有“好”与“坏”之分，每一个人都是一个独一无二的个体，都有其特别的优势和劣势，但问题的关键在于如何认识这<p>些优势和劣势,完善自己。我们对于成功的建议是：“取已之长，补已之短”。学会了这一点将会影响到你的成败及你对工作的喜好。</p>
    <p class="b">你在工作中的优势：</p>
    <p>*出色处理限定任务和实质产品的能力</p>
    <p>*敏锐的观察力和对事实信息的出色记忆力</p>
    <p>*将混乱的数据和可辨认的事实有序排列的能力</p>
    <p>*独自工作或与你敬佩的人并肩工作的态度  </p>
    <p>*在压力之下面对危机保持头脑冷静的能力</p>
    <p>*知道完成工作需要做什么和必须作什么的能力  </p>
    <p>*用手和工具工作的态度</p>
    <p>*对突然变化和迅速发生的转变适应良好的能力</p>
    <p>*实际性和丰富的常识</p>
    <p>*确认和利用有效资源的能力</p>
    <p>*柔韧性和愿意冒险以及尝试新事物</p>`,
    ls: `<p>下面列出了你工作中可能存在的缺点，这些缺点有的比较明显，有的并不明显或你没有意识到，目的是为了让你“注意”到它们，并考虑产生的原因。缺点有些是天生的，而有些是长时间形成的，因此你不可能在一两天内改变，而是去提醒、思考。注意：其实知道存在的问题就是改变提高中很重要的一步，你会发现你正在慢慢发生变化。</p>
    <p class="b">您工作中的劣势：</p>
    <p>*难以看到行动的深远影响</p>
    <p>*缺乏进行言语交流的兴趣，尤其是表面上的交流</p>
    <p>*不喜欢事先准备，你在组织时间上有一定困难</p>
    <p>*对抽象、复杂的理论缺乏耐心</p>
    <p>*有对别人的感觉迟钝麻木的倾向</p>
    <p>*有容易变得厌烦和焦躁的倾向</p>
    <p>*难以看到不前不存在的机会和选择</p>
    <p>*对行政上的细节和程序缺乏耐心</p>
    <p>*不愿意重复自己</p>
    <p>*难以做出决定</p>
    <p>*很强的独立性，不喜欢过多的条条框框、官僚作风</p>
    <p>*地址确定长期目标，难以到达最后期限</p>`,
    gwtz: `<p>研究发现：职业满足会使你的工作主动性更强，积极性更高，更愿意去工作。以下不是简单的告诉你什么样的工作适合你，而是细化的帮你分析工作中的哪些特质对你重要，你还需要从中选出你认为最重要的，因为不同经历的人对特质的重要程度要求是不同的。每个岗位的工作内容都在随企业的发展而发展，不是一成不变的，有时候岗位的发展方向需要我们自己去争取。所以找到适合的工作不如找到适合自己发展的岗位更直接。这些特质可以帮助明确如何主动的发展或争取你岗位中的那些特质。</p>
    <p>下面的条目从各个侧面上认证了您怎样才能受到真正的职业满足，看完这些条目之后，我们建议您根据它们对您的重要程序进行排序，排序的时候，回想一下您过去的学习、工作经历以及当前学习环境和工作感受，并思考：“哪一些是令你感到特别满意，有哪些令你极其不高兴”。试着寻找贯穿这些经历的主题。你的岗位特质：</p>
    <p>1、让我确认和使用以最有效的可能方式提供的资源。</p>
    <p>2、让我实践掌握，然后运用拥有的技能，尤其是机械技能和那些需要使用工具的能力。</p>
    <p>3、让我运用自己对周围世界的理解和技术上的知识，看到工作中潜在的逻辑原理；让我参与解决困难和难题。</p>
    <p>4、有明确的方向；在那儿我能方便地工作，生产真实、实际的产品。</p>
    <p>5、有趣且充满活力，让我独立地进行工作，并且经常有机会到工作领域外和户外活动。</p>
    <p>6、在一个没有其他人强加的过多规则和操作标准的环境中工作；在那儿我能享受自然发生的冒险，并且逐步应付任何危机。</p>
    <p>7、让我在最小限度的监督下独立工作，而且我也没有被要求去密切监督别人。</p>
    <p>8、给我足够的时间去发展自己的兴趣、爱好。</p>
    <p>9、给我相当数量的娱乐和不断地挑战。</p>
    <p>10、让我有效地利用装置和能源，而不要求必要的路线和步骤。</p>`,
    zy: `<p>您适合的职业：也许你会觉得下面的工作种类繁杂，但仔细分析你就会发现，这些工作各自的特色正是你对工作的要求，我们会在下面列出各类工作的特质。当然，这不可能是一个完美的适合你的工作的综合，但是，却能够向你展示你以前不曾考虑过的工作的可能性。在列出这些工作的同时，我们也要敬告您：每种职业中都有各种性格类型的成功人士；即使是相同的工作，不同公司的要求也不尽相同，所以除了工作名称外，你还要对贵公司要求的工作内容和公司文化作详细的了解。我们希望在经济快速发展、新型工作不断涌现将来，这份报告上列举的工作种类不是限制了你的选择，而是告诉你，面对新工作的选择或机会时，应该从哪些因素来分析这些工作对你的适合程度。</p>
    <p  class="b">适合ISTP型人的一般职业</p>
    <p class="flex"><span>销售部门／服务部门／劳改部门</span><span>警员或劳教人员</span><span>赛车手</span><span>飞机驾驶员</span><span>武器操作员</span><span>猎人</span><span>情报人员</span><span>司法官</span><span>消防员</span><span>调查员</span><span>运动器材商品推销</span><span>药品推销</span><span>私人调查或私人侦探</span></p>
    <p>ISTP型人的职业偏好源于他们不愿受一大堆条框束缚的观念。他们激动时总发挥得很好。他们也喜欢那种自主的环境，他们可以发挥他们的能力很快地对手头的资源作出估计。然后采取适宜的步骤。他们善于各自工作，必要时，也乐于作为集体成员。ISTP型的人喜欢掌握某种特别工具或机器的使用方法，也喜欢户外生活和身体运动。
    技术部门·电器设备专家·信息产业开发人员·技术培训人员——一对一设置的·后勤和供给制造商或经理·网络调查专家(通讯部门)·电脑程序设计师·海洋生物学家·电器工程师、机械工程师、土木工程师</p>
    <p>ISTP类型的人常可以从技术行业中获得满足感是因为他们对物体如何和如何运作感兴趣。他们在机械领域表现优秀是因为、他们有很强的观察能力和记忆使用事实细节的能力。他们通常喜欢手工活，也喜欢能不断给他们提供感官信息的工作。当他们的逻辑分析是建立在通过自己五种感觉获得的可靠事实上时，总能取得良好的结果。
    <p class="flex"><span>健康护理业</span><span>心电图专家或技师</span><span>透射技师</span><span>急救医生</span><span>运动保健医生</span><span>牙医牙科助理</span></p>
    <p>这些医疗保健工作对于ISTP型人而言具有吸引力是源于他们的较高的技术天分。这里的每一种工作都要求严格的准确性，良好的实践和操作能力、耐性和投入，这些对使用和保养敏感的诊断设备都是必需的。</p>
    <p><span>商业／金融业</span><span>证券分析家</span><span>采购员</span><span>办公室管理人员</span><span>银行家</span><span>经济学家</span><span>法律顾问
    </span><span>业务顾问</span><span>律师帮办</span></p>
    <p>由于对数字的注重和精确意识，ISTP型的人在商业和金融业也可以获得满足。工作环境是相当重要，但ISTP型的人更容易喜欢个人自由不受限制的职业。自主的工作，没有太多的会议和复杂的人际关系都是必要的。</p>
    <p>ISTP型的人常常能使混乱的数据和事实有序化。他们可以轻易地发掘经济情况之间的关系，易于和能够对紧急变化做出反应。</p>
    <p><span>贸易部门</span><span>计算机维修</span><span>飞机技师</span><span>农场人员</span><span>教练</span><span>木工</span><span>汽车部件零售商</span><span>商业家</span></p>
    <p>贸易行业的独立性和实践性常常吸引ISTP类型的人。ISTP型的人偏爱切实具体的，能上手的工作。如果项目本身能完全吸引他们的注意力，他们会坚忍不拔地做下去。所以如果他们对体育感兴趣，相似的贸易职业中他们更愿选择作教练员。从业余爱好中成就一项事业，是ISTP型的绝好的策略。</p>`,
    jy: `<p>现在你对自己的人格类型和动力已经有了一个比较清楚的了解，但这还不够。“如何通过这些信息使你在这份工作上取得更大的成功”，这是关键所在。运用你的能力非常容易，你成功的秘诀在于：</p>
    <p>*学会交流；</p>
    <p>*考虑别人的感受；</p>
    <p>*信守承诺。</p>
    <p>个人发展建议是我们咨询师多年测评职业咨询和职业生涯规划的心得体会和经验总结，我们意识到以下的建议中有很多是难以完全照办的，但只要你花时间认真思考，一定会对你有极大的帮助和改变：</p>
    <p class="b">发展建议：</p>
    <p>*需要深入与他人交流沟通；</p>
    <p>*需要发展坚毅力，为达到期望结果需要作必要的计划并付出必要的努力；*需要形成设立目标的习惯。</p>
    `,
  },
  ISFP: {
    tag: '<h1>ISFP（内倾、感觉、情感和知觉）</h1><h2>思想起决定作用</h2>',
    subscribe:
      '<p>1.羞怯的、安宁和善地、敏感的、亲切的、且行事谦虚。</p><p>2.喜于避开争论，不对他人强加已见或价值观。</p><p>3.无意于领导却常是忠诚的追随者。</p><p>4.办事不急躁，安于现状无意于以过度的急切或努力破坏现况，且非成果导向。</p><p>5.喜欢有自有的空间及照自订的时程办事。</p>',
    ms: `<p>ISFP型的人平和、敏感，他们保持着许多强烈的个人现想和自己的价值观。他们更多地是通过行为而不是言辞表达自己深沉的情感。</p>
    <p>ISFP型的人谦虚而缄默，但实际上他们是具有巨大的友爱和热情的人，但是除了与他们相知和信赖的人在一起外，他们不经常表现出自我的另一面。因为他们不喜欢直接地自我表达，所以常常被误解。</p>
    <p>ISFP型的人耐心、灵活，很容易与他人相处，很少支配或控制别人。他们很客观，以一种相当实事求是的方式接受他人的行为。他们善于观察周围的人和物，却不寻求发现动机和含义。</p>
    <p>因为ISFP型的人完全生活在目前之中，所以他们的准备或计划往往不会多于必需，他们是很好的短期计划制定者。因为他们完全投入于现在，喜欢享受目前的经历，而不继续向下一个目标冲刺，所以他们对完成工作感到很放松。</p>
    <p>他们对于从经历中直接了解和感受的东西很感兴趣，常常富有艺术天赋和审美感，力求为自己创造一个美丽而隐蔽的环境。</p>
    <p>没有想要成为领导者，ISFP型的人经常是忠诚的追随者和团体成员。因为他们利用个人的价值标准去判断生活中的每一件事，所以他们喜欢那些花费时间去认识他们和理解他们内心的忠诚之人。他们需要最基本的信任和理解，在生活中需要和睦的人际关系，对于冲突和分歧则很敏感。</p>`,
    md: `<p>高度的敏感天性使ISFP型的人清楚地了解别人的需求，他们有时过度地工作以满足那些需求，以至于在这一过程中忽略了自我。他们必须更多地关心自己而不是别人。</p>
    <p>因为他们完全把注意力集中于现在他们的经历，所以往往无法看得更远，从而丧失了更广泛的视野。有时他们很难理解一个较为复杂的环境事务。因为他们通常不会寻找或观察在目前不存在的可能发生的事物，所以他们往往不会事先准备，也很难有条理地安排时间和资财。他们本来是应该努力工作以约束自己的冲动，从而完成任务和享受——段安静时光，或是参加一项自己喜欢的活动。</p>
    <p>ISFP型的人往往做个人的反省，然后变得很生气和失望，所以说他们是很容易受别人影响的。由于他们总是按人和事物所呈现的表象来认识他们，却从来不会预想其中的不良动机或从中推断出其他的内容，所以人们常认为他们过于信任他人，很容易上当受骗。他们必须对自己的需求更加关心，进一步考虑他人行为的含义。对自己的分析更多地具有怀疑精神和客观性，会使他们的判断力更出色。</p>`,
    qn: `<p>外向与内向是指我们心理能量的作用方向，判断与知觉是我们做事的两种方式。心理学称之为“态度”。感觉与直觉是我们获取信息的方式，思考和情感是做决定的方式，心理学称之为“功能”，功能是人格理论的精髓、核心。</p>
    <p>每一个人在都会用到四种功能，但对功能应用的喜好程度不一样。等级中第一位的称为“主导功能”，第二位的称为“辅助功能”。每个人都需要运用到这两个过程。</p>
    <p class="b">有效地使用你的类型：(ISFP)</p>
    <p>ISFP型的人主导功能是思考（喜欢事实），辅助功能是感觉。他们倾向于：</p>
    <p>对事实和经验做出符合逻辑结论。</p>
    <p>注意细节、重视实际、能记住琐碎细节、耐得住烦闷的工作、有耐性、细心有系统。</p>
    <p>ISFP型的人比较少地使用他们的第三、第四功能—直觉和情感。即使在需要这些功能的场合，他们仍然无法有效使用。</p>
    <p>作为一个ISFP型的人：</p>
    <p>如果你过多的使用思考功能，你可能在需要的时间忘记去称赞他人，或忽视你决策对其它人的影响。</p>
    <p>如果你过多的使用思考功能，你可能失去整体的概念、想不出各种可能解决的途径、不住直觉、不求创新、无法应付太复杂的工作、不喜欢预测未来。</p>`,
    jjwt: `<p>每个人都会用自己的方式进行决策，决策通常来自于你的主导功能，并且完全忽视你的第四功能。你的功能运用如下：</p>
    <p>主导功能思维——辅助功能感觉——第三功能直觉——第四功能情感</p>
    <p>人格中的每一种功能对解决问题都具有关键和重要作用，忽视任何一个都将导致做出有缺陷的决定，理想化的问题解决功能流程如下：</p>
    <p>一个好的决策通常来自于四个功能的合理运用。当你尝试去解决问题时，你可以利用上表指导这个过程，但在整个的执行过程是非常困难的，因为每个人都不愿意用他不喜欢的功能去解决问题。就算你有意识地努力运用所有这些功能，你也不可能会在“真实生活”中以这种顺序运用它们。关键是要利用它们提醒自己，完善决策，就算你不按这种方式行事。</p>
    <p>工作中选择符合你的主导功能和辅助功能的岗位是非常重要的。第三功能要经过发展锻炼才能合适运用，第四功能可能永远不会在你的职业生涯中起到关键作用，因此你应当避免那种要求长期使用它的工作。</p>`,
    ys: `<p>对于不同的人格类型和不同的动力等极而言，没有“好”与“坏”之分，每一个人都是一个独一无二的个体，都有其特别的优势和劣势，但问题的关键在于如何认识这<p>些优势和劣势,完善自己。我们对于成功的建议是：“取已之长，补已之短”。学会了这一点将会影响到你的成败及你对工作的喜好。</p>
    <p class="b">你工作中的优势可能包括：</p>
    <p>*热情，慷慨</p>
    <p>*对你很关心的和组织的忠诚</p>
    <p>*注意重要的细节，尤其是那些有关他人的细节</p>
    <p>*考虑周到，具备关注目前所需的能力</p>
    <p>*主动愿意支持组织的目标</p>
    <p>*准确评估目前的能力和看出什么是最需要保持稳定的能力</p>
    <p>*仔细评估冒风险和试用新方法时的灵活性和主动性</p>`,
    ls: `<p>下面列出了你工作中可能存在的缺点，这些缺点有的比较明显，有的并不明显或你没有意识到，目的是为了让你“注意”到它们，并考虑产生的原因。缺点有些是天生的，而有些是长时间形成的，因此你不可能在一两天内改变，而是去提醒、思考。注意：其实知道存在的问题就是改变提高中很重要的一步，你会发现你正在慢慢发生变</p>化。您工作中的劣势：</p>
    <p>*有只接受事物的表面现象而忽略事物深层暗示的倾向</p>
    <p>*没有能力观察到目前不存在的机会和可选择的机会</p>
    <p>*做出对个人的批评和消极的反馈趋势</p>
    <p>*不愿意提早准备；你在利用自己的时间上有问题</p>
    <p>*决断的困难</p>
    <p>*不喜欢过多的规则和结构过于复杂的机构</p>
    <p>*在于自己的感受相矛盾时很难做出符合逻辑的决定</p>
    <p>*不愿意为坚持自己的想法和立场而冒险打破与他人的协调关系</p>
    <p>*有会被大量的极其复杂的任务压得喘不过气来的趋势</p>
    <p>*反对制定长期的目标，很难按时完成任务</p>
    <p>*不会很自觉地做直接的报告或批评他人</p>`,
    gwtz: `<p>研究发现：职业满足会使你的工作主动性更强，积极性更高，更愿意去工作。以下不是简单的告诉你什么样的工作适合你，而是细化的帮你分析工作中的哪些特质对你重要，你还需要从中选出你认为最重要的，因为不同经历的人对特质的重要程度要求是不同的。每个岗位的工作内容都在随企业的发展而发展，不是一成不变的，有时候岗位的发展方向需要我们自己去争取。所以找到适合的工作不如找到适合自己发展的岗位更直接。这些特质可以帮助明确如何主动的发展或争取你岗位中的那些特质。</p>
    <p>下面的条目从各个侧面上认证了您怎样才能受到真正的职业满足，看完这些条目之后，我们建议您根据它们对您的重要程序进行排序，排序的时候，回想一下您过去的学习、工作经历以及当前学习环境和工作感受，并思考：“哪一些是令你感到特别满意，有哪些令你极其不高兴”。试着寻找贯穿这些经历的主题。</p>
    <p class="b">你的岗位特质：作为ISFP型人，职业满足意味着你做的这些工作：</p>
    <p>1、是与自身很强的内在价值以及自己所关心和愿意为之贡献自己经历和才智的事业是一致的。</p>
    <p>2、如果要与他人合作在积极肯定的团队中工作，自己会是其中一名忠诚又富于合作精神的一分子。</p>
    <p>3、要求注意到细微之处，因为在工作中我所接触到的事对他人有益而又有实际应用价值的具体事物。</p>
    <p>4、除了与其他和蔼、谦虚地人为伍外，还要给我留有一定的空间，使我能够独立地完成工作，而不会感受到过多的规章和僵化的操作规程的束缚。</p>
    <p>5、使我成为适应能力极强而且负责的人。在工作中，我明确的目的，能够让我亲眼看到和亲身体会到我所做的工作的结果。</p>
    <p>6、让我通过审美和品味来增强我工作领域的吸引力，使其更加个性化，并使其他人感到工作在其中会更舒适。</p>
    <p>7、是在非常愉悦而又充满合作意识的氛围中完成的。人际之间的冲突会保持在最低限度。</p>
    <p>8、给我一个机会，使我在完成我认为很重要的工作内容过程中体验到自身的成长和发展。</p>
    <p>9、能让我提供实用的帮助，及时而简洁地处理好问题。</p>
    <p>10、不要求我做定期的公开演说。以及领导一大群我太熟知的人或给别人消极的反馈评价。</p>`,
    zy: `<p>也许你会觉得下面的工作种类繁杂，但仔细分析你就会发现，这些工作各自的特色正是你对工作的要求，我们会在下面列出各类工作的特质。当然，这不可能是一个完美的适合你的工作的综合，但是，却能够向你展示你以前不曾考虑过的工作的可能性。在列出这些工作的同时，我们也要敬告您：每种职业中都有各种性格类型的成功人士；即使是相同的工作，不同公司的要求也不尽相同，所以除了工作名称外，你还要对贵公司要求的工作内容和公司文化作详细的了解。我们希望在经济快速发展、新型工作不断涌现将来，这份报告上列举的工作种类不是限制了你的选择，而是告诉你，面对新工作的选择或机会时，应该从哪些因素来分析这些工作对你的适合程度。</p>
    <p class="b">适合ISFP型人的一般职业</p>
    <p class="flex"><span>工艺部门</span><span>时装设计</span><span>木匠</span><span>珠宝商</span><span>园艺匠</span><span>织毯工人</span><span>陶工</span><span>漆匠</span><span>舞蹈员</span><span>设计人员：内容／背景</span><span>厨师长</span></p>
    <p>这些职业对ISFP型的入主要吸引力在于它们能提供用他们的双手创造外观以吸引人，更多的是有用在实际工作中，他们喜欢运用五种感觉，从事实际的工作。那些职业为，ISFP型的人提供了灵活的时间和自由安排工作的机会。大多数ISFP型的人喜欢自主，不必遵守管理规则。</p>
    <p class="flex"><span>健康护理部门</span><span>上门服务护士</span><span>运动专家</span><span>按摩医生</span><span>放射技师</span><span>医务助理</span><span>牙科助理／保健医师</span><span>兽医助理</span><span>动物护理人员／训练人员</span><span>家庭保健助理</span><span>初级保健大夫</span><span>饮食专家／营养学家</span><span>验光师／配镜师</span><span>运动心理专家</span><span>职业疗法医师</span><span>工艺师</span><span>药剂师</span><span>呼吸系统专家</span><span>执行医护士</span></p>
    <p>ISFP型的人常可以从健康护理业中获得满足感，尤其是那些能让他们直接同用户和患者打交道的工作。他们喜欢许多治疗工作的亲手参与的特征——在危机中或危机后，从身体到感情上帮助他人。他们通常善于观察，能对微小变化做出反应，喜欢解决短期问题。对于ISFP型的人而言，从这些职业以及其它任何职业获得满足感的最重要成分是能看到或体验对他们成就的反馈以及能意识到他们工作的重要性。</p>
    <p class="flex"><span>技术工作</span><span>调查员</span><span>计算机操作人员</span><span>林务员</span><span>植物学家</span><span>地质学家</span><span>技师</span><span>海洋生物学家</span></p>
    <p>ISFP型的人喜欢实际工作，不喜欢理论工作。他们倾向于喜欢运用实际的积极的技术，户外工作的机会尤其为ISFP型的人喜欢，他们对工作中的变化和种类表现出极大的兴趣。</p>
    <p class="flex"><span>销售／服务业</span><span>教师：初级(自然科学／艺术)</span><span>警察／劳改官员</span><span>紧急热线电话操作员</span><span>清洁服务人员</span><span>仓库保管员</span><span>侍者</span><span>美容师</span><span>旅行用品推销</span><span>优质用户销售代表</span><span>商业计划人员</span><span>体育设备推销</span><span>家用保健品推销</span><span>家庭保健护理人员</span><span>儿童福利咨询人员</span><span>戒酒戒毒顾问</span><span>社会工作人员</span></p>
    <p>许多ISFP型的人在服务部门找到了让他们满意的工作。让他们从事满足人或动物的需要的工作最值得干。他们喜欢能分享他们价值观，培养和鼓励人际和谐、合作，以及能认可他们的成绩的工作环境。许多ISFP型的人喜欢处在特定的主观环境，通常和小孩在一起，在这种环境中，他们有机会表露自然性和乐趣。</p>
    <p class="flex"><span>商业</span><span>记帐员</span><span>司法员</span><span>打字员</span><span>职员监督人员</span><span>经理人员</span><span>律师帮办</span></p>
    <p>职员工作，如果是处于一种适合的环境，也能给ISFP型的人提供满足。关键因素是在一种积极支持的气氛中运用他们实际技能的能力。当作为小组一部分而工作，或处于一种能尊重个人隐私和成长的稳定环境中时，ISFP型的人工作总很出色。他们喜欢能让他们创造和保持愉快的、个性化的工作环境的职业部门。</p>`,
    jy: `<p>现在你对自己的人格类型和动力已经有了一个比较清楚的了解，但这还不够。“如何通过这些信息使你在这份工作上取得更大的成功”，这是关键所在。运用你的能力其实很容易。</p>
    <p class="b">ISFP成功的秘诀就是：</p>
    <p>*学会声明自己的主张；</p>
    <p>*脚踏实地，在更广阔的背景下考虑问题；</p>
    <p>*不要太个人化地看待事物。</p>
    <p>个人发展建议是我们咨询师多年测评职业咨询和职业生涯规划的心得体会和经验总结，我们意识到以下的建议中有很多是难以完全照办的，但只要你花时间认真思考，一定会对你有极大的帮助和改变：</p>
    <p class="b">发展建议：</p>
    <p>*需要发展怀疑和分析信息的方法，而不是一味接受；</p>
    <p>*需要学会在他人自鸣得意时作出否定性反馈；</p>
    <p>*需要发展一个更加未来导向的前景；</p>
    <p>*需要更果敢和更直接地对待他人。</p>
    `,
  },
  INFP: {
    tag: '<h1>INFP（内倾、直觉、情感和知觉）</h1><h2>大智若愚</h2>',
    subscribe: `
    <p>1.安静观察者，具理想性与对其价值观及重要之人具忠诚心。</p>
    <p>2.希外在生活形态与内在价值观相吻合。</p>
    <p>3.具好奇心且很快能看出机会所在。常担负开发创意的触媒者。</p>
    <p>4.除非价值观受侵犯，行事会具弹性、适应力高且承受力强。</p>
    <p>5.具想了解及发展他人潜能的企图。想作太多且作事全神贯注。</p>
    <p>6.对所处境遇及拥有不太在意。</p>
    <p>7.具适应力、有弹性除非价值观受到威胁。</p>`,
    ms: `<p>INFP把内在的和谐视为高于其他一切。他们敏感、理想化、忠诚，对于个人价值具有，一种强烈的荣誉感，个人信仰坚定，有为自认为有价值的事业献身的精神。</p>
    <p>INFP型的人对于已知事物之外的可能性很感兴趣，精力集中于他们的梦想和想象。他们思维开阔、有好奇心和洞察力，常常具有出色的长远目光。在日常事务中，他们通常灵活多变、具有忍耐力和适应性。但是他们非常坚定地对待内心的忠诚，为自己设立了事实上几乎是不可能的标准。</p>
    <p>INFP型的人具有许多使他们忙碌的理想和忠诚。他们十分坚定地完成自己所选择的事情，他们往往承担得太多，但不管怎样总要完成每件事。</p>
    <p>虽然对外部世界他们显得冷淡缄默，但INFP型的人很关心内在。他们富有同情心、理解力，对于别人的情感很敏感。除了他们的价值观受到威胁外，他们总是避免冲突，没有兴趣强迫或支配别人。</p>
    <p>INFP型的人常常喜欢通过书写而不是口头来表达自己的感情。当INFP型的人劝说别人相信他们的想法的重要性时，可能是最有说服力的。INFP很少显露强烈的感情，常常显得沉默而冷静。然而，一旦他们认识了，就会变得热情友好。INFP的人很友好，但也避免浮浅的交往。他们珍视那些花费时间去思考目标与价值的人。</p>`,
    md: `<p>由于不如ENFJ型的人有条理性，他们有时会对事实判断错误，不能意识到自己的非逻辑性。当他们的梦想脱离现实时，其他人可能认为他们充满怪想、神神秘秘。INFP型的人应该很好地向更现实的人请教，他们的理想在现实世界中是否可行和有用。</p>
    <p>因为INFP型的人如此坚信自己的理想，所以他们常常忽视其他观点的作用，而且有时会很刻板。他们对于物质环境不十分有兴趣，他们经常很忙碌以至于没有注意周围正在发生的变化。</p>
    <p>INFP型的人对于一种想法的酝酿要比实际中开始一个计划所需要的时间长很多。他们完美主义的倾向导致他们长久的精炼思想而从来没有分享过它们。这是很危险的，因为对于INFP型的人，更要的是寻找表达他们思想的途径。为了避免沮丧，他们需要使工作变得更重视行动。</p>
    <p>INFP型的人很情绪化地陷于自己的工作中，所以对于批评很敏感，更加复杂的是，当他们追求自己不可能有的高标准时，往往对自己的要求太多。即使事实上他们能够完成许多事情，但仍会导致情感上的不满足。当INFP型的人失望时，往往对于他们周围所有的事物都很对立。努力发展他们的计划客观性会有助于防止INFP型的人更少地受批评与失望的影响。</p>
    <p>因为INFP型的人往往努力让许多人同时高兴，所以让他们坚持一种不受欢迎的立场是很困难的。对于批评别人，他们感到犹豫不决，也很少会说“不”。当INFP型的人对于一些想法和计划没有表达他们的相反意见时，其他人会误以为INFP型的人同意他们的观点。INFP型的人需要培养更多的敢做敢为的信心，才能学会在必要的时候对他人提出诚恳的批评。</p>`,
    qn: `<p>外向与内向是指我们心理能量的作用方向，判断与知觉是我们做事的两种方式。心理学称之为“态度”。感觉与直觉是我们获取信息的方式，思考和情感是做决定的方式，心理学称之为“功能”，功能是人格理论的精髓、核心。</p>
    <p>每一个人在都会用到四种功能，但对功能应用的喜好程度不一样。等级中第一位的称为“主导功能”，第二位的称为“辅助功能”。每个人都需要运用到这两个过程。</p>
    <p>有效地使用你的类型：(INFP)</p>
    <p>INFP型的人主导功能是情感（关注情感），辅助功能是直觉。他们倾向于：</p>
    <p>体谅他人感受、了解他人的需要、喜欢和谐的人际关系、易表露情感、喜去说服他人。</p>
    <p>探求隐含的原因、可能性或事物的整体。</p>
    <p>INFP型的人比较少地使用他们的第三、第四功能--感觉和思维。即使在需要这些功能的场合，他们仍然无法有效使用。</p>
    <p>作为一个INFP型的人：如果你过多地关注情感，你可能会表现得不合乎逻辑、不够客观、没有组织系统的思考、不具批判精神全盘接受、感情用事。如果你过多地关注直觉，你可能错过事物的相关事实、细节或以前的经验。</p>`,
    jjwt: `<p>每个人都会用自己的方式进行决策，决策通常来自于你的主导功能，并且完全忽视你的第四功能。你的功能运用如下：</p>
    <p>主导功能情感——辅助功能直觉——第三功能感觉——第四功能思维</p>
    <p>人格中的每一种功能对解决问题都具有关键和重要作用，忽视任何一个都将导致做出有缺陷的决定，理想化的问题解决功能流程如下：</p>
    <p>一个好的决策通常来自于四个功能的合理运用。当你尝试去解决问题时，你可以利用上表指导这个过程，但在整个的执行过程是非常困难的，因为每个人都不愿意用他不喜欢的功能去解决问题。就算你有意识地努力运用所有这些功能，你也不可能会在“真实生活”中以这种顺序运用它们。关键是要利用它们提醒自己，完善决策，就算你不按这种方式行事。</p>
    <p>工作中选择符合你的主导功能和辅助功能的岗位是非常重要的。第三功能要经过发展锻炼才能合适运用，第四功能可能永远不会在你的职业生涯中起到关键作用，因此你应当避免那种要求长期使用它的工作。</p>`,
    ys: `<p>对于不同的人格类型和不同的动力等极而言，没有“好”与“坏”之分，每一个人都是一个独一无二的个体，都有其特别的优势和劣势，但问题的关键在于如何认识这些优势和劣势,完善自己。我们对于成功的建议是：“取已之长，补已之短”。学会了这一点将会影响到你的成败及你对工作的喜好。</p>
    <p class="b">你在工作中的优势：</p>
    <p>*考虑周到细致且能集中注意力深入某个问题或观点</p>
    <p>*渴望打破常规思考，并考虑新的可能情况</p>
    <p>*积极投身于所信仰的事业</p>
    <p>*必要时一个人也能很好地工作</p>
    <p>*对收集所需信息有一种天生的好奇与技巧</p>
    <p>*能通观全局以及看到意识与行为之间的联系</p>
    <p>*能洞察别人的需要与动机</p>
    <p>*适应能力强，能很快改变你的行事速度及目标</p>
    <p>*在一对一的基础上很极好地与人工作</p>`,
    ls: `<p>下面列出了你工作中可能存在的缺点，这些缺点有的比较明显，有的并不明显或你没有意识到，目的是为了让你“注意”到它们，并考虑产生的原因。缺点有些是天生的，而有些是长时间形成的，因此你不可能在一两天内改变，而是去提醒、思考。注意：其实知道存在的问题就是改变提高中很重要的一步，你会发现你正在慢慢发生变化。</p>
    <p class="b">您工作中的劣势：</p>
    <p>*必须控制方案/计划，否则你可能会失去兴趣</p>
    <p>*有变得无秩序性的倾向，很难把握优先处理的事</p>
    <p>*不愿做与自己价值观相冲突的工作</p>
    <p>*在做事方式上不愿按照传统方式</p>
    <p>*天生的理想主义，这样可能使你得不到现实的期望</p>
    <p>*讨厌以传统的或惯常的方式行事</p>
    <p>*很难在竞争的、气氛紧张的环境中工作下去</p>
    <p>*在处理及完成顽固的组织和人们打交道时没有耐心</p>
    <p>*在预计做某事要求多长时间时有不切实际的倾向</p>
    <p>*不愿惩戒直接肇事者，不愿意批评别人</p>`,
    gwtz: `<p>研究发现：职业满足会使你的工作主动性更强，积极性更高，更愿意去工作。以下不是简单的告诉你什么样的工作适合你，而是细化的帮你分析工作中的哪些特质对你重要，你还需要从中选出你认为最重要的，因为不同经历的人对特质的重要程度要求是不同的。每个岗位的工作内容都在随企业的发展而发展，不是一成不变的，有时候岗位的发展方向需要我们自己去争取。所以找到适合的工作不如找到适合自己发展的岗位更直接。这些特质可以帮助明确如何主动的发展或争取你岗位中的那些特质。</p>
    <p>下面的条目从各个侧面上认证了您怎样才能受到真正的职业满足，看完这些条目之后，我们建议您根据它们对您的重要程序进行排序，排序的时候，回想一下您过去的学习、工作经历以及当前学习环境和工作感受，并思考：“哪一些是令你感到特别满意，有哪些令你极其不高兴”。试着寻找贯穿这些经历的主题。</p>
    <p>你的岗位特质：作为INFP型人，职业满足意味着你做的这些工作：</p>
    <p>1、我的工作与我个人的价值观和信仰相一致，同时允许我通过工作表达我的想象力。</p>
    <p>2、给我时间发展我的想法到相当深度，同时对这一思维过程以及思维产物操持控制权。</p>
    <p>3、独立完成工作，有一个私人的工作空间以及大量不受干扰的时间，但必须有机会与我敬重的人交流一下观点。</p>
    <p>4、我的工作环境是一个灵活性强的组织机构，其中繁琐的规章制度减至最少限度，同时允许我有灵感时工作。</p>
    <p>5、我要在一个合作的环境中与别的有创造力的、讨人喜欢的人一起工作，且这个工作环境没有紧张的人际关系以及人际纠纷。</p>
    <p>6、允许我表达我别出心裁的观点，而且在工作中个人的发展受到鼓励与夸奖。</p>
    <p>7、不要要求我经常在大群人面前介绍我的工作，或者在我的工作还没有完成至我满意之前让我与大家分享。</p>
    <p>8、愿意帮助别人成长、发展以及实现他们所有的潜能。</p>
    <p>9、我的工作包括理解别人以及发掘他们行为的动机，允许我发展与别人一对一的深厚关系。</p>
    <p>10、允许我为实现我的理想而工作，且工作上不要受到政治的、经济的或别的方面的障碍的限制。</p>`,
    zy: `<p>也许你会觉得下面的工作种类繁杂，但仔细分析你就会发现，这些工作各自的特色正是你对工作的要求，我们会在下面列出各类工作的特质。当然，这不可能是一个完美的适合你的工作的综合，但是，却能够向你展示你以前不曾考虑过的工作的可能性。在列出这些工作的同时，我们也要敬告您：每种职业中都有各种性格类型的成功人士；即使是相同的工作，不同公司的要求也不尽相同，所以除了工作名称外，你还要对贵公司要求的工作内容和公司文化作详细的了解。</p>
    <p>我们希望在经济快速发展、新型工作不断涌现将来，这份报告上列举的工作种类不是限制了你的选择，而是告诉你，面对新工作的选择或机会时，应该从哪些因素来分析这些工作对你的适合程度。适合INFP型人的一般职业</p>
    <p class="flex"><span>创造性职业／艺术</span><span>艺术家</span><span>作家：诗人／小说家</span><span>记者</span><span>娱乐人士</span><span>建筑师</span><span>演员</span><span>编辑</span><span>音乐家</span><span>信息制图设计师</span><span>编辑／艺术指导(杂志)</span></p>
    <p>INFP型人为艺术所吸引是由于他们有能力以富于创造的个人方式来表达他们自己和他们的想法。以艺术为工作具有个人的自由和灵活性，这是INFP型人信奉的生活方式。无论是写文章、画画还是用其他手段来工作，无论是进行设计还是作为演员或音乐家运用他们自己的身体而工作，INFP型人都力求创造独到的成果，这是他们心声的真实表现。许多INFP型人把自己描述为“内心深沉”的艺术家，即使他们并不以艺术为生。一些INFP型人甚至会说，作艺术家并不是他们做出的选择，而是他们必须背负的十字架。</p>
    <p class="flex"><span>教育／咨询职业</span><span>大学教授：人文／艺术</span><span>调研员</span><span>心理学家</span><span>顾问</span><span>社会工作者</span><span>图书管理员</span><span>教育顾问</span><span>特殊教育老师</span><span>双语种教育老师</span><span>儿童早期教育老师</span><span>雇员帮助顾问</span><span>儿童福利顾问</span><span>酒精和毒品禁戒顾问</span><span>翻译／口译</span><span>法律调停人</span><span>社会工作者(老年人和儿童日常护理问题)</span></p>
    <p>教育和咨询行业都是可以使INFP型人为帮助他人成长和发掘人性潜能而工作的领域。INFP型人为改善他人生活质量的愿望和努力的确非常崇高。他们更喜欢大学，而不是小学或中学，因为大学里来自学生们的动力更强大。他们喜欢学习的过程，喜欢作为调查员或图书管理员来探求更深刻而更有意义的理解。INFP型人是富于同情心而具洞察力的顾问，心理学家，以及社会工作者，力求帮助他们的客尸在自己的社会关系和生活范围里获得自我理解和和谐。作为顾问，他们高兴的是，在理解他人的时候他们也逐渐理解了自己。</p>
    <p class="flex"><span>宗教职业</span><span>牧师</span><span>宗教教育工作者</span><span>传教士</span><span>教堂工作人员</span></p>
    <p> 对于许多INFP型人，献身宗教事业是很值得的。他们乐于帮助人们发展精神世界，在为自己和他人的梦想奋斗并得以实现的过程中他们深感快乐。他们往往喜欢一个对一个的交流，但随着经历增多，他们也渐渐乐于传道和做报告了。对于INFP型人，最根本的地方在于从事与他们内心价值观念和信仰一致的工作，往往宗教领域的职业可以满足这一点。</p>
    <p class="flex"><span>医疗保健</span><span>饮食学家／营养学家</span><span>理疗医生</span><span>家庭健康社会工作者</span><span>职业治疗医生</span><span>按摩专家</span><span>全面健康医生</span><span>语言病理学家／听觉病理学家</span></p>
    <p>保健领域吸引INFP型人的方面在于，它能够让他们对客户或病人进行亲切而亲密的工作。INFP型人一般都会喜欢这样的职业给予他们的自主性，他们可以自己开业，或者作一家大型健康护理机构的顾问。理疗、全身治疗以及按摩的诊断治疗工作具有创造性和精神因素，这些都令人满意，它们运用了INFP型人的直觉和情感倾向。</p>
    <p class="flex"><span>机构发展</span><span>雇员发展专家</span><span>人力资源开发</span><span>社会科学家</span><span>多样化管理人</span><span>顾问工作组构建／冲突解决</span></p>
    <p>尽管INFP型人通常不会对商业领域的工作感到满意，仍然有一些筛选出来的领域有可能让INFP型人获得成功和满足感。一些INFP型人喜欢合作的环境，有时他们的工作涉及到帮助别人找到适合于自己的工作。他们喜欢人事或人力资源发展方面的工作，喜欢在一个公司内部设计并安置工作。他们需要与支持他们的人共同工作，从而感到他们所做的贡献是独特而且受人重视的。这样，在竞争激烈的商业世界，他们才会找到满足感。</p>`,
    jy: `<p>现在你对自己的人格类型和动力已经有了一个比较清楚的了解，但这还不够。“如何通过这些信息使你在这份工作上取得更大的成功”，这是关键所在。运用你的能力非常容易，你成功的秘诀在于：</p>
    <p>*设定切合实际的期望值；</p>
    <p>*重视让步；</p>
    <p>*不要太主观地看待问题。</p>
    <p>个人发展建议是我们咨询师多年测评职业咨询和职业生涯规划的心得体会和经验总结，我们意识到以下的建议中有很多是难以完全照办的，但只要你花时间认真思考，一定会对你有极大的帮助和改变。
    <p class="b">发展建议：</p>
    <p>*需要学会现实地工作而不光是追求完美；</p>
    <p>*需要发展其坚韧、讲究实际和说“不”的自觉行动；</p>
    <p>*需要更加强调和重视事实和逻辑；</p>
    <p>*需要发展和实施行动计划。</p>
    `,
  },
  INTP: {
    tag: '<h1>INTP（内倾、直觉、思维和知觉）</h1><h2>聪颖机智解决问题的人</h2>',
    subscribe: `
    <p>1.安静、自持、弹性及具适应力。</p>
    <p>2.特别喜爱追求理论与科学事理。</p>
    <p>3.习于以逻辑及分析来解决问题—问题解决者。</p>
    <p>4.最有兴趣于创意事务及特定工作，对聚会与闲聊无　大兴趣。</p>
    <p>5.追求可发挥个人强烈兴趣的生涯。</p>
    <p>6.追求发展对有兴趣事务之逻辑解释。</p>`,
    ms: `<p>INTP型的人是解决理性问题者。他们很有才智和条理性，以及创造才华的突出表现。</p>
    <p>INTP型的人外表平静、缄默、超然；但内心却专心致志于分析问题。他们苛求、精细、惯于怀疑。他们努力寻找和利用原则以理解许多想法。他们喜欢有条理和目的的交谈，而且可能会仅仅为了高兴。争论一些无益而琐细的问题，只有有条理的推理才会使他们信服。</p>
    <p>通常INTP型的人是足智多谋、有独立见解的思考者。他们重视才智，对于个人能力有强烈的欲望，有能力也很感兴趣向他人挑战。INTP型的人最主要的兴趣在于理解除了能够准确知道、接受和明显的事物之外的可能性。他们乐于为了改进事物的目前状况或解决难题而发展模式。他们的思考方式极端复杂，而且他们能更好地组织概念和想法而不是人。偶尔，他们的想法非常复杂，以致于很难向别人表达和被他人理解。</p>
    <p>INTP型的人十分独立，喜欢冒险和富有想象力的活动。他们灵活易变、思维开阔，更感兴趣的是发现有创见而且合理的解决方法，而不是仅仅看到成为事实的解决方式。</p>`,
    md: `<p>因为INTP型的人过分依赖他们的条理性分析，所以他们会忽视与别人有关的事物。如果某一方面不符合逻辑，即使对于他们来说很重要，他们也会冒险抛弃。他们承认自己所真正关心的会有助于与真实的情感保持联系。</p>
    <p>INTP型的人精通于观察——种思想的缺陷，但对于表达自己的评价却沉默寡言。因为他们拒绝在整个计划中存在一点不符合逻辑的地方，所以他们在涉及计划某部分的一个细微错误之时迟滞不前，使整个方案难以完成。当他们把自己高度磨练过的批评性思考运用到周围的人身上时，他们直率的诚实会变为一种无意的伤害。必须告诉他们，而且他们必须学会询问在情感上与别人相关的事务。</p>
    <p>因为INTP型的人喜爱解决问题，所以往往他们会对常规的细节缺少耐心，而且如果一个汁划需要过多的细节或持久贯彻，他们会失去兴趣而永远完成不了计划。向外转移他们的能量会使他们获得充分的实际知识以产生可行的、让别人能接受的想法。当INTP型的人努力维持自己高标准的完美时，他们有时会想到不合适。学会与别人分享感受会帮助他们获得更现实和客观的见解。</p>`,
    qn: `<p>外向与内向是指我们心理能量的作用方向，判断与知觉是我们做事的两种方式。心理学称之为“态度”。感觉与直觉是我们获取信息的方式，思考和情感是做决定的方式，心理学称之为“功能”，功能是人格理论的精髓、核心。</p>
    <p>每一个人在都会用到四种功能，但对功能应用的喜好程度不一样。等级中第一位的称为“主导功能”，第二位的称为“辅助功能”。每个人都需要运用到这两个过程。</p>`,
    jjwt: `<p>每个人都会用自己的方式进行决策，决策通常来自于你的主导功能，并且完全忽视你的第四功能。你的功能运用如下：</p>
    <p>主导功能感觉——辅助功能思维——第三功能情感——第四功能直觉</p>
    <p>人格中的每一种功能对解决问题都具有关键和重要作用，忽视任何一个都将导致做出有缺陷的决定，理想化的问题解决功能流程如下：</p>
    <p>一个好的决策通常来自于四个功能的合理运用。当你尝试去解决问题时，你可以利用上表指导这个过程，但在整个的执行过程是非常困难的，因为每个人都不愿意用他不喜欢的功能去解决问题。就算你有意识地努力运用所有这些功能，你也不可能会在“真实生活”中以这种顺序运用它们。关键是要利用它们提醒自己，完善决策，就算你不按这种方式行事。</p>
    <p>工作中选择符合你的主导功能和辅助功能的岗位是非常重要的。第三功能要经过发展锻炼才能合适运用，第四功能可能永远不会在你的职业生涯中起到关键作用，因此你应当避免那种要求长期使用它的工作。</p>`,
    ys: `<p>对于不同的人格类型和不同的动力等极而言，没有“好”与“坏”之分，每一个人都是一个独一无二的个体，都有其特别的优势和劣势，但问题的关键在于如何认识这些优势和劣势,完善自己。我们对于成功的建议是：“取已之长，补已之短”。学会了这一点将会影响到你的成败及你对工作的喜好。</p>
    <p class="b">你在工作中的优势：</p>
    <p>*急切地“想知道盒子外面的世界”，能想出一些新的可能性</p>
    <p>*能够理解非常复杂和高度抽象的概念</p>
    <p>*杰出的创造性地解决问题的技能</p>
    <p>*独立自主；探险精神、创造意识以及克服困难的勇气</p>
    <p>*能够综合运用大量的信息</p>
    <p>*搜集所需信息时理智的好奇心、独特的洞悉力</p>
    <p>*即使在压力很大的情况下也能逻辑地分析事物</p>
    <p>*学习新知识的信心和动力都很大</p>
    <p>*客观性；能够客观地分析和处理问题，而不是感情用事</p>
    <p>*对自己的想法和观点充满信心</p>
    <p>*能够把握事物的全局，弄清行为和思想的长远影响</p>
    <p>*能灵活地适应新情况，有数量的随机应变的能力</p>`,
    ls: `<p>下面列出了你工作中可能存在的缺点，这些缺点有的比较明显，有的并不明显或你没有意识到，目的是为了让你“注意”到它们，并考虑产生的原因。缺点有些是天生的，而有些是长时间形成的，因此你不可能在一两天内改变，而是去提醒、思考。注意：其实知道存在的问题就是改变提高中很重要的一步，你会发现你正在慢慢发生变化。</p>
    <p class="b">您工作中的劣势：</p>
    <p>*办事情可能条理不清，容易发生紊乱</p>
    <p>*过于自信；可能会不恰当地运用自己的能力和社会经历</p>
    <p>*对思维狭窄和思想固执的人缺乏耐心</p>
    <p>*不喜欢按传统的、公式化的方式来办事</p>
    <p>*问题一旦解决，兴趣便不复存在</p>
    <p>*不擅长于把复杂的思想和问题用简明的形式表现出来并用简单的形式将其解决</p>
    <p>*可能过于理论化，而忽视或无视现实性</p>
    <p>*不能严格要求自己去考虑且解决重要的细节性问题</p>
    <p>*不喜欢重复地做一件事</p>
    <p>*对程式化的事情和固执的人缺乏耐心</p>`,
    gwtz: `<p>研究发现：职业满足会使你的工作主动性更强，积极性更高，更愿意去工作。以下不是简单的告诉你什么样的工作适合你，而是细化的帮你分析工作中的哪些特质对你重要，你还需要从中选出你认为最重要的，因为不同经历的人对特质的重要程度要求是不同的。每个岗位的工作内容都在随企业的发展而发展，不是一成不变的，有时候岗位的发展方向需要我们自己去争取。所以找到适合的工作不如找到适合自己发展的岗位更直接。这些特质可以帮助明确如何主动的发展或争取你岗位中的那些特质。</p>
    <p>下面的条目从各个侧面上认证了您怎样才能受到真正的职业满足，看完这些条目之后，我们建议您根据它们对您的重要程序进行排序，排序的时候，回想一下您过去的学习、工作经历以及当前学习环境和工作感受，并思考：“哪一些是令你感到特别满意，有哪些令你极其不高兴”。试着寻找贯穿这些经历的主题。</p>
    <p class="b">你的岗位特质：作为INTP型人，职业满足意味着你做的这些工作：</p>
    <p>1、让我开发、分析、批判新的想法和主意。</p>
    <p>2、让我把注意力和精力集中到那些创新的、理论的和逻辑的思维过程中，而不只一心关注着结果和产品会是如何。</p>
    <p>3、富有挑战性，涉及到许多复杂的问题；因此，我能有机会试行一些部落俗套的方案，通过冒险性的实践找到最佳解决方法。</p>
    <p>4、让我有充足的安静的私有时间独立地工作，来投入到并完成自己的思维过成。</p>
    <p>5、让我自己制定并保留评估自己工作的高层次的标准，并在此基础上决定自己的成绩与不足。</p>
    <p>6、在一个富有的、可以通融的环境中进行，没有那些无用的规章制度，过多的限制和不必要的会议。</p>
    <p>7、能让我和一小组我所钦佩的亲密朋友和伙伴合作。</p>
    <p>8、让我经常有机会增长自己的能力和才干，与别的有影响的成功人士会面、合作。</p>
    <p>9、让我开发有独创性的思想和方案，能把一些具体的实施步骤和细节问题委托给一名高效率的助手。</p>
    <p>10、不要求我直接管理别人、监督或考虑一些人际关系得协调或不和。</p>`,
    zy: `<p>也许你会觉得下面的工作种类繁杂，但仔细分析你就会发现，这些工作各自的特色正是你对工作的要求，我们会在下面列出各类工作的特质。当然，这不可能是一个完美的适合你的工作的综合，但是，却能够向你展示你以前不曾考虑过的工作的可能性。在列出这些工作的同时，我们也要敬告您：每种职业中都有各种性格类型的成功人士；即使是相同的工作，不同公司的要求也不尽相同，所以除了工作名称外，你还要对贵公司要求的工作内容和公司文化作详细的了解。我们希望在经济快速发展、新型工作不断涌现将来，这份报告上列举的工作种类不是限制了你的选择，而是告诉你，面对新工作的选择或机会时，应该从哪些因素来分析这些工作对你的适合程度。</p>
    <p class="b">适合INTP型人的一般职业</p>
    <p class="flex"><span>计划和开发</span><span>计算机软件设计员</span><span>计算机程序员</span><span>系统分析人员／数据库管理人员</span><span>调查开发专家</span><span>战略策划者</span><span>新市场或新产品开发者</span><span>网络一体化专家(电信专家)</span><span>转换管理方式的咨询</span><span>财政计划者</span><span>投资银行家</span><span>信息服务开发者——计算机程序设计</span><span>信息服务——新的商业开发领域</span><span>管理咨询：计算机／信息服务</span></p>
    <p>计划和开发领域为INTP们提供了一个发挥他们特长的机会——分析问题并找到创新性的答案。大多数INTP喜欢工作在抟术领域，他们运用自己理解复杂系统的能力和发现错误、消除错误的能力，去分析问题、解决问题。INTP能够轻易地看出产品、服务或系统是否适合整个公司、工厂或整套工艺。他们喜欢以创新的、更有效的方法去处理问题。</p>
    <p class="flex"><span>保健／技术</span><span>神经病学家</span><span>物理学家</span><span>整形外科医生</span><span>药剂师</span><span>科学家：化学／生物</span><span>药品调查员</span><span>生物工程学家</span><span>兽医</span></p>
    <p>医药卫生和其他科技领域使INTP杰出的推理能力和利用科技资料的能力得到运用，INTP对不断发展的神经病学、整形外科学、生物医学和配药学方面的研究都很感兴趣。因为这些学科使INTP能够站在科学发展的最前沿进行创新，有时甚至还有一些冒险。物理、化学和生物学为INTP提供了复杂的概念以及不断学习新知识和不断问“如果……”的机会。这些学科能够允许INTP进行独立工作，特别是当它们涉及到一些较强的研究工作的时候。同时，由于这些领域有难度、富于竞争性，所以吸引了许多有才华、有智慧的人，这对INTP也是一个刺激和挑战。</p>
    <p class="flex"><span>专门领域</span><span>律师</span><span>经济学家</span><span>心理学家／精神分析学家</span><span>金融分析家</span><span>建筑师</span><span>侦探</span><span>知识产权代理人</span><span>法律调解人</span><span>公司财务代理人</span><span>精神病医生</span></p>
    <p>这些专业领域也为INTP提供了许多分析并解决复杂问题的机会。通常，这些领域都是极富挑战性的，要求研究人员有清晰的逻辑思维能力，对付问题和挑战要有创新性的解决方法。对建筑师和心理学家来说，工作中心都是一个创造的过程。善于看清楚一个因素或一个条件在整个模型或系统中的地位和作用是INTP的一个特别优势，同时也是调查研究者和金融分析家工作中的一个主要内容。从制定毫无纰漏的法律条文到预测经济发展的微妙走势，这些都为INTP提供了感受兴奋和接受挑战的机会。</p>
    <p class="flex"><span>学术领域</span><span>数学家</span><span>考古学家</span><span>历史学家</span><span>哲学家</span><span>大学里的高级教师</span><span>学术研究者</span><span>逻辑学家</span><span>大学行政官员</span><span>经济学家</span><span>翻译</span></p>
    <p>学术领域充满刺激的世界是INTP非常喜欢的，因为这个领域着重开发新事物，寻找新的解决问题方法。INTP经常在大学教授这个职位上找到职业满足感。他们通常愿意给高年级学生讲授一些比较专深的课程。INTP喜欢做上面所列出的任何一门学科的调查研究工作，他们喜欢这种能够独自工作的机会，然后与同行们共享他们的成果与创新。</p>
    <p>INTP最喜欢在没有复杂规则限制、没有官僚政治的环境中工作，然而，这两点恰恰是大多数大学院校所不能避免的。</p>
    <p class="flex"><span>创造性的职业</span><span>摄影师</span><span>富有创造力的作家</span><span>艺术家</span><span>演员／舞蹈家</span><span>音乐家</span><span>代理人</span><span>发明家</span><span>信息图表设计者</span></p>
    <p>对INTP来说，这些职业最吸引他们的地方是可以充分发挥想象力、完全自由地进行创造。INTP喜欢利用不同的手段和已往的经验与不同的人共同进行创造的过程。INTP通常喜欢一个人工作或者只与少量可能会给工作带来转变的人们一起工作。由于对自己的能力和表达自己兴趣的艺术实力的自信，INTP非常欣赏自己的作品。但是，他们并不为了追求满足感而必须完成作品。</p>
    <p>很多INTP喜欢由富有创造力的人所组成的世界，因此他们喜欢作代理人。他们创造的新事物、新产品和新的服务方式给他们带来了类似于发明家一样的成功。注意，这些职业只能给具有独一无二的、与生俱来的才能的INTP带来职业满足感。</p>`,
    jy: `<p>现在你对自己的人格类型和动力已经有了一个比较清楚的了解，但这还不够。“如何通过这些信息使你在这份工作上取得更大的成功”，这是关键所在。运用你的能力非常容易，你成功的秘诀在于：</p>
    <p>*更加有条理；</p>
    <p>*对聪颖不足的人要有耐心；</p>
    <p>*设法增进自己的社交能力。</p>
    <p>个人发展建议是我们咨询师多年测评职业咨询和职业生涯规划的心得体会和经验总结，我们意识到以下的建议中有很多是难以完全照办的，但只要你花时间认真思考，一定会对你有极大的帮助和改变：</p>
    <p class="b">发展建议：</p>
    <p> *需要关注实际的细节，发展坚定的实施能力；</p>
    <p>*需要花力气将事情说得更简单些；</p>
    <p>*需要对他人给予的信息表示欣赏；</p>
    <p>需要更多地了解他人以及他人的职业。</p>
    `,
  },
  ESTP: {
    tag: '<h1>ESTP（外倾、感觉、思维和知觉）</h1><h2>创业者</h2>',
    subscribe: `
    <p>1.擅长现场实时解决问题—解决问题者。</p>
    <p>2.喜欢办事并乐于其中及过程。</p>
    <p>3.倾向于喜好技术事务及运动，交结同好友人。</p>
    <p>4.具适应性、容忍度、务实性；投注心力于会很快具　成效工作。</p>
    <p>5.不喜欢冗长概念的解释及理论。</p>
    <p>6.最专精于可操作、处理、分解或组合的真实事务。</p> `,
    ms: `<p>ESTP型的人不会焦虑——他们是快乐的。ESTP型的人活跃、随遇而安、天真率直。他们乐于享受现在的一切而不是为将来计划什么。ESTP型的人很现实，他们信任和依赖于自己对这个世界的感受。他们是好奇而热心的观察者。因为他们接受现存的一切，所以他们思维开阔，能够容忍自我和他人。ESTP型的人喜欢处理、分解与恢复原状的真实事物。</p>
    <p>ESTP型的人喜欢行动而不是漫谈，当问题出现时，他们乐于去处理。他们是优秀的解决问题的人，这是因为他们能够掌握必要的事实情况，然后找到符合逻辑的明智的解决途径，而无需浪费大量的努力或精力。他们会成为适宜外交谈判的人，他们乐于尝试非传统的方法而且常常能够说服别人给他们一个妥协的机会。他们能够理解晦涩的原则，在符合逻辑的基础上，而不是基于他们对事物的感受之上做出决定。因此，他们讲求实效，在情况必须时非常强硬。 </p>
    <p>在大多数的社交场合中，ESTP型的人很友善，富有魅力、轻松自如而受人欢迎。在任何有他们的场合中，他们总是爽直、多才多艺和有趣，总有没完没了的笑话和故事。他们善于通过缓和气氛以及使冲突的双方相互协调，从而化解紧张的局势。 </p>`,
    md: `<p>ESTP型的人偏好得过且过的生活方式，对意料之外的危机采取应急的态度，这会导致他们周围环境的混乱。由于缺少计划他们会错过许多机会。有时他们会一下子承担许多任务，然后便发现自己的负担过重，无法遵守诺言。ESTP型的人必须超越眼前的状况和对物质世界的兴趣，努力寻找准时完成工作的途径。</p>
    <p>当ESTP型的人力求诚实时，尤其是当他们从一种经历闯入另一种经历时，往往会忽视他人的情感，感觉变得很迟钝。有时他们的炫耀被视为很粗俗，而且会使他们尽力取悦之人产生敌对的态度。</p>
    </p>当ESTP型的人把他们的观察力更多地倾注于感受他们周围的人之时，他们会有更大的影响力。他们应该控制自己的莽撞、劲头和对享乐的偏好，使他人更容易接受，这样会使他们更有影响作用。</p>
    <p>ESTP型人的兴趣更多的在于迅速有效地解决问题，他们往往直接地投身于下一场危险，而对于目前计划中缺少兴奋的部分往往不能坚持完成。他们确实应该学会管理时间，运用长远规划的技能，从而有助于他们为责任做准备，并很好地完成。ESTP型的人通过放慢培养自己行为标准的速度，考虑自己行为的后果，会使自己更加行之有效。</p>`,
    qn: `<p>外向与内向是指我们心理能量的作用方向，判断与知觉是我们做事的两种方式。心理学称之为“态度”。感觉与直觉是我们获取信息的方式，思考和情感是做决定的方式，心理学称之为“功能”，功能是人格理论的精髓、核心。</p>
    <p>每一个人在都会用到四种功能，但对功能应用的喜好程度不一样。等级中第一位的称为“主导功能”，第二位的称为“辅助功能”。每个人都需要运用到这两个过程。</p>
    <p>有效地使用你的类型：(ESTP)</p>
    <p>ESTP型的人主导功能是感觉（关注细节），辅助功能是思维。他们倾向于：注意细节、重视实际、能记住琐碎细节，耐得住烦闷的工作、有耐性、细心有系统。合乎逻辑擅于分析、客观、公正、有逻辑系统的思考、具判判能力、坚定。</p>
    <p>ESTP型的人比较少地使用他们的第三、第四功能—情感和直觉。即使在需要这些功能的场合，他们仍然无法有效使用。</p>
    <p>作为一个ESTP型的人：如果你过多的使用感觉功能，你可能会失去整体的观念、想不出各种可能解决的途径、不信直觉、不求判断、无法应付太复杂的工作、不喜欢推测未来。如果你过多的使用思考功能，你可能忽略他人感受、误解别人的价值观、不在意和谐的人、不露感情、悯情较少、不能说服他人。</p>`,
    jjwt: `<p>每个人都会用自己的方式进行决策，决策通常来自于你的主导功能，并且完全忽视你的第四功能。你的功能运用如下：</p>
    <p>主导功能感觉——辅助功能思维——第三功能情感——第四功能直觉</p>
    <p>人格中的每一种功能对解决问题都具有关键和重要作用，忽视任何一个都将导致做出有缺陷的决定，理想化的问题解决功能流程如下：</p> 
    <p>一个好的决策通常来自于四个功能的合理运用。当你尝试去解决问题时，你可以利用上表指导这个过程，但在整个的执行过程是非常困难的，因为每个人都不愿意用他不喜欢的功能去解决问题。就算你有意识地努力运用所有这些功能，你也不可能会在“真实生活”中以这种顺序运用它们。关键是要利用它们提醒自己，完善决策，就算你不按这种方式行事。 </p>
    <p>工作中选择符合你的主导功能和辅助功能的岗位是非常重要的。第三功能要经过发展锻炼才能合适运用，第四功能可能永远不会在你的职业生涯中起到关键作用，因此你应当避免那种要求长期使用它的工作。</p>`,
    ys: `<p>对于不同的人格类型和不同的动力等极而言，没有“好”与“坏”之分，每一个人都是一个独一无二的个体，都有其特别的优势和劣势，但问题的关键在于如何认识这<p>些优势和劣势,完善自己。我们对于成功的建议是：“取已之长，补已之短”。学会了这一点将会影响到你的成败及你对工作的喜好。</p>
    <p class="b">你在工作中的优势： </p>
    <p>敏锐的观察力，对实际讯息的出色记忆力 </p>
    <p>明白该做什么的能力，和现实地对完成工作的必要条件 </p>
    <p>在发动和促进项目时的愉快 </p>
    <p>精力充沛；你喜欢在工作中充满活力</p> 
    <p>随机应变的能力 </p>
    <p>使工作有趣和兴奋的能力 </p>
    <p>参加团队的乐趣 </p>
    <p>实际、现实和观察力和丰富的常识 </p>
    <p>逐步上升的方式；你在工作中创造的生动有趣的气氛 </p>
    <p>适应力，愿意冒险和尝试新事物 </p>
    <p>愿意接受不同和“跟随潮流”的能力 </p>`,
    ls: `<p>下面列出了你工作中可能存在的缺点，这些缺点有的比较明显，有的并不明显或你没有意识到，目的是为了让你“注意”到它们，并考虑产生的原因。缺点有些是天生的，而有些是长时间形成的，因此你不可能在一两天内改变，而是去提醒、思考。注意：其实知道存在的问题就是改变提高中很重要的一步，你会发现你正在慢慢发生变化。</p>
    <p class="b">您工作中的劣势：</p> 
    <p>很难独自工作，尤其是长时间 </p>
    <p>不喜欢事先准备；在组织时间上你有困难 </p>
    <p>对别人的感觉迟钝、麻木的倾向、或者对人们的感觉过于疏忽 </p>
    <p>无法看到一时不存在的机会和选择 </p>
    <p>缺乏耐心和/或无法忍受行政细节和手续 </p>
    <p>很难作决定和/或优先考虑计划</p> 
    <p>易冲动的倾向和易受诱惑或迷惑 </p>
    <p>难以看到事情的长远影响 </p>
    <p>不喜欢过多的规矩和条条框框的官僚作分 </p>
    <p>抵抗制定长远目标，难以达到最后期限</p>`,
    gwtz: `<p>研究发现：职业满足会使你的工作主动性更强，积极性更高，更愿意去工作。以下不是简单的告诉你什么样的工作适合你，而是细化的帮你分析工作中的哪些特质对你重要，你还需要从中选出你认为最重要的，因为不同经历的人对特质的重要程度要求是不同的。每个岗位的工作内容都在随企业的发展而发展，不是一成不变的，有时候岗位的发展方向需要我们自己去争取。所以找到适合的工作不如找到适合自己发展的岗位更直接。这些特质可以帮助明确如何主动的发展或争取你岗位中的那些特质。</p>
    <p>下面的条目从各个侧面上认证了您怎样才能受到真正的职业满足，看完这些条目之后，我们建议您根据它们对您的重要程序进行排序，排序的时候，回想一下您过去的学习、工作经历以及当前学习环境和工作感受，并思考：“哪一些是令你感到特别满意，有哪些令你极其不高兴”。试着寻找贯穿这些经历的主题。 
    </p>
    <p class="b">你的岗位特质：作为ESTP型人，职业满足意味着你做的这些工作：</p>
    <p>1. 自然地与许多人接触和互相影响；每天提供不同和有趣的事。 </p>
    <p>2. 运用敏锐的观察力、吸收和记忆事实的能力。</p>
    <p>3. 运用找寻解决问题方法的能力，运用第一手经验，然后批判地分析这些解决方法，找出最佳方案。</p>
    <p>4. 充满活力、冒险和趣味，事情迅速地发生，被允许冒险和留意新的机会。</p>
    <p>5. 对计划外的情况做出反应，自由地处理，有技巧地磋商满意的解决方案。</p>
    <p>6. 在没有过多的规则和限制的环境中与其他现实而有趣的人一起工作，在完成任务后可以享受自由时光。</p>
    <p>7. 以我自己习惯和认为有必要的方式安排自己的工作，而不是依照别人的标准。</p>
    <p>8. 实际且有逻辑性，我可以运用自己的推理能力找到系统内逻辑上的矛盾和缺陷，并加以改正。</p>
    <p>9. 让我自己应付危机，以坚持的原则和适宜的态度工作。</p> 
    <p>10.涉及真正的人和事，而不是理论和方法，我的努力可以直接产生有形的产品或服务。</p>`,
    zy: `<p>也许你会觉得下面的工作种类繁杂，但仔细分析你就会发现，这些工作各自的特色正是你对工作的要求，我们会在下面列出各类工作的特质。当然，这不可能是一个完美的适合你的工作的综合，但是，却能够向你展示你以前不曾考虑过的工作的可能性。在列出这些工作的同时，我们也要敬告您：每种职业中都有各种性格类型的成功人士；即使是相同的工作，不同公司的要求也不尽相同，所以除了工作名称外，你还要对贵公司要求的工作内容和公司文化作详细的了解。我们希望在经济快速发展、新型工作不断涌现将来，这份报告上列举的工作种类不是限制了你的选择，而是告诉你，面对新工作的选择或机会时，应该从哪些因素来分析这些工作对你的适合程度。</p>
    <p class="b">适合ESTP型人的一般职业 </p>
    <p class="flex"><span>销售／服务／“活动”</span><span>警察</span><span>消防队员</span><span>护理人员</span><span>侦探</span><span>领航员</span><span>调查研究者</span><span>管教罪犯的人员</span><span>房地产经纪人</span><span>急诊医士</span><span>运动生理学家／运动医学家</span><span>呼吸治疗专家</span><span>空中服务员</span><span>体育用品销售</span><span>调查保险诈骗人员</span><span>私人侦探</span></p>
    <p>许多ESTP型的人发现国内服务部门的职业令人感到满意， 因为这些职业具有高度的积极活动、多样性以及与来自于不同背景的大量人员接触的机会。这些职业中的大多数要求对迅速变化的形势做出快捷的思考和反应，并在压力下能够保持冷静。ESTP型的人天生具备好奇心和观察力，他们经常能成为出色的侦探和调查研究人员。</p>
    <p class="flex"><span>金融</span><span>个人财务计划者</span><span>审计员</span><span>股票经纪人</span><span>银行业者</span><span>投资者</span><span>保险推销</span><span>预算分折员</span><span>保险代理人／经纪人(推销商)</span></p>
    <p>ESTP型的人常常对金融界感兴趣，尤其是当它涉及到快速变化的局势，包含着一定冒险之时。对于兴奋事物以及冒险的热爱意味着他们在股票市场中很善于下赌注或“玩”得很好。他们是注重实际和讲求实效的人，喜欢需要解决问题的职业，即使这意味着要使用非常规的途径。金融方面的大多数职业都要求与公众大量的接触，而ESTP型人友好和善、易于相处的性格有助于他们认识别人和获得新客户。 </p>
    <p class="flex"><span>娱乐业／体育运动</span><span>播送体育节目</span><span>新闻报道员</span><span>承办人</span><span>旅游代理人</span><span>舞蹈演员</span><span>酒吧间侍员</span><span>拍卖商</span><span>职业运动员／教练</span><span>体能指导员／训练者</span></p>
    <p>这些“娱乐性”的职业提供了大量紧张活动的机会，对于 ESTP型的人来说，这是他们职业满足感中的基本要素。ESTP型的人为目前而生活，喜欢令他们活跃和有生气的工作。他们是天生的创导者，然而也热衷于完成冒险和令人激动的事情。许多 ESTP型的人是体育爱好者，他们擅长以运动为中心内容，包括与别人竞争和训练别人的工作。他们喜欢尽可能多得在别人周围工作，而且他们也会成为具有魅力和说服力的酒吧间掌柜和拍卖商。</p>
    <p class="flex"><span>技术的／商贸</span><span>木匠</span><span>工匠／手艺人</span><span>农民</span><span>总承包商</span><span> 建筑工人</span><span>厨师长／厨师</span><span>电气工程师</span><span>电子专家</span><span>集成网络专家(电信学)</span><span>工业／机械工程师</span><span>勘测员</span><span>脑电图专家／技术员</span><span>放射学专业人员</span><span>飞机修理工</span><span>海洋生物学家</span><span>技术培训者——课堂环景</span><span>后勤和供给经营人——制造业 </span><span>土木工程师</span></p>
    <p>对于ESTP型的人来说，商贸的吸引力在于能与实际的事物打交道，以及以有效、经济、熟练的方式利用工具。通常他们有很好的对机械方面技巧的理解力，并能很好地运用自己的双手。只要周围有别人，ESTP型的人就喜欢独自地工作。他们喜欢这些职业的具体和活跃的特性，其中包括有时在紧张的时间框架中工作的、压力(如，农业和烹调)。 </p>
    <p class="flex"><span>商业</span><span>房地产经纪人</span><span>中间商</span><span>房地产投资开发者</span><span>批发商</span><span>零售商</span><span>汽车销售商</span><span>业务顾问(业务活动)</span><span>特许经营者</span></p>
    <p>通常，许多ESTP型的人认为商业世界的限制太多，步伐过慢，很难使人满意。然而，这些职业在程序上更具灵活可变性、个人的自由和多样性，这些都吸引了ESTP型人的兴趣。ESTP型的人是出色的中间商，他们喜欢在开始—…项新的商业计划或成为投资者中发现的冒险性。因为他们很善于从他人身上体察微妙的暗示，所以他们能成为出色的销售商。他们喜欢淡判的过程和成为公平的仲裁人，也热衷于保险业或汽车销售中激烈竞争的特点．而且在具有为销售目标设有奖励和奖金的体制下，会做得很好。</p>`,
    jy: `<p>现在你对自己的人格类型和动力已经有了一个比较清楚的了解，但这还不够。“如何通过这些信息使你在这份工作上取得更大的成功”，这是关键所在。</p>
    <p>运用你的能力非常容易，你成功的秘诀在于：</p>
    <p>学会三四而行； </p>
    <p>考虑别人的感觉； </p>
    <p>善始善终。 </p>
    <p>个人发展建议是我们咨询师多年测评职业咨询和职业生涯规划的心得体会和经验总结，我们意识到以下的建议中有很多是难以完全照办的，但只要你花时间认真思考，一定会对你有极大的帮助和改变：</p>
    <p class="b">发展建议：</p>
    <p>需要抑制其独断而忽视他人感情的方面；</p>
    <p>需要在迅速决定之前，事先计划，考虑细节，三思而行； 
    <p>需要发展持之以恒； </p>
    <p>需要注意物质享受以外的东西。</p>
    `,
  },
  ESFP: {
    tag: '<h1>ESFP（外倾、感觉、情感和知觉）</h1><h2>不要担心，高兴起来！</h2>',
    subscribe: `
    <p>1.外向、和善、接受性、乐于分享喜乐予他人。</p>
    <p>2.喜欢与他人一起行动且促成事件发生，在学习时亦然。</p>
    <p>3.知晓事件未来的发展并会热列参与。</p>
    <p>4.最擅长于人际相处能力及具备完备常识，很有弹性能立即　适应他人与环境。</p>
    <p>5.对生命、人、物质享受的热爱者。</p> `,
    ms: `<p>ESFP型的人乐意与人相处，有一种真正的生活热情。他们顽皮活泼，通过真诚和玩笑使别人感到事情更加有趣。ESFP型的人脾气随和、适应性强，热情友好和慷慨大方。他们擅长交际，常常是别人的“注意中心”。他们热情而乐于合作地去参加各种活动和节目，而且通常立刻能对付几种活动。</p>
    <p>ESFP型的人是现实的观察者，他们按照事物的本身去对待并接受它们。他们往往信任自己能够听到、闻到、触摸和看到的事物，而不是依赖于理论上的解释。因为他们喜欢具体的事实，对于细节有很好的记忆力，所以他们能从亲身的经历中学到最好的东西。共同的感觉给予他们与人和物相处的实际能力。他们喜欢收集信息，从中观察可能自然出现的解决方法。</p>
    <p>ESFP型的人对于自我和他人都能容忍和接受，往往不会试图把自己的愿望强加于他人。ESFP型的人通融和有同情心，通常许多人都真心地喜欢他们，，他们能够让别人采纳他们的建议，所以他们很擅于帮助冲突的各方重归于好。他们寻求他人的陪伴，是很好的交淡者。他们乐于帮助旁人，偏好以真实有形的方式给予协助。ESFP型的人天真率直，很有魅力和说服力。他们喜欢意料不到的事情，喜欢寻找给他人带来愉快和意外惊喜的方法。</p> `,
    md: `<p>ESFP型的人把经历和享受人生置于一个优先的位置，所以有时他们使自己其他的责任受到损害。而且因为他们很容易受诱惑，很难自我约束，所以他们经常性的社交会干扰他们并使他们陷于麻烦之中。ESFP型的人往往容易分散对完成已经开始的任务的注意力，这使他们变得很懒散。优先考虑工作而不是活动，在工作与娱乐之间寻找——种平衡，这样他们会获得更具广阔前景和长远规划的生活观念，利用易被接受和具有成功性的组织方式和时间管理有利于他们克服天生的禀性。</p>
    <p>积极活跃的生活使得他们十分忙碌，无法事先计划。这使他们对于如果能够注意到其来临的征兆则很容易对付的人生变化毫无准备。ESFP型的人必须尝试预见今后可能发生的事情，制订一个万一情况不愉快时可供选择的计划。</p>
    <p>ESFP型的人往往做决定时没有考虑他们的行动是否符合逻辑。他们信赖自己的个人情感，习惯于排除更加客观的信息。ESFP型的人对朋友的评价很高，往往只看到他们积极的一—面。ESFP型的人必须后退一步考虑他们行为的起因和结果，工作中应变得更加意志坚强。拒绝并不是如决定是否他们去做那样困难。</p>`,
    qn: `<p>外向与内向是指我们心理能量的作用方向，判断与知觉是我们做事的两种方式。心理学称之为“态度”。感觉与直觉是我们获取信息的方式，思考和情感是做决定的方式，心理学称之为“功能”，功能是人格理论的精髓、核心。</p>
    <p>每一个人在都会用到四种功能，但对功能应用的喜好程度不一样。等级中第一位的称为“主导功能”，第二位的称为“辅助功能”。每个人都需要运用到这两个过程。有效地使用你的类型</p>`,
    jjwt: `<p>每个人都会用自己的方式进行决策，决策通常来自于你的主导功能，并且完全忽视你的第四功能。你的功能运用如下：</p>
    <p>主导功能感觉——辅助功能思维——第三功能情感——第四功能直觉</p>
    <p>人格中的每一种功能对解决问题都具有关键和重要作用，忽视任何一个都将导致做出有缺陷的决定，理想化的问题解决功能流程如下：</p>
    <p>一个好的决策通常来自于四个功能的合理运用。当你尝试去解决问题时，你可以利用上表指导这个过程，但在整个的执行过程是非常困难的，因为每个人都不愿意用他不喜欢的功能去解决问题。就算你有意识地努力运用所有这些功能，你也不可能会在“真实生活”中以这种顺序运用它们。关键是要利用它们提醒自己，完善决策，就算你不按这种方式行事。</p>
    <p>工作中选择符合你的主导功能和辅助功能的岗位是非常重要的。第三功能要经过发展锻炼才能合适运用，第四功能可能永远不会在你的职业生涯中起到关键作用，因此你应当避免那种要求长期使用它的工作。</p>`,
    ys: `<p>对于不同的人格类型和不同的动力等极而言，没有“好”与“坏”之分，每一个人都是一个独一无二的个体，都有其特别的优势和劣势，但问题的关键在于如何认识这些优势和劣势,完善自己。我们对于成功的建议是：“取已之长，补已之短”。学会了这一点将会影响到你的成败及你对工作的喜好。</p>
    <p class="b">你在工作上的优势可能包括：</p>
    <p>*工作时精力充沛和充满乐趣</p>
    <p>*对迅速发生的改变和转变适应良好的能力</p>
    <p>*对别人的需要敏感和渴望以真正的方法帮助他们</p>
    <p>*喜欢自然；你是个有协作精神的团队成员</p>
    <p>*使工作有去、让人兴奋的能力</p>
    <p>*实际且具有丰富的常识</p>
    <p>*忠实于你关心的人和组织</p>
    <p>*上进心；在工作中，你创造了一个生机勃勃、充满乐趣的气氛</p>
    <p>*柔韧性和愿意冒险，尝试新事物</p>
    <p>*渴望合作，以真实准确的方式帮助他人</p>
    <p>*清楚地评估目前的资源和情况，并且立刻看到应该做什么的能力</p>`,
    ls: `<p>下面列出了你工作中可能存在的缺点，这些缺点有的比较明显，有的并不明显或你没有意识到，目的是为了让你“注意”到它们，并考虑产生的原因。缺点有些是天生的，而有些是长时间形成的，因此你不可能在一两天内改变，而是去提醒、思考。注意：其实知道存在的问题就是改变提高中很重要的一步，你会发现你正在慢慢发生变化。</p>
    <p class="b">您工作中的劣势：</p>
    <p >你在工作上的缺陷可能包括：</p>
    <p>*难以独自工作，尤其是持续的一段时间</p>
    <p>*以表面价值接受事物和错失进一步暗示的倾向</p>
    <p>*不喜欢提前准备；在组织时间上有问题</p>
    <p>*难以看到目前不存在的机会和选择</p>
    <p>*将失败当作针对个人的批评和负面回应的倾向</p>
    <p>*难以做出决定</p>
    <p>*冲动和容易被诱惑或迷惑的倾向</p>
    <p>*不喜欢过多的条条框框和官僚作风</p>
    <p>*如果涉及到个人感情，就难以做出有逻辑的决定</p>
    <p>*抵制确立长期目标和难以达到最后期限的倾向</p>
    <p>*难以律己或律人</p>`,
    gwtz: `<p>研究发现：职业满足会使你的工作主动性更强，积极性更高，更愿意去工作。以下不是简单的告诉你什么样的工作适合你，而是细化的帮你分析工作中的哪些特质对你重要，你还需要从中选出你认为最重要的，因为不同经历的人对特质的重要程度要求是不同的。每个岗位的工作内容都在随企业的发展而发展，不是一成不变的，有时候岗位的发展方向需要我们自己去争取。所以找到适合的工作不如找到适合自己发展的岗位更直接。这些特质可以帮助明确如何主动的发展或争取你岗位中的那些特质。</p>
    <p>下面的条目从各个侧面上认证了您怎样才能受到真正的职业满足，看完这些条目之后，我们建议您根据它们对您的重要程序进行排序，排序的时候，回想一下您过去的学习、工作经历以及当前学习环境和工作感受，并思考：“哪一些是令你感到特别满意，有哪些令你极其不高兴”。试着寻找贯穿这些经历的主题。你的岗位特质：作为ESFP型人，职业满足意味着你做的这些工作：</p>
    <p>1.让我有实际经验中学习，并由我在权限内收集到的所有信息和使用常识寻求问题的解决之法。</p>
    <p>2.让我妻子参加实际任务，与客户直接工作，在户外比远离行动好。</p>
    <p>3.让我和许多人一起在有活力、友善、充满变化乐趣和自然的环境中工作。</p>
    <p>4.要求技巧地处理人和矛盾，缓解紧张气氛，帮助团队更协作的工作的能力，以及激励别人的能力。</p>
    <p>5.让我快速地操纵多样的项目和活动，尤其是那些用得上我的审美观和设计感的。</p>
    <p>6.让我整个工作日与其他分享我的热情、精力和实际观点的易相处且友善的人一起互相影响。</p>
    <p>7.让我开展有即时效应得机会，满足我周围人的要求。</p>
    <p>8.在一个友善、放松，没有隐藏的政治议程的环境中工作。</p>
    <p>9.奖励我的努力工作和好意图，让我觉得自己的贡献让人欣赏。</p>
    <p>10.让我有乐趣享受每一天的景气，官僚作氛、规矩或限制达到最低程度。</p>`,
    zy: `<p>也许你会觉得下面的工作种类繁杂，但仔细分析你就会发现，这些工作各自的特色正是你对工作的要求，我们会在下面列出各类工作的特质。当然，这不可能是一个完美的适合你的工作的综合，但是，却能够向你展示你以前不曾考虑过的工作的可能性。在列出这些工作的同时，我们也要敬告您：每种职业中都有各种性格类型的成功人士；即使是相同的工作，不同公司的要求也不尽相同，所以除了工作名称外，你还要对贵公司要求的工作内容和公司文化作详细的了解。我们希望在经济快速发展、新型工作不断涌现将来，这份报告上列举的工作种类不是限制了你的选择，而是告诉你，面对新工作的选择或机会时，应该从哪些因素来分析这些工作对你的适合程度。适合ESFP型人的一般职业</p>
    <p class="flex"><span>教育及社会服务部门</span><span>教师：早期儿童教育及初级教育</span><span>儿童护理员。</span><span>家庭保健人员</span><span>体育教练</span><span>特殊教育老师</span><span>酗酒，吸毒劝戒人员</span><span>儿童福利顾问</span><span>海洋生物学家</span></p>
    <p>ESFP型的人在教育行业常获得职业满足。尤其当他们同小孩一块时，更是这样。初级及学龄前教育有时不太正规和固定化，这就为主动实践创造了机会。ESFP型的人喜欢传授基本技能，乐于教孩子们如何相处，这对早期教育非常重要。他们喜欢初级学校的活动，能力水平，丰富的学科。ESFP型的人生气勃勃，本身具备熟练的活动技能，因此，他们喜欢体育和体育教练。体育活动、集体活动，积极的户外活动对于ESFP型的人都喜欢。他们富于热情、勇气，是确实的教练和教师。</p>
    <p class="flex"><span>健康护理</span><span>应急家庭护士</span><span>社会福利工作人员</span><span>训犬员</span><span>医务助理</span><span>牙科专家或牙科助理</span><span>领照实习护士</span><span>运动专家</span><span>最初保健护理医生</span><span>家庭保健服务人员</span><span>按摩专家</span><span>饮食专家或营养学家</span><span>紧急医务工作人员</span><span>运动生理学家</span><span>药剂师</span><span>放射专家</span><span>呼吸系统专家</span><span>营养专家</span><span>职业疗法医师</span></p>
    <p>保健护理和社会福利工作为ESFP型的人提供了帮助别人的机会。那些职业要求学会然后重复使用技能。大多数ESFP型的人喜欢同别人直接接触和快节奏的生活。紧急家用护土要能对经历危险而受到惊吓的人作出快速反应而且必须有让对象平静的本领。许多ESFP型的人喜欢动物，也乐于从事对动物的护理和训练的工作。社会工作领域能让ESFP型的人同许多不同的人接触和共事，有助于他们发现和确定可利用的资源。他们轻易地建立关系，从帮助别人过得更舒心的过程中得到满足。</p>
    <p class="flex"><span>娱乐业</span><span>旅行代办人／旅游组织人员</span><span>摄像师</span><span>电影制片人</span><span>音乐家</span><span>承办人员</span><span>特殊事件统筹人员</span><span>表演艺术人员、舞蹈员、喜剧演员</span></p>
    <p>ESFP型的人喜欢同朋友正式和随意地娱乐。他们通常对美有很强的感受，对新鲜和优美的事物有敏锐的洞察力。一些ESFP型的人喜欢表现自己的技艺，另一些则喜欢娱乐时同别人在一起，ESFP型的人喜欢旅游，同时，由于他们善于倾听游客的不同要求然后找出最佳方案，他们也是好的旅行代办人。他们能适应立刻对付不同事件或同一事情的不同部分，经常在统筹特殊事件时获得满足感。</p>
    <p class="flex"><span>商业／推销业</span><span>零售商或零售策划人员</span><span>公共关系专家</span><span>资金筹措者</span><span>劳资关系调查人员</span><span>接待员</span><span>商业计划人员</span><span>多样化管理者：人力资源</span><span>小组协同培训</span><span>旅行推销人员</span><span>保险代办人经纪人(健康或人寿)</span><span>房地产经纪人</span><span>体育设备推销／买卖</span><span>零售推销</span><span>家用保键用品推销</span></p>
    <p>ESFP型的人一般不喜欢商业事务，尤其公司事务，但如果那些工作能提供同别人交往的机会或不受严格的条框束缚，也可引起ESFP人的兴趣。他们喜欢房地产推销是因为由此可在办公室外度过大多数时间，而且可以直接同各种各样的人交往，展示各种各样的居室。他们常喜欢搞公关，筹集资金，调停，从中可以运用他们天生的劝解能力。那些工作能让他们使用他们的人际交往技巧及运用收集信息的能力。许多ESFP型的人被零售业吸引，尤其是他们能发挥对时尚的洞察力的时候。</p>
    <p class="flex"><span>服务业</span><span>飞机服务员</span><span>秘书／接待员</span><span>侍者</span><span>旅店老板</span><span>花卉设计</span><span>警察／劳改服务人员</span></p>
    <p>服务业吸引ESFP型的人是因为要求使用熟练的人际交往技巧和能力。ESFP型的人热情友好，有使别人放松的能力。他们喜欢饭店和俱乐部的气氛，以及爱交往和慷慨的老板，他们当场适应的特点让他们走到哪都很快乐，据说，聚会常围着ESFP型的人转。</p>`,
    jy: `<p>现在你对自己的人格类型和动力已经有了一个比较清楚的了解，但这还不够。“如何通过这些信息使你在这份工作上取得更大的成功”，这是关键所在。运用你的能力其实很容易。</p>
    <p class="b">ESFP型人成功的秘诀就是：</p>
    <p>*学会考虑将来的暗示；</p>
    <p>*不要太个人化地看待事情；</p>
    <p>*信守承诺。</p>
    个人发展建议是我们咨询师多年测评职业咨询和职业生涯规划的心得体会和经验总结，我们意识到以下的建议中有很多是难以完全照办的，但只要你花时间认真思考，一定会对你有极大的帮助和改变：</p>
    <p class="b">发展建议：</p>
    <p>*在决定时需要照顾逻辑关系；</p>
    <p>*在管理项目之前需要事先计划；</p>
    <p>*需要平衡工作努力和社交活动；</p>
    <p>*需要在时间管理上下功夫。</p>
    `,
  },
  ENFP: {
    tag: '<h1>ENFP(外倾、直觉、情感和知觉)</h1><h2>天底下没有不可能的事</h2>',
    subscribe: `
    <p>1.充满热忱、活力充沛、聪明的、富想象力的，视生命充满机会但期能得自他人肯定与支持。</p>
    <p>2.几乎能达成所有有兴趣的事。</p>
    <p>3.对难题很快就有对策并能对有困难的人施予援手。</p>
    <p>4.依赖能改善的能力而无须预作规划准备。</p>
    <p>5.为达目的常能找出强制自己为之的理由。</p>
    <p>6.即兴执行者。 </p>`,
    ms: `<p>ENFP型的人充满热情和新思想。他们乐观、自然、富有创造性和自信，具有独创性的思想和对可能性的强烈感受。对于ENFP型的人来说，生活是激动人生的戏剧。因为ENFP型的人对可能性很感兴趣，所以他们了解所有事物中的深远意义，喜欢许多可供选择的事物的存在。他们具有洞察力，是热情的观察者，注意常规以外的任何事物。</p>
    <p>ENFP型的人好奇，他们更喜欢理解而不是判断。</p>
    <p>ENFP型的人具有想象力、适应性和可变性，他们视灵感高于—切，常常是足智多谋的发明人。有时他们不墨守成规，善于发觉做事情的新方法。ENFP型的人为思想或行为开辟新道路，并保持它们的开放。在完成新颖的想法的过程中，ENFP型的人依赖冲动的能量。他们有大量的主动性，认为问题令人兴奋。他们也从所处的周围其他人中得到能量的输入，能够把自己的才能与别人的力量成功地结合在一起。</p>
    <p>ENFP型的人具有魅力、充满生机。他们待人热情、彬彬有礼、富有同情心，愿意帮助别人解决问题。他们具有出色的洞察力和观察力，常常关心他人的发展。ENFP型的人避免冲突，喜欢和睦。他们把更多的精力倾注于维持个人关系而不是客观事物，喜欢保持一种广泛的人际关系。</p>`,
    md: `<p>由于他们觉得产生想法很容易，所以ENFP型的人每次把精力集中寸：仅仅一件事上很困难，在做决定时很麻烦。他们了解许多可能性，以至他们很难挑选最好的活动或兴趣去追求。有时他们会做出拙劣的决定，立刻卷入过多的事物之中。仔细地选择把精力集中在哪儿，有助于ENFP型的人避免浪费时间和相当的才能。</p>
    <p>对于一个ENFP型的人来说，一个计划中有意思的部分是最初问题的解决和引出一些新内容。他们乐于在一个问题最重要和富有挑战性的部分施展自己的灵感。这一阶段过后，他们常常会失去兴趣，缺乏完成已经开始的工作所必要的自我约束。他们很可能会开始许多计划，但完成的却寥寥无几，当ENFP型的人坚持完成单调却又必要的计划时，直到完成前，他们必须付出更多的努力。经常在纸上写出重要的事情或下面的步骤有助于防止他们偏离主题。</p>
    <p>通常ENFP型的人不是特别安排有序的。他们会得益于学习和运用时间的管理个人组织能力。与更现实和实际的人配合工作，ENFP型的人能做得很好，作为ENFP型的人不喜欢单独工作，尤其是要持续一段时间，所以与别人配合对他们很适合、即使是处于计划中缺乏兴趣的阶段，他们觉得与另外一个人一起工作也比单独工作合意得多。</p>
    <p>ENFP型的人对于细节不是很感兴趣。由于他们对于运用自己的灵感和产生有独创性的事物电感兴奋，所以他们厌烦为了完成一项特别的活动去搜集自己所需要的材料。有时他们仅仅是当场即兴创作，而事先没有计划和准备。因为他们觉得收集材料单调枯燥，所以他们冒着永远超不出“闪亮的思维”阶段，或者一旦开始，永不结束的危险。一直不变的是，他们宁愿推迟处理麻烦的细节，而转移创新的或不寻常的其他事情上。当他们有意识地参加周围的社会交往，收集—些使他们的创新可以实际运用的、更加现实的想法时，ENFP型的人会更加卓有成效。</p>`,
    qn: `<p>外向与内向是指我们心理能量的作用方向，判断与知觉是我们做事的两种方式。心理学称之为“态度”。感觉与直觉是我们获取信息的方式，思考和情感是做决定的方式，心理学称之为“功能”，功能是人格理论的精髓、核心。</p>
    <p>每一个人在都会用到四种功能，但对功能应用的喜好程度不一样。等级中第一位的称为“主导功能”，第二位的称为“辅助功能”。每个人都需要运用到这两个过程。</p>
    <p>有效地使用你的类型：(ENFP)</p>
    <p>ENFP型的人主导功能是直觉（喜欢推论），辅助功能是情感。他们倾向于：对事情能面面观之、以整体概念看事、富想象力、尝试新鲜构想、喜欢复杂的工作、喜欢解决新奇的问题。体谅他人感受、了解他人的需要、喜欢和谐的人际关系、易表露情感、喜去说服他人。</p>
    <p>ENFP型的人比较少地使用他们的第三、第四功能--感觉和情感。即使在需要这些功能的场合，他们仍然无法有效使用。</p>
    <p>作为一个ENFP型的人：如果你过多的使用直觉功能，你可能会忽视细节和当前的实际，或在信息并不足够得情况下，过快地进行决策。如果你过多地关注情感，你可能会表现得不合乎逻辑、不够客观、没有组织系统的思考、不具批判精神全盘接受、感情用事。</p>`,
    jjwt: `<p>每个人都会用自己的方式进行决策，决策通常来自于你的主导功能，并且完全忽视你的第四功能。你的功能运用如下：</p>
    <p>主导功能直觉——辅助功能情感——第三功能思考——第四功能感觉</p>
    <p>人格中的每一种功能对解决问题都具有关键和重要作用，忽视任何一个都将导致做出有缺陷的决定，理想化的问题解决功能流程如下：</p>
    <p>一个好的决策通常来自于四个功能的合理运用。当你尝试去解决问题时，你可以利用上表指导这个过程，但在整个的执行过程是非常困难的，因为每个人都不愿意用他不喜欢的功能去解决问题。就算你有意识地努力运用所有这些功能，你也不可能会在“真实生活”中以这种顺序运用它们。关键是要利用它们提醒自己，完善决策，就算你不按这种方式行事。</p>
    <p>工作中选择符合你的主导功能和辅助功能的岗位是非常重要的。第三功能要经过发展锻炼才能合适运用，第四功能可能永远不会在你的职业生涯中起到关键作用，因此你应当避免那种要求长期使用它的工作。</p>`,
    ys: `<p>对于不同的人格类型和不同的动力等极而言，没有“好”与“坏”之分，每一个人都是一个独一无二的个体，都有其特别的优势和劣势，但问题的关键在于如何认识这些优势和劣势，完善自己。我们对于成功的建议是：“取已之长，补已之短”。学会了这一点将会影响到你的成败及你对工作的喜好。</p>
    <p class="b">你在工作中的优势：</p>
    <p>*你希望打破常规思考，考虑事情发展可能出现的新情况</p>
    <p>*敢于冒险、敢于尝试新事物，能克服障碍</p>
    <p>*兴趣广泛，对自己感兴趣的东西接受能力强</p>
    <p>*对收集自己所需信息有一种天生的求知欲和技能</p>
    <p>*能通观全局，能看出行为和思想之间的潜在含义</p>
    <p>*交际能力强，能激发别人的热情</p>
    <p>*适应能力强，能迅速改变自己的行事速度及目标</p>
    <p>*能洞察别人，能理解他们的需要和动机</p>`,
    ls: `<p>下面列出了你工作中可能存在的缺点，这些缺点有的比较明显，有的并不明显或你没有意识到，目的是为了让你“注意”到它们，并考虑产生的原因。缺点有些是天生的，而有些是长时间形成的，因此你不可能在一两天内改变，而是去提醒、思考。注意：其实知道存在的问题就是改变提高中很重要的一步，你会发现你正在慢慢发生变化。</p>
    <p>您工作中的劣势：</p>
    <p>*不善于把握事物的轻重，难于决定优先处理哪些事</p>
    <p>*对缺乏独创性的人没有耐心</p>
    <p>*不愿以传统或常规的方式行事</p>
    <p>*易于烦躁或不耐烦，尤其是当工作中创造性过程结束后</p>
    <p>*讨厌做重复性任务</p>
    <p>*不能容忍与过于严谨的机构或个人工作</p>
    <p>*倾向于关注可能发生的事情，而非实际的或极可能发生的事情</p>
    <p>* 你有变得毫无组织的倾向</p>`,
    gwtz: `<p>研究发现：职业满足会使你的工作主动性更强，积极性更高，更愿意去工作。以下不是简单的告诉你什么样的工作适合你，而是细化的帮你分析工作中的哪些特质对你重要，你还需要从中选出你认为最重要的，因为不同经历的人对特质的重要程度要求是不同的。每个岗位的工作内容都在随企业的发展而发展，不是一成不变的，有时候岗位的发展方向需要我们自己去争取。所以找到适合的工作不如找到适合自己发展的岗位更直接。这些特质可以帮助明确如何主动的发展或争取岗位中的那些特质。</p>
    <p>下面的条目从各个侧面上认证了您怎样才能受到真正的职业满足，看完这些条目之后，我们建议您根据它们对您的重要程序进行排序，排序的时候，回想一下您过去的学习、工作经历以及当前学习环境和工作感受，并思考：“哪一些是令你感到特别满意，有哪些令你极其不高兴”。试着寻找贯穿这些经历的主题。</p>
    <p>你的岗位特质：</p>
    <p>1、让我领导、控制、组织以及完善一个机构的运行体制，以便它有效地运行并达到计划目的。</p>
    <p>2、允许我从事长远策略计划，创造性地解决问题，以及对各种不同问题涉及处富有创意且符合逻辑的解决方法。</p>
    <p>3、我的工作是在一个组织性强的环境中完成，而且在那里我和我的同事要在一些明确的指导方针下工作。</p>
    <p>4、我的工作观念鼓励并持机我的求知欲，同时允许我从事复杂且通常比较棘手的问题。</p>
    <p>5、给我机会遇到并与各种不同的人交往，他们必须是由能力、有趣且实力强大的人。</p>
    <p>6、给我在机构内不断前进、提高并展示自己才能的机会。</p>
    <p>7、我的工作有刺激性、挑战性及竞争性，在工作中我市公众注意的焦点，同时我的成就要看得见、被认同，且能够得到一定的回报。</p>
    <p>8、允许我与别人的有才智、有创造性、有抱负以及有理想的人工作，而且他们的能力是我所佩服的。</p>
    <p>9、让我设立一定目标，并为实现它们而奋斗，允许我运用自己的组织技能来使我自己及别人集中精力于更大的目标，而同时我自己所有的目标都能及时、有效地实现。
<p>10、能让我以我的政策来管理并监督别人，运用逻辑、客观的标准及利用每个人的才能，但不必处理人际冲突。研究发现：职业满足会使你的工作主动性更强，积极性更高，更愿意去工作。以下不是简单的告诉你什么样的工作适合你，而是细化的帮你分析工作中的哪些特质对你重要，你还需要从中选出你认为最重要的，因为不同经历的人对特质的重要程度要求是不同的。</p>
<p>每个岗位的工作内容都在随企业的发展而发展，不是一成不变的，有时候岗位的发展方向需要我们自己去争取。所以找到适合的工作不如找到适合自己发展的岗位更直接。这些特质可以帮助明确如何主动的发展或争取你岗位中的那些特质。</p>
<p>下面的条目从各个侧面上认证了您怎样才能受到真正的职业满足，看完这些条目之后，我们建议您根据它们对您的重要程序进行排序，排序的时候，回想一下您过去的学习、工作经历以及当前学习环境和工作感受，并思考：“哪一些是令你感到特别满意，有哪些令你极其不高兴”。</p>
<p>试着寻找贯穿这些经历的主题。</p>
<p class="b">你的岗位特质：</p>
<p>1、能让我有机会帮助各种各样的人解决互不相同的问题，而同时我自己也能从中受到灵感的启发。</p>
<p>2、允许创造新奇的观点、产品、服务以及解决问题的方法来帮助别人，并能看到我的计划变成现实。</p>
<p>3、这份工作要集趣味性、挑战性、多样性于一体。</p>
<p>4、工作中尽可能少地要求我处理培训后续工作、日常琐事，也不要要求我维护一个系统或工程。</p>
<p>5、允许我以自己的进度或日程安排我的工作，工作中没有太多规章制度，而应该有自主行动的自由。</p>
<p>6、能让我有机会遇到一些新的面孔，学习一些新的技能以不断满足自己的求知欲。</p>
<p>7、我的工作必须与我的个人信仰和价值观相一致，并能让我创造于别人有利的机会。</p>
<p>8、我工作的环境必须友好、轻松，人们幽默、亲切，且彼此之间没有人际冲突。</p>
<p>9、能让我自由发挥我的灵感，并参加一些刺激、有趣的冒险。</p>
<p>10、  在工作中，我的热情、创造力、以及想象力能得到赏识和回报。</p>`,
    zy: `<p>也许你会觉得下面的工作种类繁杂，但仔细分析你就会发现，这些工作各自的特色正是你对工作的要求，我们会在下面列出各类工作的特质。当然，这不可能是一个完美的适合你的工作的综合，但是，却能够向你展示你以前不曾考虑过的工作的可能性。在列出这些工作的同时，我们也要敬告您：每种职业中都有各种性格类型的成功人士；即使是相同的工作，不同公司的要求也不尽相同，所以除了工作名称外，你还要对贵公司要求的工作内容和公司文化作详细的了解。我们希望在经济快速发展、新型工作不断涌现将来，这份报告上列举的工作种类不是限制了你的选择，而是告诉你，面对新工作的选择或机会时，应该从哪些因素来分析这些工作对你的适合程度。适合ENFP型人的一般职业</p>
    <p class="flex"><span>创造性职业</span><span>记者</span><span>编剧或剧作家</span><span>专栏作家</span><span>性格演员</span><span>音乐家或作曲家</span><span>新闻广播员</span><span>室内装潢人</span><span>卡通制作人</span><span>艺术家</span><span>报道人或编辑</span><span>杂志报道人或编辑</span><span>信息图片设计师</span></p>
    <p>这些创造性职业明显的吸引力在于其中有机会不断发展新颖而有创见的方法。ENFP型人喜爱创造过程，尤其是它提供了合作及被与他人合作所激发自身灵感的机会。工作环境越没有压力越灵活，ENFP型人就越喜欢。他们可以独立地把工作做好，但需要经常自发地与他人接触，以保持他们不断地产生创造力，并使他们愉快地工作。</p>
    <p class="flex"><span>营销／计划</span><span>公关专家</span><span>营销顾问</span><span>广告业务经理</span><span>广告撰稿人或公共写作人</span><span>广告创意指导</span><span>战略策划人</span><span>报刊宣传员</span><span>调研助理</span><span>编辑或艺术指导(杂志)</span></p>
    <p>ENFP型人通常是出色的思维广阔的思想家，他们能很容^地看出一个想法、计划或服务对于他人的效果。他们在计划中个考虑别人的需要和关注点，而且经常能想出创新的及富有人情的解决办法，他们喜欢创造性地解决问题，尤其当他们做为一个活泼而精力旺盛的群体的一部分时。ENFP型人往往聪明有趣，他们为刊登或播送而撰写广告的时候感到很满意。广告的迅速和不断变化是很有吸引力的。而且作为公关经理，他们能成为机构的出色发言人。</p>
    <p class="flex"><span>教育／咨询</span><span>特殊教育老师</span><span>双语种教育老师</span><span>早期儿童教育老师</span><span>艺术戏剧、音乐及英语老师</span><span>儿童福利顾问</span><span>酒精毒品禁戒顾问</span><span>社会工作者(老年人及儿童日常照顾问题)</span><span>发展指导</span><span>职业顾问</span><span>住宅安居指挥</span><span>民意调查员</span><span>主教顾问</span><span>康复中心工作人员</span><span>社会学家</span><span>心理学家</span></p>
    <p>许多ENFP型人发现对于他人有积极影响力的工作在本质上是令人满意的。他们能成为富于同情心与支持鼓励他人的心理学家，能成为有创造力而热诚的职业顾问，能帮助他们的客户发现新颖而有创见的解决问题的方法。他们关注事物的可能性，尤其是对于别人的可能性，而且他们充满感染力和旺盛精力的风格激励他们的客户试着在生活中进行积极的改变。他们乐于帮助别人发展个人的精神世界，并经常被牧师这类形式的职业所吸引。作为民意调查员，他们是促进机构转变的高效而富于创新的中介人物，作为向导来帮助个人获得可利用的资源。</p>
    <p class="flex"><span>健康护理／社会服务</span><span>营养学家</span><span>语言病理学家或听觉学家</span><span>全面健康医生</span><span>按摩治疗专家</span><span>雇员辅助计划顾问</span><span>理疗专家</span><span>法律调停人</span></p>
    <p>这些健康护理及社会服务领域的工作对于ENFP型人通常是有吸引力的，部分是因为它们能予人帮助的性质及具有运用创造性方法的机会。而且也因为它们是使人们保持独立和灵活的同时进行自我管理的职业。从事这些职业的ENFP型人感到非常满意，因为他们能在严格的传统框架之外工作（比如拥有各种不同客户的顾问）。</p>
    <p class="flex"><span>企业家／商业</span><span>顾问</span><span>发明家</span><span>无形商品或点子的销售</span><span>人力资源经理</span><span>人力资源发展训练人</span><span>会议安排人</span><span>雇佣发展专家</span><span>饭店老板</span><span>管理顾问：转变管理体制或合作组构建或管理多样化</span><span>公司或小组培训人</span><span>人力资源多样化管理人</span><span>广告业务管理人或经理</span><span>公关专家</span><span>营销主管：广播或电视或有线转播业</span><span>调职顾问</span><span>环境法律师</span></p>
    <p>ENFP型人是天生的企业家!他们喜欢给自己干活，因为这给予他们自由和灵活，也让他们有机会选择想做的工作以及想合作的人。他们通常极富想法并想付诸实现，尤其是那些将影响其他人的。</p>
    <p>许多ENFP型人喜欢在建立合作组、解决冲突或提高工作效率等方面作顾问。他们也喜欢成为独立的销售员，尤其是销售。传统的商业世界通常对于ENFP型人没有吸引力，因为他们不喜欢过多的规则、规定或标准操作程序。</p>
    <p>然而，在传统的机构中，ENFP型人群集中于人力资源部做培训员及顾问。他们发现诸如雇员发展顾问这样的职业可以运用他们事先谋划并提出利于人们完成工作的创造性方法的能力，同时还能不忘机构的目标。记住，上述只是可以为具有独特天分的ENFP型人提供满足感的一部分职业领域。</p>`,
    jy: `<p>现在你对自己的人格类型和动力已经有了一个比较清楚的了解，但这还不够。“如何通过这些信息使你在这份工作上取得更大的成功”，这是关键所在。运用你的能力非常容易。</p>
    <p class="b">你成功的秘诀在于：</p>
    <p>*把握事情轻重，优先处理重要事宜；</p>
    <p>*集中精力于某一目标；</p>
    <p>*对自己接手的事坚持到底。</p>
    <p>个人发展建议是我们咨询师多年测评职业咨询和职业生涯规划的心得体会和经验总结，我们意识到以下的建议中有很多是难以完全照办的，但只要你花时间认真思考，一定会对你有极大的帮助和改变：</p>
    <p class="b">发展建议：</p>
    <p>*需要设立优先级，考虑轻重缓急，发展持之以恒；</p>
    <p>*需要关注重要的细节；</p>
    <p>*需要学会审查计划或规划，而不是尝试去做所有看起来有吸引力的事情；</p>
    <p>*需要学会并运用时间管理技能。</p>`,
  },
  ENTP: {
    tag: '<h1>ENTP（外倾、直觉、思维和知觉）</h1><h2>天生的企业家</h2>',
    subscribe: `
    <p>1.反应快、聪明、长于多样事务。</p>
    <p>2.具激励伙伴、敏捷及直言讳专长。</p>
    <p>3.会为了有趣对问题的两面加予争辩。</p>
    <p>4.对解决新及挑战性的问题富有策略，但会轻忽或厌烦经常的任务与细节。</p>
    <p>5.兴趣多元，易倾向于转移至新生的兴趣。</p>
    <p>6.对所想要的会有技巧地找出逻辑的理由。</p>
    <p>7.长于看清础他人，有智能去解决新或有挑战的问题</p> `,
    ms: `<p>ENTP型的人喜欢兴奋与挑战。他们热情开放、足智多谋、健谈而聪明，擅于许多事情，不断追求增加能力和个人权力。</p>
    <p>ENTP型的人天生富有想象力，他们深深地喜欢新思想，留心一切可能性。他们有很强的首创精神，擅于运用创造冲动。ENTP型的人视灵感高于其他的一切，力求使他们的新颖想法转变为现实，他们好奇、多才多艺、适应性强，在解决挑战性和理论性问题时善于随机心变。</p>
    <p>ENTP型的人灵活而率直，能够轻易地看出任何情况中的缺点，乐于出于兴趣争论问题的某方面。他们有极好的分析能力，是出色的策略谋划者。他们几乎一直能够为他们所希望的事情找出符合逻辑的推理。大多数的ENTP别人喜欢审视周围的环境，认为多数的规则和章程如果不被打破，便意味着屈从。有时他们的态度不从习俗，乐于帮助别人超出可被接受和被期望的事情。他们喜欢自在地生活，在每天的生活中寻找快乐和变化。</p>
    <p>ENTP型的人富有想象力地处理社会关系，常常有许多的朋友和熟人。他们表现得很乐观，具有幽默感。ENTP型的人吸引和鼓励问伴，通过他们富有感染力的热情，鼓舞别人加入他们的行动中。他们喜欢努力理解和回应他人，而不是判断他人。</p>`,
    md: `<p>因为ENTP型人视创造力和革新力高于其他的一切，所以有时会忽视按照普遍的方式完成简单的事情，因为它是没有创造性的。对于常规和可预见的事物的强烈厌恶，使得他们很难注意到必要的细节。在他们热情地着手新鲜事物时，有时会忽视必要的准备，一头扎进去得太快。而且一旦解决了主要问题，他们常常会去做十一个兴奋人心的冒险，而不是圆满地坚持到看见原先的计划顺利通过。他们应该逐渐地少做出对各种计划的许诺，以便更多地完成自己已经开始的工作。</p>
    <p>ENTP型的人常常说话很快，其他方面全都如此，他们不允许别人发挥作用。他们诚实、公正，但是在准备好的对别人的批评中，却很生硬与不得体。ENTP型的人必须把别人的想法看作是正确和重要的，即使他们不具有这种想法。ENTP型的人迷人、有趣、使人愉快的同时，也会显得虚假。ENTP型的人必须抵抗想仅仅适应和表现的欲望，而是应分享真实的情感。</p>
    <p>ENTP型的人不愿承诺一件事情是由于他们害怕错过更吸引入的其他机会造成的。他们那种对于新的很有前景的事情保持开放并做出回应的愿望令人感到他们不可信赖，对待别人的计划很轻率。全面地考虑他们的行为对于别人的影响会有助于ENTP型的人显得更加可靠。</p>
    <p>ENTP型的人天生的敏捷，以及对于即将发生之事的预见能力意味着他们偶然会错误地假设他们知道人们将要说的话，而月．可能会一下子就得出意见。花些时间，更仔细地注意在他们周围的世界真正将要发生的事情，仔细地聆听他人的信息和反应会帮助他们避免显得傲慢与无礼。</p>`,
    qn: `<p>外向与内向是指我们心理能量的作用方向，判断与知觉是我们做事的两种方式。心理学称之为“态度”。感觉与直觉是我们获取信息的方式，思考和情感是做决定的方式，心理学称之为“功能”，功能是人格理论的精髓、核心。</p>
    <p>每一个人在都会用到四种功能，但对功能应用的喜好程度不一样。等级中第一位的称为“主导功能”，第二位的称为“辅助功能”。每个人都需要运用到这两个过程。</p>
    <p>有效地使用你的类型：(ENTP)</p>
    <p>ENTP型的人主导功能是直觉（喜欢推论），辅助功能是思考。他们倾向于：探求隐含的原因、可能性或事物的整体。对事实和经验进行逻辑的分析，并做出结论。</p>
    <p>ENTP型的人比较少地使用他们的第三、第四功能--感觉和情感。即使在需要这些功能的场合，他们仍然无法有效使用。</p>
    <p>作为一个ENTP型的人：如果你过多地关注直觉，你可能错过事物的相关事实、细节或以前的经验如果你只使用思考进行判断的话，你可能在需要的时间忘记去称赞他人或忽视你决策对其它人的影响。</p>`,
    jjwt: `<p>每个人都会用自己的方式进行决策，决策通常来自于你的主导功能，并且完全忽视你的第四功能。你的功能运用如下：</p>
    <p>主导功能直觉——辅助功能思考——第三功能感觉——第四功能情感</p>
    <p>人格中的每一种功能对解决问题都具有关键和重要作用，忽视任何一个都将导致做出有缺陷的决定，理想化的问题解决功能流程如下：</p>
    <p>一个好的决策通常来自于四个功能的合理运用。当你尝试去解决问题时，你可以利用上表指导这个过程，但在整个的执行过程是非常困难的，因为每个人都不愿意用他不喜欢的功能去解决问题。就算你有意识地努力运用所有这些功能，你也不可能会在“真实生活”中以这种顺序运用它们。关键是要利用它们提醒自己，完善决策，就算你不按这种方式行事。</p>
    <p>工作中选择符合你的主导功能和辅助功能的岗位是非常重要的。第三功能要经过发展锻炼才能合适运用，第四功能可能永远不会在你的职业生涯中起到关键作用，因此你应当避免那种要求长期使用它的工作。</p>`,
    ys: `<p>对于不同的人格类型和不同的动力等极而言，没有“好”与“坏”之分，每一个人都是一个独一无二的个体，都有其特别的优势和劣势，但问题的关键在于如何认识这些优势和劣势,完善自己。我们对于成功的建议是：“取已之长，补已之短”。学会了这一点将会影响到你的成败及你对工作的喜好。</p>
    <p class="b">你在工作中的优势：</p>
    <p>*出色的交际才能、能使别人对自己的观点感到兴奋</p>
    <p>*急切地“想知道盒子外边的世界”、能相处一些新的可能性</p>
    <p>*杰出的创造性解决问题的能力</p>
    <p>*探险精神、创新意识以及克服困难的勇气</p>
    <p>*兴趣爱好广泛，易于接受新事物</p>
    <p>*有“走自己的路，让别人去说吧”的乐观主义激情</p>
    <p>*学习新知识的信心和动力都很强大</p>
    <p>*天生的好奇心理，快速地搜索所需信息的技能</p>
    <p>*能够把握事情的全局，弄清思想和行为的长远影响</p>
    <p>*同时处理多个问题的能力      </p>
    <p>*对别人的需要和意图的知觉</p>
    <p>*能灵活地适应新情况，有熟练的变换能力</p>
    <p>*在社交生活中不会感到拘谨，能舒适地适应大多数社交场合</p>`,
    ls: `<p>下面列出了你工作中可能存在的缺点，这些缺点有的比较明显，有的并不明显或你没有意识到，目的是为了让你“注意”到它们，并考虑产生的原因。缺点有些是天生的，而有些是长时间形成的，因此你不可能在一两天内改变，而是去提醒、思考。注意：其实知道存在的问题就是改变提高中很重要的一步，你会发现你正在慢慢发生变化。</p>
    <p class="b">您工作中的劣势：</p>
    <p>*难以使自己有条不紊和富于条理</p>
    <p>*在区分出应该优先对待的事物以及做出决定方面有一定的困难</p>
    <p>*过于自信；可能会不恰当地运用自己的能力和社会经历</p>
    <p>*倾向于用“是不是有可能”来看待问题，而不是以可能性、可行性的大小来衡量事物</p>
    <p>*很可能不切实际地许诺</p>
    <p>*对思维狭窄及思想顽固的人缺乏耐心</p>
    <p>*问题一旦解决，兴趣便不复存在</p>
    <p>*不喜欢按传统的、公式化的以及例行的方式来办事</p>
    <p>*对待细节和后续工作可能缺乏耐心，对自要求不严格</p>
    <p>*对事物很容易感到厌烦，并且可能在不恰当的时候把注意力转移到别的事情上去</p>
    <p>*不喜欢重复地做相同的工作任务</p>
    <p>*对自己不信任的人耐心不够</p>`,
    gwtz: `<p>研究发现：职业满足会使你的工作主动性更强，积极性更高，更愿意去工作。以下不是简单的告诉你什么样的工作适合你，而是细化的帮你分析工作中的哪些特质对你重要，你还需要从中选出你认为最重要的，因为不同经历的人对特质的重要程度要求是不同的。每个岗位的工作内容都在随企业的发展而发展，不是一成不变的，有时候岗位的发展方向需要我们自己去争取。所以找到适合的工作不如找到适合自己发展的岗位更直接。这些特质可以帮助明确如何主动的发展或争取你岗位中的那些特质。</p>
    <p>下面的条目从各个侧面上认证了您怎样才能受到真正的职业满足，看完这些条目之后，我们建议您根据它们对您的重要程序进行排序，排序的时候，回想一下您过去的学习、工作经历以及当前学习环境和工作感受，并思考：“哪一些是令你感到特别满意，有哪些令你极其不高兴”。试着寻找贯穿这些经历的主题。</p>
    <p class="b">你的岗位特质：</p>
    <p>1、能让我与别人建立并维持友好、真诚的人际关系，通过切实有形的方式来帮助他们提高生活质量。</p>
    <p>2、能给他人带来切实的利益；让我有时间来学习和精通必要的技能，然后把它们运用到工作中来为他人提供服务。</p>
    <p>3、能让我具有一定的影响力；工作时能和许多人打交道，领导他们为了共同的目标而和谐地合作。</p>
    <p>4、有明确的工作要求，工作表现是运用明确的、确定的成文标准来衡量的。</p>
    <p>5、在一个和谐、合作的气氛下进行的；同事、上司、客户、病人等人彼此之间不会发生冲突和不和。</p>
    <p>6、为了保证我的任务能够出色地完成，我能自主地做出一些决定，并运用一些高效的方法。</p>
    <p>7、每天都能让我有大量的与别人交往的机会；而且我能够成为决策过程的重要成员。</p>
    <p>8、为了确保事情顺利高效地进展，我能够安排自己以及周围其他人的工作。</p>
    <p>9、在一个友好的气氛中进行的；别人对我的工作成就表示赞赏，我能体会到认可和支持，我和同事之间是朋友关系。</p>
    <p>10、在一个有章可循的环境中进行的，所有的指令和要求都公开和清晰；而且权威受到充分的重视。</p>`,
    zy: `<p>也许你会觉得下面的工作种类繁杂，但仔细分析你就会发现，这些工作各自的特色正是你对工作的要求，我们会在下面列出各类工作的特质。当然，这不可能是一个完美的适合你的工作的综合，但是，却能够向你展示你以前不曾考虑过的工作的可能性。在列出这些工作的同时，我们也要敬告您：每种职业中都有各种性格类型的成功人士；即使是相同的工作，不同公司的要求也不尽相同，所以除了工作名称外，你还要对贵公司要求的工作内容和公司文化作详细的了解。我们希望在经济快速发展、新型工作不断涌现将来，这份报告上列举的工作种类不是限制了你的选择，而是告诉你，面对新工作的选择或机会时，应该从哪些因素来分析这些工作对你的适合程度。</p>
    <p class="b">适合ENTP型人的一般职业</p>
    <p>偏好的领域：创作、创业、开发、投资、公共关系、政治、创造性的领域。</span></p>
    <p>偏好的典型职业：</p>
    <p class="flex"><span>企业家</span><span>发明家</span><span>投资银行家</span><span>风险投资商</span><span>管理营销顾问</span><span>广告文案</span><span>访谈节目主持</span><span>政客</span><span>房地产开发商</span><span>后勤顾问</span><span>投资经纪人</span><span>广告创意指导</span><span>演员</span><span>战略规划家</span><span>大学校长/学院院长</span><span>互联网营销人员</span><span>营销策划人员。 </span></p>`,
    jy: `
    <p>ENTP们往往有着许多创新的想法，能够突破常规的束缚，获得创造性的成就。但是ENTP喜欢突破规则这一个特质常常导致他们对于自己手头的工作和项目没有办法坚持到底。他们常常在进行一个项目的时候忽然灵光一闪，转而去进行其他项目。</p>
    <p>从某种程度上说，ENTP应该去遵守一些规则，这有利于他们提高效率。ENTP们在进行一项工作的时候可以尝试着做一个详细的计划，按照步骤和规则完成。这样下来，ENTP就会提高效率，也能有具体的输出。</p>
    <p>ENTP们对于自己的新想法或者新项目都有着极大的热情，他们会“全心投入”某一个项目和工作之中。但是ENTP们在开展工作之前并没有仔细的调查过，这导致他们往往会没注意到细节，从而带来一些坏的影响。</p>
    <p>ENTP们可以试着在开始热情投入之前先做一做调查和计划，这样可以大大的节省ENTP们的精力，也能减少时间和精神的浪费。</p>
    <p>ENTP们往往都是从大局观着眼，他们关注点在于主题模式和广泛的、大的项目。他们通常会忽视一些细小的东西和细节，在他们眼中，处理细节很麻烦。但是，不注意细节往往会浪费更多的时间和精力。</p>
    <p>ENTP们可以尝试着去关注一些小事和细节，既要有大局观，也要有细节观，如果能够在这一点上多加注意，ENTP们将会发展的更好。</p>
    <p>ENTP们往往不太分得清楚概率和可能性。他们在做事情的时候往往只能想到的是这件事情有没有可能实现，但是他们从未想过这件事情实现的概率有多大。有时候，某一想法确实可能实现，但是实现的概率只有千分之一，甚至百万分之一，这导致这个想法的可行性并不高。</p>
    <p>所以ENTP们在很多时候都需要关注一下自己想法实现的概率是大多，可行性怎么样。这样做可以让ENTP们拥有更高的效率和有更多的可以实现的输出。</p>
    <p>每一种人格都有自己的优劣势，我们在发展自己的优势的时候，也要适当的补齐自己的劣势，以此来获得更多的成功。</p>
    `,
  },
  ESTJ: {
    tag: '<h1>ESTJ（外倾、感觉、思维和判断）</h1><h2>事务料理家</h2>',
    subscribe: `
    <p>1.务实、真实、事实倾向，具企业或技术天份。</p>
    <p>2.不喜欢抽象理论；最喜欢学习可立即运用事理。</p>
    <p>3.喜好组织与管理活动且专注以最有效率方式行事以达致成效。</p>
    <p>4.具决断力、关注细节且很快作出决策—优秀行政者。</p>
    <p>5.会忽略他人感受。</p>
    <p>6.喜作领导者或企业主管。 </p>`,
    ms: `<p>ESTJ型的人很善于完成任务；他们喜欢操纵局势和促使事情发生；他们具有责任感、勤勤恳恳，信守他们的承诺；他们喜欢条理性并且能记住和组织安排许多细节。他们及时和尽可能高效率地、系统地开始达到目标。</p>
    <p>ESTJ型的人被迫做决定。他们常常以自己过去的经历为基础得出结论。他们很客观，有条理性和分析能力，以及很强的推理能力。事实上，除了符合逻辑外，从他没有什么可以使他们信服。同时，ESTJ型的人又很现实、有头脑、讲求实际。他们更感兴趣的是“真实的事物”，而不是诸如抽象的想法和理论等无形的东西，他们往往对那些认为没有实用价值的东西不感兴趣。他们知道自己周围将要发生的事情，而首要关心的则是目前。</p>
    <p>因为ESTJ型的人依照一套固定的规则生活，所以他们坚持不懈和值得依赖。他们往往很传统，有兴趣维护现存的制度。虽然对于他们来说，感情生活和社会活动并不像生活的其他方向那样重要，但是对于亲情关系，他们却固守不变。他们不但能很轻松地判断别人．而且还是条理分明的纪律执行者。ESTJ型的人直爽坦率，友善合群、通常他们会很容易地了解事物，这是因为“你看到的便是你得到的”。</p>`,
    md: `<p>因为ESTJ型的人对自己和别人都采取一种严格的道德规范，所以当他们把自己的行为标准强加在别人身上时，则会被认为很独裁。他们应该努力变得更灵活和思想开阔—些，这样会避免过于粗鲁。作为公正和有条理的分析家，ESTJ型的人不会出于自然地考虑他们的决定对别人的影响。他们被视为冷酷而漠然，因而他们应该常常更多地关心自我的情感，对别人的思想和感受也应给予更多的尊重。</p>
    <p>ESTJ型的人天生就挑剔，所以常常不会对周围那些人的特性或贡献表示出欣赏。就此而言，他们必须努力做到更多地关注他人的才能和努力，并给予祝贺和赞扬。有时ESTJ型的人对于自己的计划显得很急切，以致无法停下来倾听别人很有必要的谈话，他们不会很自然地询问“假如，则……”，所以他们常常会漏掉有可能性的价值、含义、联系和形式。防止思维封闭的简单方法是在说话之前，等待几分钟，给别人一个提供信息的机会。</p>
    <p>ESTJ型的人常常在没有收集到所有必要的信息之前，或足没有花费时间充分了解情况之前便匆匆地下结论。因此他们必须学会有意识地放慢做出决定；直到他们已经考虑了更多的信息，尤其是一些他们可能忽略的其他方面。那些已经学会放弃一些他们所追求的控制权的ESTJ型人，以及那些学会看清生命中存在“灰色区”(而不只是黑白分明地看待事物)的ESTJ型的人更有适应能力，更容易成功。</p>`,
    qn: `<p>外向与内向是指我们心理能量的作用方向，判断与知觉是我们做事的两种方式。心理学称之为“态度”。感觉与直觉是我们获取信息的方式，思考和情感是做决定的方式，心理学称之为“功能”，功能是人格理论的精髓、核心。</p>
    <p>每一个人在都会用到四种功能，但对功能应用的喜好程度不一样。等级中第一位的称为“主导功能”，第二位的称为“辅助功能”。每个人都需要运用到这两个过程。</p>
    <p>有效地使用你的类型：(ESTJ)</p>
    <p>ESTJ型的人主导功能是思考（喜欢事实），辅助功能是感觉。他们倾向于：对事实和经验做出符合逻辑结论。注意细节、重视实际、能记住琐碎细节、耐得住烦闷的工作、有耐性、细心有系统。</p>
    <p>ESTJ型的人比较少地使用他们的第三、第四功能—直觉和情感。即使在需要这些功能的场合，他们仍然无法有效使用。</p>
    <p>作为一个ESTJ型的人：如果你过多的使用思考功能，你可能在需要的时间忘记去称赞他人，或忽视你决策对其它人的影响。如果你过多的使用思考功能，你可能失去整体的概念、想不出各种可能解决的途径、不住直觉、不求创新、无法应付太复杂的工作、不喜欢预测未来。</p>`,
    jjwt: `<p>每个人都会用自己的方式进行决策，决策通常来自于你的主导功能，并且完全忽视你的第四功能。</p>
    <p>你的功能运用如下：</p>
    <p>主导功能思维——辅助功能感觉——第三功能直觉——第四功能情感</p>
    <p>人格中的每一种功能对解决问题都具有关键和重要作用，忽视任何一个都将导致做出有缺陷的决定，理想化的问题解决功能流程如下：</p>
    <p>一个好的决策通常来自于四个功能的合理运用。当你尝试去解决问题时，你可以利用上表指导这个过程，但在整个的执行过程是非常困难的，因为每个人都不愿意用他不喜欢的功能去解决问题。就算你有意识地努力运用所有这些功能，你也不可能会在“真实生活”中以这种顺序运用它们。关键是要利用它们提醒自己，完善决策，就算你不按这种方式行事。工作中选择符合你的主导功能和辅助功能的岗位是非常重要的。第三功能要经过发展锻炼才能合适运用，第四功能可能永远不会在你的职业生涯中起到关键作用，因此你应当避免那种要求长期使用它的工作。</p>`,
    ys: `<p>对于不同的人格类型和不同的动力等极而言，没有“好”与“坏”之分，每一个人都是一个独一无二的个体，都有其特别的优势和劣势，但问题的关键在于如何认识这些优势和劣势,完善自己。我们对于成功的建议是：“取已之长，补已之短”。学会了这一点将会影响到你的成败及你对工作的喜好。</p>
    <p class="b">你在工作中的优势：</p>
    <p>*注重实践，关心结果</p>
    <p>*能强有力地承担自己的义务；必要的时候能够快刀斩乱麻、意志坚定</p>
    <p>*能够自始至终地关注着公司（或组织）的目标</p>
    <p>*办事精确、很少出差错，有要把工作做好的强烈愿望</p>
    <p>*有很好地遵循已经建立起的工作安排和工作程序的习惯</p>
    <p>*能够敏感地觉察出不合逻辑、不连贯、不现实以及不称职的人或事</p>
    <p>*很好的组织能力；能很客观地做出决定</p>
    <p>*相信传统模式的可取之处，并能够遵循传统模式</p>
    <p>*很强的责任心；别人可以信任你去实现自己的诺言</p>
    <p>*清楚明白的工作伦理；对效率和成果的追求</p>
    <p>*通情达理，视角现实 </p>`,
    ls: `<p>下面列出了你工作中可能存在的缺点，这些缺点有的比较明显，有的并不明显或你没有意识到，目的是为了让你“注意”到它们，并考虑产生的原因。缺点有些是天生的，而有些是长时间形成的，因此你不可能在一两天内改变，而是去提醒、思考。注意：其实知道存在的问题就是改变提高中很重要的一步，你会发现你正在慢慢发生变化。</p>
    <p class="b">您工作中的劣势：</p>
    <p>*对不遵循工作程序和忽略重要细节的人有点不耐烦</p>
    <p>*不愿意尝试、接受新的和未经考验的观点和想法</p>
    <p>*对变动感到不安；排斥革新</p>
    <p>*对低效率的或需花很多时间才能完成的程序或工作缺乏耐心</p>
    <p>*只考虑眼前需要而不顾长远利益</p>
    <p>*有为了实现自己的利益而无视别人的需要的趋向</p>
    <p>*难以看到将来的可能性</p>
    <p>*对于方针或决定将会对别人造成什么样的影响缺乏敏感</p>
    <p>*不喜欢听相反的意见；可能频繁地打断别人的发言</p>`,
    gwtz: `<p>研究发现：职业满足会使你的工作主动性更强，积极性更高，更愿意去工作。以下不是简单的告诉你什么样的工作适合你，而是细化的帮你分析工作中的哪些特质对你重要，你还需要从中选出你认为最重要的，因为不同经历的人对特质的重要程度要求是不同的。每个岗位的工作内容都在随企业的发展而发展，不是一成不变的，有时候岗位的发展方向需要我们自己去争取。所以找到适合的工作不如找到适合自己发展的岗位更直接。这些特质可以帮助明确如何主动的发展或争取你岗位中的那些特质。</p>
    <p>下面的条目从各个侧面上认证了您怎样才能受到真正的职业满足，看完这些条目之后，我们建议您根据它们对您的重要程序进行排序，排序的时候，回想一下您过去的学习、工作经历以及当前学习环境和工作感受，并思考：“哪一些是令你感到特别满意，有哪些令你极其不高兴”。试着寻找贯穿这些经历的主题。</p>
    <p>你的岗位特质：</p>
    <p>1、让我系统地工作，组织和整理事实、原则或人事档案；为了一个合乎逻辑的目标而有效地利用时间和资源。</p>
    <p>2、在处理具体的、直接的、有明确属性的任务时能运用我高超的办事技能和强大的推理能力。</p>
    <p>3、是通过公平、合理、清晰和客观的标准来衡量和评估的。</p>
    <p>4、在一个友好的气氛中和其他刻苦认真的人们一起工作，而且他们不把个人问题带到工作中来，也不盘问我工作的个人感受。</p>
    <p>5、有着现实、有形的属性；有时在的应用价值和明确的运行结果。</p>
    <p>6、要有明确的发展前景和清晰的汇报等级制度。</p>
    <p>7、让我成果丰硕；去安排和组织一些必要的步骤和资源，遵循一定的工作程序，有权规定最后的期限，并能够在此前完成任务。</p>
    <p>8、在一个稳定和可预测的环境中工作，但是也能让我和很多人在一起坐很多事情。</p>
    <p>9、需要和许多人一起来共同完成，使我能支配自己和他人。</p>
    <p>10、让我有决定权、很大的管理权利和很多职责任务；而且，我的观点、意见和经历很受关注和重视。</p>`,
    zy: `<p>也许你会觉得下面的工作种类繁杂，但仔细分析你就会发现，这些工作各自的特色正是你对工作的要求，我们会在下面列出各类工作的特质。当然，这不可能是一个完美的适合你的工作的综合，但是，却能够向你展示你以前不曾考虑过的工作的可能性。在列出这些工作的同时，我们也要敬告您：每种职业中都有各种性格类型的成功人士；即使是相同的工作，不同公司的要求也不尽相同，所以除了工作名称外，你还要对贵公司要求的工作内容和公司文化作详细的了解。我们希望在经济快速发展、新型工作不断涌现将来，这份报告上列举的工作种类不是限制了你的选择，而是告诉你，面对新工作的选择或机会时，应该从哪些因素来分析这些工作对你的适合程度。</p>
    <p class="b">适合ESTJ型人的一般职业</p>
    <p class="flex"><span>销售／服务</span><span>保险代理</span><span>丧葬承办者</span><span>厨师</span><span>陆军军官</span><span>教师：贸易、工业、技术</span><span>政府雇员</span><span>保安人员</span><span>体育商品／设备经销商</span><span>药品经销商</span><span>电信防护员</span><span>警察／监护官／管教官</span><span>销售(有形的东西)：计算机、不动产</span></p>
    <p>这些职业允许ESTJ在现实生活中从事一些实际的、具体的工作。这些职业中的大多数都需要遵守一些原则和标准，还需要与公众打交道。ESTJ喜欢处于一个“有权”的位置，他们喜欢发号施令。经营不动产则给他们提供机会使他们能够迅速取得有形的结果。</p>
    <p class="flex"><span>科技／物理</span><span>计算机系统分析家</span><span>审计员</span><span>总承包商</span><span>农场主</span><span>建筑工人</span><span>药剂师</span><span>临床医师</span><span>会计学内部审计员</span><span>技术教员</span><span>脑电图技术专家／技师</span><span>工程师：机械领域／应用领域</span><span>律师帮办</span></p>
    <p>这些领域能够发挥ESTJ技术和机械方面的才能，每一种工作都需要他们收集、组织、分析事实资料，然后进行演绎的推理。每一种工作都需要一种有逻辑的、系统化的工作方式，这正是ES-TJ所特有的。他们就喜欢在整齐、有条理的环境中工作，他们不能忍受工作中的混乱状态和效率低下。</p>
    <p class="flex"><span>管理</span><span>银行高级职员／贷款员</span><span>项目经理</span><span>职员总管</span><span>行政官员</span><span>工厂监督人</span><span>数据库经营者</span><span>购物代理人</span><span>信贷分析员</span><span>制定规章制度的官员</span><span>预算分析员</span><span>管理人：社会保健服务</span><span>信息主管</span><span>管理顾问：企业运行</span><span>后勤供给经理</span><span>银行经理／贷款员</span><span>信贷分析员／顾问</span></p>
    <p>管理领域非常适合ESTJ，因为他们喜欢处于有权力的位置。ESTJ都是优秀的管理人员，因为他们喜欢发布命令，喜欢作决定和指导别人。同时，他们对所服务的机构都非常忠心。管理工作需要不断地与人打交道，需要有对别人的工作进行指导、管理和评价的能力。</p>
    <p class="flex"><span>专门领域</span><span>牙医</span><span>内科医生：普通医学</span><span>股票经纪人</span><span>法官</span><span>行政领导</span><span>教师：技术／贸易</span><span>公司财务律师</span><span>电气工程师</span><span>最初保健护理医生</span><span>工业工程师</span><span>律师帮办</span><span>药剂师</span><span>土木／机械／冶金工程师</span></p>
    <p>这些特定职业的吸引力在于它们能使ESTJ在一些已经建立起来的、传统的、具有一定权力的机构中工作。牙科和医学是需要技术的职业，通常包括一些具体工作，工作对象是人或其它有形的东西，如牙齿和牙床(对牙医来说)、人体(对临床医生而言)。这些职业利用了ESTJ演绎推理的能力和解释因果关系的能力。</p>
    <p>ESTJ喜欢遵循一定的程序办事，这些程序是根据他们自己或其他他们所敬佩的人的经验建立起来的，通常都是非常有效的。</p>`,
    jy: `<p>现在你对自己的人格类型和动力已经有了一个比较清楚的了解，但这还不够。“如何通过这些信息使你在这份工作上取得更大的成功”，这是关键所在。运用你的能力非常容易。</p>
    <p>你成功的秘诀在于：</p>
    <p>*放慢节奏；</p>
    <p>*多为别人着想；</p>
    <p>*要能灵活变通。</p>
    <p>个人发展建议是我们咨询师多年测评职业咨询和职业生涯规划的心得体会和经验总结，我们意识到以下的建议中有很多是难以完全照办的，但只要你花时间认真思考，一定会对你有极大的帮助和改变：</p>
    <p class="b">发展建议：</p>
    <p>*在决策前，需要考虑问题的各个方面，包括人的因素的影响；</p>
    <p>*需要督促自己仔细考虑变动所带来的得失；</p>
    <p>*需要作出特殊的努力以夸赞别人的成绩。</p>
    `,
  },
  ESFJ: {
    tag: '<h1>ESFJ（外倾、感觉、情感和判断）</h1><h2>我能为您做些什么？</h2>',
    subscribe: `
    <p>1.诚挚、爱说话、合作性高、受　欢迎、光明正大 的—天生的　合作者及活跃的组织成员。</p>
    <p>2.重和谐且长于创造和谐。</p>
    <p>3.常作对他人有益事务。</p>
    <p>4.给予鼓励及称许会有更佳工作成效。</p>
    <p>5.最有兴趣于会直接及有形影响人们生活的事务。</p>
    <p>6.喜欢与他人共事去精确且准时地完成工作。 </p>`,
    ms: `<p>ESFJ型的人通过直接的行动和合作积极地以真实、实际的方法帮助别人。他们友好、富有同情心和责任感。因为ESFJ型的人把他们同别人的关系放在十分重要的位置，所以他们往往健谈、受人欢迎、有礼貌、渴望取悦他人。他们具有和睦的人际关系，并且通过很大的努力以获得和维持这种关系。事实上，他们常常理想化自己欣赏的人或物。</p>
    <p>ESFJ型的人往往对自己以及自己的成绩十分欣赏，因而他们对于批评或者别人的漠视很敏感。通常他们很果断，表达自己坚定的主张，乐于事情能很快得到解决。</p>
    <p>ESFJ型的人很现实，他们讲求实际、实事求是和安排有序。他们参与并能记住重要的事情和细节，乐于别人也能对自己的事情很确信。他们在自己的个人经历或在他们所信赖之人的经验之上制定计划或得出见解。他们知道并参与周围的物质世界，并喜欢具有主动性和创造性。</p>
    <p>ESFJ型的人十分小心谨慎，也非常传统化，因而他们能恪守自己的责任与承诺。他们支持现存制度，往往是委员会或组织机构中积极主动和乐于合作的成员，他们重视并能保持很好的社交关系。他们不辞劳苦地帮助他人，尤其在遇到困难或取得成功时，他们都很积极活跃。</p>`,
    md: `<p>ESFJ型的人高度重视和睦的关系，所以他们往往避免冲突，而不是毫不含糊地处理问题。有时他们过多地重视和在意所关心的见解和情感。在紧张或痛苦之时，他们对事实情况茫然无知。他们必须学会坦诚率直地处理冲突，确信在最困境的情形中，他们对于别人的情感具有天生的敏感，这种敏感会为他们提供必要的圆通。</p>
    <p>ESFJ型的人往往会忽视自己的情感，因为他们渴望帮助别人，使别人高兴。他们很难拒绝别人或向别人请求帮助，这是因为不希望冒犯或使别人失望。通常他们很难提出或接受建设性的意见，因为他们很个人化地去处理事物。当他们找不到改变生活的办法，就会很悲观失望。暂时退后一步思考问题，判定一些目标，这样会使他们得到新的前景。在努力帮助别人的过程中，ESFJ型的人有时会以专横和盛气凌人的态度表达他们的观点。因而，在帮助别人之前，最好看一看别人是否希望得到建议或帮助。</p>
    <p>ESFJ型的人经常在还有充分的时间去收集一些不明确的情况，并考虑他们行动的后果之前，便匆匆地做出决定。他们缺少灵活性，往往不会去寻找解决问题的新办法或不同办法。推迟做决定，更多地对解决问题的新颖方法采取欢迎的态度，这样才会使自己有更好的资料基础，更好地做出决定。</p>`,
    qn: `<p>外向与内向是指我们心理能量的作用方向，判断与知觉是我们做事的两种方式。心理学称之为“态度”。感觉与直觉是我们获取信息的方式，思考和情感是做决定的方式，心理学称之为“功能”，功能是人格理论的精髓、核心。</p>
    <p>每一个人在都会用到四种功能，但对功能应用的喜好程度不一样。等级中第一位的称为“主导功能”，第二位的称为“辅助功能”。每个人都需要运用到这两个过程。</p>
    <p>有效地使用你的类型：(ESFJ)</p>
    <p>ESFJ型的人主导功能是情感，辅助功能是感觉。他们倾向于：体谅他人感受、了解他人的需要、喜欢和谐的人际关系、易表露情感、喜去说服他人。注意细节、重视实际、能记住琐碎细节、耐得住烦闷的工作、有耐性、细心有系统。</p>
    <p>ESFJ型的人比较少地使用他们的第三、第四功能—直觉和思维。即使在需要这些功能的场合，他们仍然无法有效使用。</p>
    <p>作为一个ESFJ型的人：如果你过多的使用情感功能，你可能在会表现得不合乎逻辑、不够客观、没有组织系统的思考、不具批判精神全盘接受、感情用事。如果你过多地使用感觉功能，你可能会失去整体的概念、想不出各种可能解决的途径、不住直觉、不求创新、无法应付太复杂的工作、不喜欢预测未来。</p>`,
    jjwt: `<p>每个人都会用自己的方式进行决策，决策通常来自于你的主导功能，并且完全忽视你的第四功能。你的功能运用如下：</p>
    <p>主导功能情感——辅助功能感觉——第三功能直觉——第四功能思维</p>
    <p>人格中的每一种功能对解决问题都具有关键和重要作用，忽视任何一个都将导致做出有缺陷的决定，理想化的问题解决功能流程如下：</p>
    <p>一个好的决策通常来自于四个功能的合理运用。当你尝试去解决问题时，你可以利用上表指导这个过程，但在整个的执行过程是非常困难的，因为每个人都不愿意用他不喜欢的功能去解决问题。就算你有意识地努力运用所有这些功能，你也不可能会在“真实生活”中以这种顺序运用它们。关键是要利用它们提醒自己，完善决策，就算你不按这种方式行事。</p>
    <p>工作中选择符合你的主导功能和辅助功能的岗位是非常重要的。第三功能要经过发展锻炼才能合适运用，第四功能可能永远不会在你的职业生涯中起到关键作用，因此你应当避免那种要求长期使用它的工作。</p>`,
    ys: `<p>对于不同的人格类型和不同的动力等极而言，没有“好”与“坏”之分，每一个人都是一个独一无二的个体，都有其特别的优势和劣势，但问题的关键在于如何认识这些优势和劣势,完善自己。我们对于成功的建议是：“取已之长，补已之短”。学会了这一点将会影响到你的成败及你对工作的喜好。</p>
    <p>你在工作中的优势：</p>
    <p>*有很大的精力和动力来完成任务、创造成果</p>
    <p>*能够有效地合别人写作，并且和他人建立起友好和睦的人际关系</p>
    <p>*处理事实和细节问题时，具有客观的态度和得天独厚的天资才能</p>
    <p>*善于培养和帮助他人；对于别人良好的行为举止能够给予赞扬，并使他们更加发扬光大</p>
    <p>*果敢坚决，稳定可靠</p>                
    <p>*能够维护组织一向德价值观念和工作原则</p>
    <p>*灵活的组织技能和明确的工作道德    </p>
    <p>*信奉工作在一个传统、稳定的组织里有其自身的优点和长处</p>
    <p>*乐意遵循已制定的理性公事和工作程序  </p>
    <p>*通情达理，视角现实</p>`,
    ls: `<p>下面列出了你工作中可能存在的缺点，这些缺点有的比较明显，有的并不明显或你没有意识到，目的是为了让你“注意”到它们，并考虑产生的原因。缺点有些是天生的，而有些是长时间形成的，因此你不可能在一两天内改变，而是去提醒、思考。注意：其实知道存在的问题就是改变提高中很重要的一步，你会发现你正在慢慢发生变化。</p>
    <p>您工作中的劣势：</p>
    <p>*不愿意尝试、接受新的和未经考验的观点和想法</p>
    <p>*对于别人的异议和批评耿耿于怀；不喜欢在紧张的气氛中工作</p>
    <p>*可能只关注眼前需要，而对于长远利益重视不够</p>
    <p>*难以适应新境况，在不同工作任务之间来回切换时会有困难</p>
    <p>*容易表现的过于敏感；逃避难堪的场合</p>
    <p>*不愿意长时间独自工作，特及其想要和别人在一起</p>
    <p>*会轻易地把个人喜好表露出来</p>
    <p>*可能由于情感方面的负担而疲惫不堪</p>
    <p>*在掌握的嬉戏和资料还不够的情况下便草率作出决定</p>
    <p>*之关注具体的细节之处，而不能整体地把握一个情况或者事物的长远影响</p>
    <p>*容易固执己见、武断地做出决定</p>
    <p>*不愿意听取和接受反面的观点和意见</p>
    <p>*得不到赞扬或欣赏之辞的时候，可能会灰心丧气</p>
    <p>*只考虑眼前的需要，不愿意为事情作长远打算</p>`,
    gwtz: `<p>研究发现：职业满足会使你的工作主动性更强，积极性更高，更愿意去工作。以下不是简单的告诉你什么样的工作适合你，而是细化的帮你分析工作中的哪些特质对你重要，你还需要从中选出你认为最重要的，因为不同经历的人对特质的重要程度要求是不同的。每个岗位的工作内容都在随企业的发展而发展，不是一成不变的，有时候岗位的发展方向需要我们自己去争取。所以找到适合的工作不如找到适合自己发展的岗位更直接。这些特质可以帮助明确如何主动的发展或争取你岗位中的那些特质。</p>
    <p>下面的条目从各个侧面上认证了您怎样才能受到真正的职业满足，看完这些条目之后，我们建议您根据它们对您的重要程序进行排序，排序的时候，回想一下您过去的学习、工作经历以及当前学习环境和工作感受，并思考：“哪一些是令你感到特别满意，有哪些令你极其不高兴”。试着寻找贯穿这些经历的主题。</p>
    <p>你的岗位特质：</p>
    <p>1、能让我与别人建立并维持友好、真诚的人际关系，通过切实有形的方式来帮助他们提高生活质量。</p>
    <p>2、能给他人带来切实的利益；让我有时间来学习和精通必要的技能，然后把它们运用到工作中来为他人提供服务。</p>
    <p>3、能让我具有一定的影响力；工作时能和许多人打交道，领导他们为了共同的目标而和谐地合作。</p>
    <p>4、有明确的工作要求，工作表现是运用明确的、确定的成文标准来衡量的。</p>
    <p>5、在一个和谐、合作的气氛下进行的；同事、上司、客户、病人等人彼此之间不会发生冲突和不和。</p>
    <p>6、为了保证我的任务能够出色地完成，我能自主地做出一些决定，并运用一些高效的方法。</p>
    <p>7、每天都能让我有大量的与别人交往的机会；而且我能够成为决策过程的重要成员。</p>
    <p>8、为了确保事情顺利高效地进展，我能够安排自己以及周围其他人的工作。</p>
    <p>9、在一个友好的气氛中进行的；别人对我的工作成就表示赞赏，我能体会到认可和支持，我和同事之间是朋友关系。</p>
    <p>10、在一个有章可循的环境中进行的，所有的指令和要求都公开和清晰；而且权威受到充分的重视。</p>`,
    zy: `<p>也许你会觉得下面的工作种类繁杂，但仔细分析你就会发现，这些工作各自的特色正是你对工作的要求，我们会在下面列出各类工作的特质。当然，这不可能是一个完美的适合你的工作的综合，但是，却能够向你展示你以前不曾考虑过的工作的可能性。在列出这些工作的同时，我们也要敬告您：每种职业中都有各种性格类型的成功人士；即使是相同的工作，不同公司的要求也不尽相同，所以除了工作名称外，你还要对贵公司要求的工作内容和公司文化作详细的了解。我们希望在经济快速发展、新型工作不断涌现将来，这份报告上列举的工作种类不是限制了你的选择，而是告诉你，面对新工作的选择或机会时，应该从哪些因素来分析这些工作对你的适合程度。</p>
    <p class="b">适合ESFJ型人的一般职业</p>
    <p class="flex"><span>保健</span><span>医师助理／牙医助理</span><span>言语病理学家</span><span>运动生理学家</span><span>家庭医生</span><span>护士</span><span>牙医</span><span>医用秘书</span><span>验光师</span><span>饮食学家／营养学家</span><span>按摩治疗专家</span><span>验光师／配镜技师</span><span>药剂师／制药技师</span><span>呼吸系统治疗专家</span><span>兽医</span><span>领照实习护士</span><span>家庭健康护理</span><span>最初保健护理医师</span><span>理疗专家</span><span>家庭健康社会工作者</span></p>
    <p>保健领域对ESFJ的吸引力主要是它们能够允许ESFJ以一种直接的方式去帮助他人。无论是内科医生、护士或其他类似的职业，ESFJ都喜欢通过学习技能帮助他们的病人生活得更加容易，减少痛苦和创伤。这些领域要求有较强的实际操作能力和严格遵守运行程序的标准，这正是ESFJ所善长的两件事。这些保健领域的职业还可以使ESFJ与病人及同事建立并维护亲密的关系。</p>
    <p class="flex"><span>教育</span><span>小学教师</span><span>特殊教育工怍者</span><span>儿童照管人员</span><span>家庭经济教师</span><span>运动教练</span><span>双语教学老师</span></p>
    <p>ESFJ的教育方式常常是介入个人感情的，并且善于举例。ESFJ非常喜欢对低年级学生和有特殊需要的学生进行教育，因为他们认为帮助别人掌握一些基本的知识和技能是非常有意义的。直接与小学生们打交道满足了ESFJ天性中的能量和热情。在学校环境中，常常有许多规章和制度，这是ESFJ所喜欢的，他们能在这里找到满足感。很多ESFJ喜欢运动，因此他们愿意教给别人一些运动技巧。他们希望在工作中能有重要的地位。</p>
    <p class="flex"><span>社会服务／咨询</span><span>社会工作者</span><span>社区福利工作者</span><span>专业自愿者</span><span>宗教教育者</span><span>顾问</span><span>律师帮办</span><span>女雇员问题咨询顾问</span><span>儿童福利顾问</span><span>戒毒和戒酒咨询顾问</span><span>社会工作者</span><span>牧师／神父／拉比</span></p>
    <p>ESFJ都是社区活动的有力支持者，他们经常自愿建立并维护市民组织。因此，他们可以从类似的社会工作中找到职业满足感。帮助个人或家庭解决问题，使他们成为社会积极的一部分，这对很多ESFJ可是非常有意义的。他们与人轻松交往的能力和为团体服务的精神使他们容易在社区活动中得到满足感。顾问、宗教教育者、牧师这些职业之所以吸引ESFJ是因为他们喜欢以一种特殊的、深刻的方式去帮助别人。</p>
    <p>ESFJ天性倾向于传统和保守，因此他们喜欢在固定结构的、有传统价值观念的团体中贡献他们的力量。</p>
    <p class="flex"><span>商务</span><span>公关业务经理</span><span>私人银行家</span><span>销售代表(有形商品)</span><span>电话推销员</span><span>办公室经理</span><span>零售商</span><span>接待员</span><span>保险代理(家庭)</span><span>管理顾问：人力资源／培训</span><span>信贷顾问</span><span>经营策划者 </span></p>
    <p>商业世界为ESFJ提供了和许多人打交道的机会以及通过勤奋工作达到自己目标的机会，他们喜欢商业活动中的快节奏和活力，他们喜欢与顾客或客户打交道。当工作中涉及到私人关系时，如房地产或私人银行，很多ESFJ都能获得成功。因为他们善于与别人建立积极向上的关系并且能够维持这种关系。公关和推销需要杰出的人际交流技巧，这正是大多数ESFJ所具有的。这两种职业都需要密切关注细节，把整个计划贯彻到底。ESFJ的组织才能在这些领域得到了充分的发挥。</p>
    <p>最后，销售领域也是ESFJ经常能获得大量成功的地方。因为他们能够利用他们的交际能力、他们的机智、和他们的敏感充分满足顾客的需求。贴刃通常喜欢卖有形商品，他们不喜欢销售诸如概念、思想或复杂系统等无形的商品，他们对零售非常感兴趣，因为这个行业可以使他们与公众接触，使他们的能力不断增强并且由于特定的商品而受益。</p>
    <p class="flex"><span>销售／服务</span><span>飞机服务员</span><span>顾客服务代表</span><span>殡仪馆管理人员</span><span>高级理发师／美容师</span><span>旅店老板／老板娘</span><span>酒席承办者</span><span>资金筹集人</span><span>旅行推销员</span><span>环境旅游专家</span><span>不动产代理／经纪人</span><span>翻译</span><span>系谱学家</span><span>家庭保健用品销售</span><span>体育设备／商品销售</span><span>营销经理：无线电／电视／广播电缆工业</span></p>
    <p>ESFJ经常喜欢从事服务行业，主要是因为这个行业可以使他们直接与其他人打交道，使他们为他人提供服务，帮助他人减轻痛苦或者生活得更好。顾客服务代表的工作为他们做到这些提供了机会。</p>
    <p>ESFJ在困难的时候是坚定不移、值得依靠的，他们经常在危机的时候站出来承担具体事务。殡仪馆管理人员在工作中必须对他人的需求表现出极大的敏感和关心。</p>
    <p>作为飞机服务员，ESFJ喜欢工作对象的变化、旅行和与人打交道。大多数ESFJ都是热情而殷勤的，他们在饭馆或承办事务中是出色的老板或老板娘。ESFJ喜欢销售，并且擅长销售，特别是对于有形的、具体的商品。通过销售，他们可以与顾客建立长期的良好关系。</p>
    <p class="flex"><span>职员工作</span><span>秘书</span><span>接待员</span><span>办公室机器操作员</span><span>簿记员</span><span>打字员</span></p>
    <p>大多数ESFJ除了具有文职工作所需要的与人交往的技巧以外，他们还有熟练、灵巧的操作能力。一旦他们学会了—一项技术，他们就永远也不会忘记。ESFJ可以没有任何差错地完成例行的工作，他们还具有作为一名簿记员所需要的处理数字的能力。这类工作最吸引ESFJ的地方是他们可以感到自己是集体的一员，他们，可以与同事们并肩工作。孤立对ESFJ来说是令人沮丧的。</p>`,
    jy: `<p>现在你对自己的人格类型和动力已经有了一个比较清楚的了解，但这还不够。“如何通过这些信息使你在这份工作上取得更大的成功”，这是关键所在。运用你的能力非常容易。</p>
    <p class="b">你成功的秘诀在于：</p>
    <p>*办事情时要不紧不慢；</p>
    <p>*考虑眼前并不存在的可能性；</p>
    <p>*不要随便地把事情视为是对自己的人身攻击。</p>
    <p>个人发展建议是我们咨询师多年测评职业咨询和职业生涯规划的心得体会和经验总结，我们意识到以下的建议中有很多是难以完全照办的，但只要你花时间认真思考，一定会对你有极大的帮助和改变：</p>
    <p class="b">发展建议：</p>
    <p>*需要学会如何看待和管理冲突；</p>
    <p>*需要努力倾听其它人的愿望和要求；</p>
    <p>*需要考虑其决策的逻辑与全局影响。</p>
    `,
  },
  ENFJ: {
    tag: '<h1>ENFJ (外倾、直觉、情感和判断) </h1><h2>公共关系专家</h2>',
    subscribe: `
    <p>1.热忱、易感应及负责任的--具能鼓励他人的领导风格。</p>
    <p>2.对别人所想或希求会表达真正关切且切实用心去处理。</p>
    <p>3.能怡然且技巧性地带领团体讨论或演示文稿提案。</p>
    <p>4.爱交际、受欢迎及富同情心。</p>
    <p>5.对称许及批评很在意。</p>
    <p>6.喜欢带引别人且能使别人或团体发挥潜能。 </p>`,
    ms: `<p>ENFJ型的人热爱人类。他们认为人和感情关系是最重要的，而且他们很自然地去关心别人。他们以热情的态度对待生命，感受与个人相关的所有事物。由于他们很理想化，按照自己的价值观生活，因此ENFJ型的人对于他们所尊重和敬佩的人、事业和机构非常忠诚。他们精力充沛、满腔热情、富有责任感、勤勤恳恳、锲而不舍。</p>
    <p>ENFJ型的人具有自我批评的自然倾向。然而，因为他们对他人的情感具有责任心，所以ENFJ型的人很少在公共场合批评人。他们敏锐地意识到什么是(或不是)合适的行为。他们彬彬有礼、富有魅力、讨人喜欢、深谙社会。ENFJ型的人具有平和的性格与忍耐力，他们长于外交，擅长在自己的周围激发幽默感。他们是天然的领导者，受人欢迎而有魅力，他们愿意成为出色的传播工作者，常常有利用自己口头表达的天赋。</p>
    <p>ENFJ型的人在自己对一种情况的感受的基础上做决定，而不是这种情况事实上如何。他们对显而易见的事物之外的可能性，以及这些可能性以怎样的方式影响他人感兴趣。</p>
    <p>ENFJ型的人天生具有条理性，他们喜欢一种有安排的世界，并且希望别人也是如此。即使其他人：正在做决定，他们还是喜欢把问题解决了。</p>
    <p>ENFJ型的人显得富有同情心和理解力，愿意培养和支持他人。他们能很好地理解别人，有责任感和关心他人。由于他们是理想主义者，因此他们通常看到别人身上的优点。</p>`,
    md: `<p>ENFJ型的人具有如此的同情心与关切之心，以至于对一些问题和他人的情感涉入过多。有时他们选择的事业并不值得他们倾注所有的时间和精力。当一些事情完成得不是很好时，他们会觉得不知所措、失望和理想破灭。这会使他们退缩，感到自己不被欣赏。ENFJ型的人需要学会接受自己和他们所关心的人的缺点。他们还需要了解怎样“挑选他们的战场”和怎样保持现实的期望。</p>
    <p>由于他们对和睦友好强烈的渴望，因此ENFJ型的人能够忽视自己的需求和真实的问题。因为回避冲突，所以他们有的维持，一种缺乏诚实和平等的人际关系。ENFJ型的人非常在意别人的情感，以至于当情况涉及批评或伤害感情时，他们能视而不见重要的事实。学会如何接受和处理作为人际关系中必不可少的冲突，对于ENFJ型的。人来说是重要的。</p>
    <p>因为他们满怀热情，急切地开始下一次挑战，所以ENFJ型的人有时会做出错误的臆断或过于急促地做决定，而没有收集到所有重要的事实。他们需要放慢速度，更仔细地注意计划的细节。直到等到知晓所有的信息，他们才能避免犯错误。</p>
    <p>ENFJ型的人对于情感因素关注的程度到了对他们行为的必然结果视而不见的地步。努力关注涉及他们决定的事实而不仅仅是人，这会很有帮助的。ENFJ型的人对于赞扬有着很好的反应，但却很容易被批评所伤害，这些批评使他们很易怒。他们当面受到甚至是最无恶意或动机良好的批评时，反应是激动、感到受伤害或生气。ENFJ型的人的确应该停下来．退后一步，在做出反应前努力客观地了解情况。少一些敏感能够使ENFJ型的人听到一些包括建设性批评的、重要而有帮助的信息。</p>
    <p>ENFJ型的人是如此的理想化，以至于他们习惯于以他们对事物的希望是怎样，便怎样去看待事物。他们易受理想化的人际关系的影响，易于忽视与他们认为相矛盾的事实。</p>
    <p>ENFJ型的人没有学会面对自己无法认同的事实，结果他们忽视了自己的问题，而不是去寻找解决的方法。一般来说ENFJ型的人需要警觉。</p>`,
    qn: `<p>外向与内向是指我们心理能量的作用方向，判断与知觉是我们做事的两种方式。心理学称之为“态度”。感觉与直觉是我们获取信息的方式，思考和情感是做决定的方式，心理学称之为“功能”，功能是人格理论的精髓、核心。</p>
    <p>每一个人在都会用到四种功能，但对功能应用的喜好程度不一样。等级中第一位的称为“主导功能”，第二位的称为“辅助功能”。每个人都需要运用到这两个过程。</p> 
    <p>有效地使用你的类型： (ENFJ)</p>
    <p>ENFJ型的人主导功能是情感（关注情感），辅助功能是直觉。他们倾向于：体谅他人感受、了解他人的需要、喜欢和谐的人际关系、易表露情感、喜去说服他人。探求隐含的原因、可能性或事物的整体。ENFJ型的人比较少地使用他们的第三、第四功能--感觉和思维。即使在需要这些功能的场合，他们仍然无法有效使用。</p>
    <p>作为一个ENFJ型的人：如果你过多地关注情感，你可能会表现得不合乎逻辑、不够客观、没有组织系统的思考、不具批判精神全盘接受、感情用事。如果你过多地使用直觉功能，你可能会表现得不注重细节、不注意实际、不耐沉闷、不合逻辑、把握不住现在、骤下断语。</p>`,
    jjwt: `<p>每个人都会用自己的方式进行决策，决策通常来自于你的主导功能，并且完全忽视你的第四功能。你的功能运用如下：</p>
    <p>主导功能情感——辅助功能直觉——第三功能感觉——第四功能思维</p>
    <p>人格中的每一种功能对解决问题都具有关键和重要作用，忽视任何一个都将导致做出有缺陷的决定，理想化的问题解决功能流程如下：</p>
    <p>一个好的决策通常来自于四个功能的合理运用。当你尝试去解决问题时，你可以利用上表指导这个过程，但在整个的执行过程是非常困难的，因为每个人都不愿意用他不喜欢的功能去解决问题。就算你有意识地努力运用所有这些功能，你也不可能会在“真实生活”中以这种顺序运用它们。关键是要利用它们提醒自己，完善决策，就算你不按这种方式行事。</p>
    <p>作中选择符合你的主导功能和辅助功能的岗位是非常重要的。第三功能要经过发展锻炼才能合适运用，第四功能可能永远不会在你的职业生涯中起到关键作用，因此你应当避免那种要求长期使用它的工作。</p>`,
    ys: `<p>对于不同的人格类型和不同的动力等极而言，没有“好”与“坏”之分，每一个人都是一个独一无二的个体，都有其特别的优势和劣势，但问题的关键在于如何认识这些优势和劣势,完善自己。我们对于成功的建议是：“取已之长，补已之短”。学会了这一点将会影响到你的成败及你对工作的喜好。</p>
    <p>你在工作中的优势：</p>
    <p>优秀的交流及表达能力</p>
    <p>天生的领导才能及凝聚力 </p>
    <p>热情奔放，及有较强的寻求合作的能力</p>
    <p>坚决果断，有组织能力</p>
    <p>渴望推陈出新</p>
    <p>与别人感情交融，能遇见别人的需要，能真诚地关怀别人</p>
    <p>兴趣广泛，头脑灵活</p>
    <p>能统观全局，能洞察行为与意识之间的联系</p>
    <p>鞭策自己做出成绩，达到目的</p>
    <p>对自己所信仰的事业尽职尽责</p>`,
    ls: `<p>下面列出了你工作中可能存在的缺点，这些缺点有的比较明显，有的并不明显或你没有意识到，目的是为了让你“注意”到它们，并考虑产生的原因。缺点有些是天生的，而有些是长时间形成的，因此你不可能在一两天内改变，而是去提醒、思考。注意：其实知道存在的问题就是改变提高中很重要的一步，你会发现你正在慢慢发生变化。</p>
    <p>您工作中的劣势：</p>
    <p>不愿干预自己价值观相冲突的事 </p>            
    <p>容易把人际关系理想化</p>
    <p>很难在竞争强、气氛紧张的环境下工作 </p>       
    <p>对那些没效率、或死脑筋的人没有耐心</p>
    <p>逃避矛盾冲突，易于疏忽不愉快的事 </p>         
    <p>在没有收集足够证据前，易于仓促做决定</p>
    <p>不愿训诫下属      </p> 
    <p>易于因轻率犯错误    </p>   
    <p>易于满足小范围管理，决不放弃控制权</p>`,
    gwtz: `<p>研究发现：职业满足会使你的工作主动性更强，积极性更高，更愿意去工作。以下不是简单的告诉你什么样的工作适合你，而是细化的帮你分析工作中的哪些特质对你重要，你还需要从中选出你认为最重要的，因为不同经历的人对特质的重要程度要求是不同的。每个岗位的工作内容都在随企业的发展而发展，不是一成不变的，有时候岗位的发展方向需要我们自己去争取。所以找到适合的工作不如找到适合自己发展的岗位更直接。这些特质可以帮助明确如何主动的发展或争取你岗位中的那些特质。</p>
    <p>下面的条目从各个侧面上认证了您怎样才能受到真正的职业满足，看完这些条目之后，我们建议您根据它们对您的重要程序进行排序，排序的时候，回想一下您过去的学习、工作经历以及当前学习环境和工作感受，并思考：“哪一些是令你感到特别满意，有哪些令你极其不高兴”。试着寻找贯穿这些经历的主题。</p>
    p>你的岗位特质：</p>
    <p>作为ENFJ型人，职业满足意味着你做的这些工作：</p>
    <p>1、能让我与我的同事、客户、主顾建立并维持亲密的、互助的人际关系。</p>
    <p>2、允许我对于我所负责的项目中出现的问题自己创造性地解决，同时我的努力能让我有所回报。</p>
    <p>3、能让我看到我的工作有很好的前景，我所作的贡献得到别人的赏识，同时我自身及我的事业能得到发展，我的进步得到同时的鼓励。</p>
    <p>4、能让我成为另一群富有创造精神的人的一员，同时我还很充实，且有成就感。</p>
    <p>5、允许我有足够的时间探求解决问题的创造性方法，然后与支持我，关心我的人分享。</p>
    <p>6、我的工作环境是积极且富有挑战性的，而且在工作中我有权同时操纵多个项目。</p>
    <p>7、在工作中，我能充分发挥我的组织和决策能力，对我自己负责的项目有自主权，并对其承担一定责任。</p>
    <p>8、我的工作变化性很强，且允许我有时间对它有条不紊地进行好规划。</p>
    <p>9、我的工作环境轻松，人们之间没有冲突，也没有相互猜忌。</p>
    <p>10、让我有机会接触新观念，并允许我探究一些新方法，能让别人生活得更美好。</p>`,
    zy: `<p>也许你会觉得下面的工作种类繁杂，但仔细分析你就会发现，这些工作各自的特色正是你对工作的要求，我们会在下面列出各类工作的特质。当然，这不可能是一个完美的适合你的工作的综合，但是，却能够向你展示你以前不曾考虑过的工作的可能性。在列出这些工作的同时，我们也要敬告您：每种职业中都有各种性格类型的成功人士；即使是相同的工作，不同公司的要求也不尽相同，所以除了工作名称外，你还要对贵公司要求的工作内容和公司文化作详细的了解。我们希望在经济快速发展、新型工作不断涌现将来，这份报告上列举的工作种类不是限制了你的选择，而是告诉你，面对新工作的选择或机会时，应该从哪些因素来分析这些工作对你的适合程度。</p>
    <p>适合ENFJ型人的一般职业，由于ENFJ型人种种倾向的组合，他们天然适于从事广为不同的各种职业。列出ENFJ型人通常会从事的职业，重要的是要指出各种职业中都有属于各种类格的成功者。下面列出的是可能使ENFJ型人格外感到满意的职业，而且可以发现其中的一些原因。这绝不是一个包罗万象的列表，但是包括了过去你可能没有考虑过的情况。尽管所有这些职业都具有使你获得满足感的潜力，但我们预测未来对一些职业的需要会多于其他职业。基于我们的研究，我们预测，进入2000年以后对于下面标注出的职业的需求将大大超过平均职业需求水平。</p>
    <p class="flex"><span>交流性职业</span><span>广告销售主管</span><span>公共关系专家</span><span>对外交流董事</span><span>作家／新闻工作者</span><span>娱乐表演者／艺术家</span><span>资金筹备人</span><span>招聘人员</span><span>娱乐业导演</span><span>电视制片人</span><span>新闻广播员</span><span>政客</span><span>信息制图设计人</span><span>营销经理(电台、电视、有线播放行业)</span><span>编辑(杂志)</span></p>
    <p>ENFJ型人是熟练的交流者。他们善于理解他人，使他人高兴，因此他们常常具有足够的老练和外交手段。他们有时候更喜欢口语而不是书面语言，但许多ENPJ型人也是很好的作家。他们乐于通过接触和会见人们来了解事情或问题所包含的有关个人的方面，收集种种信息。广告、公共关系和融资领域的工作常常是令人满意的，尤其当一个ENFJ型人推崇其中涉及的产品、服务或目标，或者当环境不是太具竞争性或充满冲突的时候。</p>
    <p>ENFJ型人可以迅速地与顾客、客户以及同事建立关系，能够成为具有说服力和效率的代理人、制作人、招聘人员和政界人士。他们是天生的具有超凡感召魅力的领导者，以促进大大小小群体之间的交流为乐。</p>
    <p class="flex"><span>咨询顾问</span><span>心理医生</span><span>职业顾问</span><span>牧师／教士</span><span>翻译／口译</span><span>雇员帮助顾问</span><span>便利促进人</span><span>私人顾问</span><span>公司公共活动顾问</span><span>酒精和毒品戒禁顾问</span></p>
    <p>许多ENFJ型人从帮助他人通过自我了解而获得幸福和满足的职业中得到自己的满足感。他们乐于帮助客户了解个人的问题，克服困难。ENFJ型人通常热情、富于同情心，是有影响力的治疗医生。他们常喜欢从事牧师的工作，这样可以与他人分享自己的价值观念，帮助自己和他人发挥全部潜力。他们可以很容易地看到可行的选择或解决办法，帮助他们的客户也了解到同样的情况。</p>
    <p class="flex"><span>教育／社会服务职业</span><span>教师：卫生健康／艺术／戏剧／英文</span><span>学生的系主任</span><span>大学教授：人文学科</span><span>儿童福利工作者</span><span>图书馆管理员</span><span>社会工作者</span><span>特殊教育教师</span><span>双语种教育老师</span><span>老年人社会工作者</span><span>住宅安居指导</span><span>非盈利性组织的指导者</span><span>早期教育教师</span></p>
    <p>ENFJ型人常常从事教育，因为这一职业可以给予他们直接与人们打交道从而帮助他人成长和发展的机会。他们喜欢教授那些能够着重于事物的意义并通过解释与表达来讲授的科目。他们曙要和谐而合作的工作环境，能够容纳所有的看法，鼓励人们公开分享各自的看法和情感。</p>
    <p>社会服务性机构也常常吸引ENFJ型人，因为它们提供了以改善自己与他人的生活质量为工作目标的机会  他们喜欢做领导者，尽可能多地驾驭他们的工作，喜欢看到他们的努力带来积极的结果。</p>
    <p class="flex"><span>健康护理职业</span><span>全面健康医生(可替代药物)</span><span>饮食学家/营养学家</span><span>语言障碍病理学家/听觉病理学家</span><span>职业治疗医生</span></p>
    <p>在迅速扩大的保健行业，这些职业很好地利用了ENFJ型人的观察、诊断以及处理对象整体的能力，ENFJ型人通常对心理学的、感情的以及精神的疾病原因很感兴趣，常常着迷于新型以及替代性的各种治疗方法。他们喜欢职业疗法以及语言病理学所具有的创造性地解决问题的因素。</p>
    <p class="flex"><span>商业/咨询职业</span><span>开发人力资源的培训员</span><span>推销培训员</span><span>招聘人员</span><span>旅游代理人</span><span>小型企业经理</span><span>项目设计人</span><span>销售经理</span><span>调职顾问</span><span>公司/工作小组的培训员</span><span>生态旅行专家</span><span>管理顾问</span></p>
    <p>咨询性行业中许多不同的工作为ENFJ型人提供了职业满足感，这样的工作在与他人保持密切联系的同时也能使人保持独立性。ENFJ型人是出色的表达者和培训员，尤其当面对个人或团体开展工作而帮助他们提高效率的时候。他们富于创造力且精力充沛，是新方案和新服务的设计者，但这仅仅是在这些方案于他人有益的情况下。他们喜欢在小一些的公司或机构里担任经理职务，这样他们可以具有积极主动的影响力，而以寻求新工作方法过程中出现的种种变化和机会为乐，同时他们仍然可以拥有一定的控制权力。</p>`,
    jy: `<p>现在你对自己的人格类型和动力已经有了一个比较清楚的了解，但这还不够。“如何通过这些信息使你在这份工作上取得更大的成功”，这是关键所在。运用你的能力非常容易。</p>
    <p class="b">你成功的秘诀在于：</p>
    <p>放慢你匆忙的脚步；适当放弃某些控制权；客观对待周围的一切。</p>
    <p>个人发展建议是我们咨询师多年测评职业咨询和职业生涯规划的心得体会和经验总结，我们意识到以下的建议中有很多是难以完全照办的，但只要你花时间认真思考，一定会对你有极大的帮助和改变：</p>
    <p> class="b"发展建议：</p>
    <p>需要防止盲目的信任和赞同；  </p>       
    <p>需要有成效地管理冲突；</p>
    <p>需要像关注人一样关注任务的细节； </p>    
    <p>需要仔细倾听外界的反馈信息。</p>`,
  },
  ENTJ: {
    tag: '<h1>ENTJ(外倾、直觉、思维和判断)</h1><h2>一切顺利，因为我一手掌握</h2>',
    subscribe:
      '<p>1.坦诚、具决策力的活动领导者。</p><p>2.长于发展与实施广泛的系统以解决组织的问题。</p><p>3.专精于具内涵与智能的谈话如对公众演讲。</p><p>4.乐于经常吸收新知且能广开信息管道。</p><p>5.易生过度自信，会强于表达自已创见。</p><p>6.喜于长程策划及目标设定 </p>',
    ms: `<p>ENTJ型的人是伟大的领导者和决策人。他们能轻易地看出事物具有的可能性，很高兴指导别人，使他们的想象成为现实。他们是头脑灵活的思想家和伟大的长远规划者。</p>
    <p>因为ENTJ型的人很有条理和分析能力，所以他们通常对要求推理和才智的任何事情都很擅长。为了在完成工作中称职，他们通常会很自然地看出所处情况中可能存在的缺陷，并且立刻知道如何改进。他们力求精通整个体系，而不是简单地把它们作为现存的接受而已。</p>
    <p>ENTJ型的人乐于完成一些需要解决的复杂问题，他们大胆地力求掌握使他们感兴趣的任何事情。ENTJ型的人把事实看得高于一切，只有通过逻辑的推理才会确信。</p>
    <p>ENTJ型的人渴望不断增加自己的知识基础，他们系统地计划和研究新情况。他们乐于钻研复杂的理论性问题，力求精通任何他们认为有趣的事物。他们对于行为的未来结果更感兴趣，而不是事物现存的状况。</p>
    <p>ENTJ型的人是热心而真诚的天生的领导者。他们往往能够控制他们所处的任何环境。因为他们具有预见能力，并且向别人传播他们的观点，所以他们是出色的群众组织者，他们往往按照一套相当严格的规律生活，并且希望别人也是如此。因此他们往往具有挑战性，同样艰难地推动自我和他人前进。</p>`,
    md: `<p>由于ENTJ型的人渴望向下一个目标挑战，或向更大的目标前进，所以有时他们草率地做决定。偶尔放慢速度会有机会收集所有相关的资料，考虑行为的实际后果和有关个人的后果。他们一旦做出决定就立刻行动，而不是停下来重新检查细节和形势的现实情况。</p>
    <p>因为ENTJ型的人按照很条理的方式生活．所以当他们无法理解别人的需求和情感的理由时，就会对这些情感显得粗鲁、迟钝、缺乏耐心和麻木。ENTJ型的人好争论，难以接近，经常不欢迎别人清楚明白的建议，他们与其等着接受必然的批评，不如去聆听周围人们的情况，并对周围人的贡献表示感激。事实上作为一条原则，ENTJ型的人应该有意识地努力，在确信自己的想法之前，停下来去聆听别人的意见，从而避免武断专横的行为。</p>
    <p>ENTJ型的人承认忽略或不表达自己的情感时，发现自己在感情上反应激烈。如果他们发现某个人，尤其是他们尊敬的人，对他们的能力表示疑问时，更容易感情激动。他们对貌似无足轻重的情况会有粗暴的反应，这种爆发会伤害接近他们的事物。</p>
    <p>当ENTJ型的人给自己时间去考虑和理解自己真正的感觉如何时，会更加快乐和引人注目。与其任情感左右自己的人格类型，不如给感情一个积极的发泄途径，这样才会真正地充分控制感情，从而处于一种愉快和力求达到的境界。</p>
    <p>令人惊讶的是，ENTJ型的人实际上不如他们自信的风度所表现的那样老练和有能力。允许自己从别人那里得到合理而有价值的帮助，他们将会增加个人的能力和成功的机率。</p>`,
    qn: `<p>外向与内向是指我们心理能量的作用方向，判断与知觉是我们做事的两种方式。心理学称之为“态度”。感觉与直觉是我们获取信息的方式，思考和情感是做决定的方式，心理学称之为“功能”，功能是人格理论的精髓、核心。</p>
    <p>每一个人在都会用到四种功能，但对功能应用的喜好程度不一样。等级中第一位的称为“主导功能”，第二位的称为“辅助功能”。每个人都需要运用到这两个过程。</p>
    <p>有效地使用你的类型：(ENTJ)</p>
    <p>ENTJ型的人主导功能是思考（喜欢事实），辅助功能是直觉。他们倾向于：对事实和经验做出符合逻辑结论。探求隐含的原因、可能性或事物的整体。</p>
    <p>ENTJ型的人比较少地使用他们的第三、第四功能--感觉和情感。即使在需要这些功能的场合，他们仍然无法有效使用。</p>
    <p>作为一个ENTJ型的人：如果你过多的使用思考功能，你可能在需要的时间忘记去称赞他人，或忽视你决策对其它人的影响。如果你过多地关注直觉，你可能错过事物的相关事实、细节或以前的经验。</p>`,
    jjwt: `<p>每个人都会用自己的方式进行决策，决策通常来自于你的主导功能，并且完全忽视你的第四功能。你的功能运用如下：</p>
    <p>主导功能思考——辅助功能直觉——第三功能感觉——第四功能情感</p>
    <p>人格中的每一种功能对解决问题都具有关键和重要作用，忽视任何一个都将导致做出有缺陷的决定，理想化的问题解决功能流程如下：</p>
    <p>一个好的决策通常来自于四个功能的合理运用。当你尝试去解决问题时，你可以利用上表指导这个过程，但在整个的执行过程是非常困难的，因为每个人都不愿意用他不喜欢的功能去解决问题。就算你有意识地努力运用所有这些功能，你也不可能会在“真实生活”中以这种顺序运用它们。关键是要利用它们提醒自己，完善决策，就算你不按这种方式行事。</p>
    <p>工作中选择符合你的主导功能和辅助功能的岗位是非常重要的。第三功能要经过发展锻炼才能合适运用，第四功能可能永远不会在你的职业生涯中起到关键作用，因此你应当避免那种要求长期使用它的工作。</p>`,
    ys: `<p>对于不同的人格类型和不同的动力等极而言，没有“好”与“坏”之分，每一个人都是一个独一无二的个体，都有其特别的优势和劣势，但问题的关键在于如何认识这些优势和劣势,完善自己。我们对于成功的建议是：“取已之长，补已之短”。学会了这一点将会影响到你的成败及你对工作的喜好。</p>
    <p>你在工作中的优势：</p>
    <p>*能看到事情的可能发展情况及潜在含义</p>
    <p>*有创造性解决问题的天资，能客观地审查问题</p>
    <p>*有追求成功的干劲和雄心</p>
    <p>*自信且有天生的领导才能</p>
    <p>*对于在工作中胜任和胜出有强烈动机</p>
    <p>*标准高，工作原则强</p>
    <p>*能创造方法体系和模式来达到你的目标</p>
    <p>*敢于采取大胆行动，又不达目的不罢休的势头</p>
    <p>*能逻辑地、分析地做出决定</p>
    <p>*擅长于从事技术性工作，学习新东西时接受能力强</p>`,
    ls: `<p>下面列出了你工作中可能存在的缺点，这些缺点有的比较明显，有的并不明显或你没有意识到，目的是为了让你“注意”到它们，并考虑产生的原因。缺点有些是天生的，而有些是长时间形成的，因此你不可能在一两天内改变，而是去提醒、思考。注意：其实知道存在的问题就是改变提高中很重要的一步，你会发现你正在慢慢发生变化。</p>
    <p>您工作中的劣势：</p>
    <p>*对那些反映不如你敏捷的人缺乏耐心</p>
    <p>*唐突、不机智、缺乏交际手段</p>
    <p>*易于仓促作决定</p>
    <p>*对一些世俗小事没有兴趣</p>
    <p>*有相要去改变那些根本没有必要改善的事物的倾向</p>
    <p>*不愿花时间适当地欣赏、夸奖同事或别人</p>
    <p>*对那些既定问题不愿再审查</p>
    <p>*易于过分强调工作，从而顺还了家庭的和谐</p>`,
    gwtz: `<p>研究发现：职业满足会使你的工作主动性更强，积极性更高，更愿意去工作。以下不是简单的告诉你什么样的工作适合你，而是细化的帮你分析工作中的哪些特质对你重要，你还需要从中选出你认为最重要的，因为不同经历的人对特质的重要程度要求是不同的。每个岗位的工作内容都在随企业的发展而发展，不是一成不变的，有时候岗位的发展方向需要我们自己去争取。所以找到适合的工作不如找到适合自己发展的岗位更直接。这些特质可以帮助明确如何主动的发展或争取岗位中的那些特质。</p>
    <p>下面的条目从各个侧面上认证了您怎样才能受到真正的职业满足，看完这些条目之后，我们建议您根据它们对您的重要程序进行排序，排序的时候，回想一下您过去的学习、工作经历以及当前学习环境和工作感受，并思考：“哪一些是令你感到特别满意，有哪些令你极其不高兴”。试着寻找贯穿这些经历的主题。</p>
    <p>你的岗位特质：</p>
    <p>1、让我领导、控制、组织以及完善一个机构的运行体制，以便它有效地运行并达到计划目的。</p>
    <p>2、允许我从事长远策略计划，创造性地解决问题，以及对各种不同问题涉及处富有创意且符合逻辑的解决方法。</p>
    <p>3、我的工作是在一个组织性强的环境中完成，而且在那里我和我的同事要在一些明确的指导方针下工作。</p>
    <p>4、我的工作观念鼓励并持机我的求知欲，同时允许我从事复杂且通常比较棘手的问题。</p>
    <p>5、给我机会遇到并与各种不同的人交往，他们必须是由能力、有趣且实力强大的人。</p>
    <p>6、给我在机构内不断前进、提高并展示自己才能的机会。</p>
    <p>7、我的工作有刺激性、挑战性及竞争性，在工作中我市公众注意的焦点，同时我的成就要看得见、被认同，且能够得到一定的回报。</p>
    <p>8、允许我与别人的有才智、有创造性、有抱负以及有理想的人工作，而且他们的能力是我所佩服的。</p>
    <p>9、让我设立一定目标，并为实现它们而奋斗，允许我运用自己的组织技能来使我自己及别人集中精力于更大的目标，而同时我自己所有的目标都能及时、有效地实现。</p>
    <p>10、能让我以我的政策来管理并监督别人，运用逻辑、客观的标准及利用每个人的才能，但不必处理人际冲突。</p>`,
    zy: `<p>也许你会觉得下面的工作种类繁杂，但仔细分析你就会发现，这些工作各自的特色正是你对工作的要求，我们会在下面列出各类工作的特质。当然，这不可能是一个完美的适合你的工作的综合，但是，却能够向你展示你以前不曾考虑过的工作的可能性。在列出这些工作的同时，我们也要敬告您：每种职业中都有各种性格类型的成功人士；即使是相同的工作，不同公司的要求也不尽相同，所以除了工作名称外，你还要对贵公司要求的工作内容和公司文化作详细的了解。我们希望在经济快速发展、新型工作不断涌现将来，这份报告上列举的工作种类不是限制了你的选择，而是告诉你，面对新工作的选择或机会时，应该从哪些因素来分析这些工作对你的适合程度。</p>
    <p  class="b">适合ENTJ型人的一般职业</p>
    <p class="flex"><span>商业</span><span>经理</span><span>高级主管</span><span>办公室经理</span><span>行政管理人</span><span>人事经理</span><span>销售经理</span><span>营销经理</span><span>网络一体化专家(电讯)</span><span>技术培训人员</span><span>信息服务——新业务开发人</span><span>后勤顾问(生产)</span><span>广告业务经理</span><span>管理顾问：电脑／信息服务，营销，机构重组</span><span>营销经理：广播／电视／有线播放行业</span><span>媒体策划／买主</span><span>国际销售和营销</span><span>特许权所有人</span><span>销售经理：制药业</span><span>管理人：健康服务</span></p>
    <p>ENTJ型人往往喜欢在商业领域工作。他们喜欢处于权力、控制和领导位置。作为商业经理，他们能够运用远景思维能力来制定随时适应变化的计划，规划出实现目标的最佳过程。ENTJ型人使用直接管理方式，能够做出坚决而尚属公平的决定，为雇员制定工作政策。他们喜欢周围的人独立、注重结果，不需太多的监督和干涉就能完成工作。因ENTJ型人有能力影响他人而能轻松地与人交往和联络，他们通常会晋升为机构的最高领导。</p>
    <p class="flex"><span>金融</span><span>个人财务设计人</span><span>经济分析家</span><span>抵押经纪人</span><span>信用调查员</span><span>股票经纪人</span><span>投资银行家</span><span>公司财务律师</span><span>国际银行家</span><span>经济学家</span></p>
    <p>ENTJ型人通常在金融领域做得很出色。他们喜欢赚钱，也喜欢和别人的钱打交道。他们喜欢这一领域的竞争性，能够轻易地快速控制局面。这些职业使ENTJ型人能够发挥预测趋势、为自己和客户设计简捷途径来充分把握机会的能力。他们不喜欢细节化、按部就班的工作，但他们可以把这些事情交给一个合格的助手去做。</p>
    <p class="flex"><span>咨询／培训</span><span>商业顾问</span><span>管理顾问</span><span>教育顾问</span><span>项目设计人</span><span>管理培训人</span><span>就业开发专家</span><span>劳工关系</span><span>电信安全顾问</span><span>公司工作小组培训人</span></p>
    <p>咨询行业所具有的变化和独立性对ENTJ型人很有吸引力：近年来，这一领域发展迅速，使ENTJ型人能有机会满足他们所具有的企业家精神，使他们得以与各种各样的商业背景下的各种人打交道，而且能得到与投入的工作相符合的回报。ENTJ型人往往能够出色地进行商业或管理顾问工作，成为能够激励他人的出色的培训员。他们常常能够通过创造性的设计和生动灵活的学习班来开创有组织而且充满挑战的环境。他们总是喜欢承担新的工作项目，乐于教导雄心勃勃的人们如何提高自己的能力。</p>
    <p class="flex"><span>专业性职业</span><span>律师</span><span>法官</span><span>心理学家</span><span>科学／社会科学教师</span><span>化学工程师</span><span>知识产权律师</span><span>生物医学工程师</span><span>精神病学家</span><span>环境工程师</span></p>
    <p>这些专业提供了ENTJ型人在职业生涯中寻求的地位和影响力。ENTJ型人通常喜欢法律领域，能够成为成功的从业律师和法官。心理学和精神病学的智力挑战吸引许多ENTJ型人，复杂的化学工程行业和日益发展的生物医学工程领域也有同样的吸引力。教育领域中，ENTJ型人通常愿意教授高年级学生，尤其中等教育，成人教育以及高等教育。他们喜欢将他们的知识运用于周围的世界，常常从事那种可以让他们把教育责任扩展到其它领域的职业——如政治或政治顾问。</p>`,
    jy: `<p>现在你对自己的人格类型和动力已经有了一个比较清楚的了解，但这还不够。“如何通过这些信息使你在这份工作上取得更大的成功”，这是关键所在。运用你的能力非常容易。</p>
    <p class="b">你成功的秘诀在于：</p>
    <p>*放慢你行动的节拍；</p>
    <p>*注重细节；</p>
    <p>*体谅他人的需要。</p>
    <p>个人发展建议是我们咨询师多年测评职业咨询和职业生涯规划的心得体会和经验总结，我们意识到以下的建议中有很多是难以完全照办的，但只要你花时间认真思考，一定会对你有极大的帮助和改变：</p>
    <p class="b">发展建议：</p>
    <p>*需要区分人的因素并欣赏他人的贡献；</p>
    <p>*需要在埋头苦干之前，仔细检查可资利用的、实际的人与情境资源；</p>
    <p>*在决策前，需要花时间三思问题的所有方面；</p>
    <p>*需要学会认同和看重感情。</p>`,
  },
}
