import axios from 'axios'
import { getUserId } from './storage'

const instance = axios.create({
  baseURL: process.env.NODE_ENV === 'development' ? '' : '/api',
})

instance.interceptors.request.use(
  (config: any) => {
    const uuid = getUserId()
    config.headers['Content-Type'] = 'application/json'

    if (config.method.toLocaleLowerCase() === 'get') {
      config.params = config.params || {}
      config.params.userId = uuid
    } else {
      config.data.userId = uuid
    }

    return config
  },
  (error) => {
    return Promise.reject(error)
  },
)

instance.interceptors.response.use(
  (response) => {
    const data = response.data || {}
    if (data.code === '00000000') {
      return data
    }

    // reject错误处理
    return Promise.reject(new Error(data.desc))
  },
  (error) => {
    return Promise.reject(error)
  },
)

export default instance
