import { Search } from 'auto-libs'
import { Button, Layout, Toast } from 'auto-ui'
import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import FeedBack from '../../components/feedback'
import http from '../../utils/http'
import { getUserId, setUserId } from '../../utils/storage'
import MBTI from './mbti.jpeg'
import './style.scss'

const PageIndex = () => {
  const navigateHook = useNavigate()
  const channel = Search.get('channel') || ''
  const [type, setType] = useState<'full' | 'fast'>('full')
  const [orderNo, setOrderNo] = useState('')

  const onSubmit = async () => {
    try {
      const result: any = await http.request({
        url: '/order/create',
        method: 'POST',
        data: {
          productType: '3', // 1:姓名匹对，2：智商测试 ,3: MBTI
          channel,
        },
      })

      const { code, body } = result
      if (code !== '00000000') {
        Toast('请求失败，请再试')
        return
      }
      setOrderNo(body.orderId)
      navigateHook('/qa', { state: { orderNo: body.orderId, type } })
    } catch (e) {
      Toast('请求失败，请再试')
    }
  }

  const fetchUserId = async () => {
    try {
      const result: any = await http.request({
        url: '/user/getNewUserId',
        method: 'POST',
        data: {},
      })

      const { code, body } = result

      if (code !== '00000000') {
        return
      }

      const { userId } = body

      setUserId(userId)
      statistics(userId)
    } catch (error) {}
  }

  const statistics = async (userId: string) => {
    const params: any = { userId }

    if (channel) {
      params.channel = channel
    }

    await http.request({
      url: '/statistics/submit',
      method: 'POST',
      data: params,
    })
  }

  const onSelectType = (type: 'fast' | 'full') => {
    setType(type)
  }

  useEffect(() => {
    const userId = getUserId()
    if (userId) {
      statistics(userId)
      return
    }

    fetchUserId()
  }, [])

  return (
    <Layout className="page-index" useBottomSafeArea={false}>
      <Layout.Body>
        <h1>MBTI职业性格测试题</h1>
        <h2>迈尔斯-布里格斯类型指标</h2>
        <div className="slogan">发现你的个性优势及职场潜能</div>

        <div className="question-type">
          <div
            className={`item a ${type === 'full' ? 'select' : ''}`}
            onClick={() => onSelectType('full')}
          >
            <div className="img" />
            <div className="b">93题</div>
            <div className="t">完整版</div>
            <div className="desc">4-6分钟</div>
          </div>
          <div
            className={`item b ${type === 'fast' ? 'select' : ''}`}
            onClick={() => onSelectType('fast')}
          >
            <div className="img" />
            <div className="b">48题</div>
            <div className="t">速测版</div>
            <div className="desc">3-5分钟</div>
          </div>
        </div>

        <div className="cell">
          <p>
            <strong>职场上总是存在这些「不公平」:</strong>
          </p>
          <p>有些人毕业 2 年月薪 3w，有人工作 10 年却月薪 5K；加班的是你，升职的却是他人;</p>
          <p>明明已经很努力，但工作还是没有突破；换过很多工作，依旧不确定自己擅长什么……</p>
          <p>
            不要怀疑人生，只是你还不够了解自己的性格特点，没匹配到合适的工作，没能发挥真正实力！
          </p>
          <p>
            <strong>职业发展，选择比努力更重要。</strong>
          </p>
          <p>年薪 10 万靠的是勤奋，年薪 30 万比的是谁更快找准了位置。</p>
          <p>
            <strong>如何找对职场跑道，超越同龄人？</strong>这份测试可以帮助你，找到答案。
          </p>

          <p>
            <img src={MBTI} />
          </p>
        </div>

        <div className="cell">
          <p>
            <strong>测评理论</strong>
          </p>
          <p>
            心理学家荣格，提出了人格分类理论，将人决策判断的过程，分成四个维度：触觉感知、自觉感知、理性判断、感性判断。以此为基础，后人发展出了一套科学的职业性格测试
          </p>
          <p>MBTI 职业性格测试。在世界五百强中，有 80% 的企业都用 MBTI 选择人才。</p>
        </div>

        <div className="cell">
          <strong>MBTI测试前须知：</strong>
          <p>1、参加测试的人员请务必诚实、独立地回答问题，只有如此，才能得到有效的结果。</p>
          <p>2、《性格分析报告》展示的是你的性格倾向，而不是你的知识、技能、经验。</p>
          <p>
            3、MBTI提供的性格类型描述仅供测试者确定自己的性格类型之用，性格类型没有好坏，只有不同。每一种性格特征都有其价值和优点，也有缺点和需要注意的地方。清楚地了解自己的性格优劣势，有利于更好地发挥自己的特长，而尽可能的在为人处事中避免自己性格中的劣势，更好地和他人相处，更好地作重要的决策。
          </p>
          <p>
            4、只要你是认真、真实地填写了测试问卷，那么通常情况下你都能得到一个确实和你的性格相匹配的类型。希望你能从中或多或少地获得一些有益的信息。
          </p>
        </div>

        <div className="company">上海简水网络科技有限公司</div>
        <div className="tip-warning">付费项目</div>

        <FeedBack orderNo={orderNo} />
      </Layout.Body>

      <Layout.Footer className="footer">
        <div className="tip">已有269980人参与测试</div>
        <div className="btns">
          <Button className="submit" onClick={onSubmit}>
            开始测试
          </Button>
        </div>
      </Layout.Footer>
    </Layout>
  )
}

export default PageIndex
