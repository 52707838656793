export const dataSource93 = [
  {
    title: '1、当你要外出一整天，你会',
    answer: [
      {
        title: 'A.计划你要做什么和在什么时候做',
        value: 'J',
      },
      {
        title: 'B.说去就去',
        value: 'P',
      },
    ],
  },
  {
    title: '2、你认为自己是一个',
    answer: [
      {
        title: 'A.较为随兴所至的人',
        value: 'P',
      },
      {
        title: 'B.较为有条理的人',
        value: 'J',
      },
    ],
  },
  {
    title: '3、假如你是一位老师，你会选教',
    answer: [
      {
        title: 'A.以事实为主的课程',
        value: 'S',
      },
      {
        title: 'B.涉及理论的课程',
        value: 'N',
      },
    ],
  },
  {
    title: '4、你通常',
    answer: [
      {
        title: 'A.与人容易混熟',
        value: 'E',
      },
      {
        title: 'B.比较沉静或矜持',
        value: 'I',
      },
    ],
  },
  {
    title: '5、一般来说，你和哪些人比较合得来？',
    answer: [
      {
        title: 'A.富于想象力的人',
        value: 'N',
      },
      {
        title: 'B.现实的人',
        value: 'S',
      },
    ],
  },
  {
    title: '6、你是否经常让',
    answer: [
      {
        title: 'A.你的情感支配你的理智',
        value: 'F',
      },
      {
        title: 'B.你的理智主宰你的情感',
        value: 'T',
      },
    ],
  },
  {
    title: '7、处理许多事情上，你会喜欢',
    answer: [
      {
        title: 'A.凭兴所至行事',
        value: 'P',
      },
      {
        title: 'B.按照计划行事',
        value: 'J',
      },
    ],
  },
  {
    title: '8、你是否',
    answer: [
      {
        title: 'A.容易让人了解',
        value: 'E',
      },
      {
        title: 'B.难于让人了解',
        value: 'I',
      },
    ],
  },
  {
    title: '9、按照程序表做事',
    answer: [
      {
        title: 'A.合你心意',
        value: 'J',
      },
      {
        title: 'B.令你感到束缚',
        value: 'P',
      },
    ],
  },
  {
    title: '10、当你有一份特别的任务，你会喜欢',
    answer: [
      {
        title: 'A.开始前小心组织计划',
        value: 'J',
      },
      {
        title: 'B.边做边找须做什么',
        value: 'P',
      },
    ],
  },
  {
    title: '11、在大多数情况下，你会选择',
    answer: [
      {
        title: 'A.顺其自然',
        value: 'P',
      },
      {
        title: 'B.按程序表做事',
        value: 'J',
      },
    ],
  },
  {
    title: '12、大多数人会说你是一个',
    answer: [
      { title: 'A.重视自我隐私的人', value: 'I' },
      { title: 'B.非常坦率开放的人', value: 'E' },
    ],
  },

  {
    title: '13、你宁愿被人认为是一个',
    answer: [
      { title: 'A.实事求是的人', value: 'S' },
      { title: 'B.机灵的人', value: 'N' },
    ],
  },
  {
    title: '14、在一大群人当中，通常是',
    answer: [
      { title: 'A.你介绍大家认识', value: 'E' },
      { title: 'B.别人介绍你', value: 'I' },
    ],
  },

  {
    title: '15、你会跟哪些人做朋友？',
    answer: [
      { title: 'A.常提出新注意的', value: 'N' },
      { title: 'B.脚踏实地的', value: 'S' },
    ],
  },

  {
    title: '16、你倾向',
    answer: [
      { title: 'A.重视感情多于逻辑', value: 'F' },
      { title: 'B.重视逻辑多于感情', value: 'T' },
    ],
  },

  {
    title: '17、你比较喜欢',
    answer: [
      { title: 'A.坐观事情发展才作计划', value: 'P' },
      { title: 'B.很早就作计划', value: 'J' },
    ],
  },

  {
    title: '18、你喜欢花很多的时间',
    answer: [
      { title: 'A.一个人独处', value: 'I' },
      { title: 'B.合别人在一起', value: 'E' },
    ],
  },

  {
    title: '19、与很多人一起会',
    answer: [
      { title: 'A.令你活力培增', value: 'E' },
      { title: 'B.常常令你心力憔悴', value: 'I' },
    ],
  },

  {
    title: '20、你比较喜欢',
    answer: [
      { title: 'A.很早便把约会、社交聚集等事情安排妥当', value: 'J' },
      { title: 'B.无拘无束，看当时有什么好玩就做什么', value: 'P' },
    ],
  },
  {
    title: '21、计划一个旅程时，你较喜欢',
    answer: [
      { title: 'A.大部分的时间都是跟当天的感觉行事', value: 'P' },
      { title: 'B.事先知道大部分的日子会做什么', value: 'J' },
    ],
  },

  {
    title: '22、在社交聚会中，你',
    answer: [
      { title: 'A.有时感到郁闷', value: 'I' },
      { title: 'B.常常乐在其中', value: 'E' },
    ],
  },

  {
    title: '23、你通常',
    answer: [
      { title: 'A.和别人容易混熟', value: 'E' },
      { title: 'B.趋向自处一隅', value: 'I' },
    ],
  },

  {
    title: '24、哪些人会更吸引你？',
    answer: [
      { title: 'A.一个思想敏捷及非常聪颖的人', value: 'N' },
      { title: 'B.实事求是，具丰富常识的人', value: 'S' },
    ],
  },
  {
    title: '25、在日常工作中，你会 ',
    answer: [
      { title: 'A.颇为喜欢处理迫使你分秒必争的突发', value: 'P' },
      { title: 'B.通常预先计划，以免要在压力下工作', value: 'J' },
    ],
  },

  {
    title: '26、你认为别人一般',
    answer: [
      { title: 'A.要花很长时间才认识你', value: 'I' },
      { title: 'B.用很短的时间便认识你', value: 'E' },
    ],
  },

  {
    title: '27、下列两组词，你更倾向于哪一个',
    answer: [
      { title: 'A.注重隐私', value: 'I' },
      { title: 'B.坦率开放', value: 'E' },
    ],
  },

  {
    title: '28、下列哪组词能更好的描述你',
    answer: [
      { title: 'A.预先安排的  ', value: 'J' },
      { title: 'B.无计划的', value: 'P' },
    ],
  },

  {
    title: '29、选一个与你更匹配的词组',
    answer: [
      { title: 'A.抽象', value: 'N' },
      { title: 'B.具体', value: 'S' },
    ],
  },

  {
    title: '30、在下列每一对词语中，哪一个词语更合你心意？',
    answer: [
      { title: 'A.温柔', value: 'F' },
      { title: 'B.坚定', value: 'T' },
    ],
  },

  {
    title: '31、下列两组词，你更倾向于哪一个',
    answer: [
      { title: 'A.思考', value: 'T' },
      { title: 'B.感受', value: 'F' },
    ],
  },

  {
    title: '32、下列哪组词能更好的描述你',
    answer: [
      { title: 'A.事实', value: 'S' },
      { title: 'B.意念', value: 'N' },
    ],
  },

  {
    title: '33、选一个与你更匹配的词组',
    answer: [
      { title: 'A.冲动', value: 'P' },
      { title: 'B.决定', value: 'J' },
    ],
  },

  {
    title: '34、下列哪组词与你匹配更高',
    answer: [
      { title: 'A.热衷', value: 'E' },
      { title: 'B.文静', value: 'I' },
    ],
  },

  {
    title: '35、下列两组词，你更倾向于哪一个',
    answer: [
      { title: 'A.文静', value: 'I' },
      { title: 'B.外向', value: 'E' },
    ],
  },

  {
    title: '36、下列哪组词能更好的描述你',
    answer: [
      { title: 'A.有系统  ', value: 'J' },
      { title: 'B.随意', value: 'P' },
    ],
  },

  {
    title: '37、选一个与你更匹配的词组',
    answer: [
      { title: 'A.理论', value: 'N' },
      { title: 'B.肯定', value: 'S' },
    ],
  },

  {
    title: '您已经完成第一组测试，即将进入第二组测试',
    type: 'tip',
    img: 'https://cdn.atzuche.com/act/iq/tip-1.png',
    answer: [{ title: 'A.好的，继续挑战', value: 'TIP', type: 'tip' }],
  },

  {
    title: '38、在下列每一对词语中，哪一个词语更合你心意？',
    answer: [
      { title: 'A.敏感', value: 'F' },
      { title: 'B.公正', value: 'T' },
    ],
  },

  {
    title: '39、下列两组词，你更倾向于哪一个',
    answer: [
      { title: 'A.令人信服', value: 'T' },
      { title: 'B.感人的', value: 'F' },
    ],
  },

  {
    title: '40、下列哪组词能更好的描述你',
    answer: [
      { title: 'A.声明', value: 'S' },
      { title: 'B.概念', value: 'N' },
    ],
  },

  {
    title: '41、选一个与你更匹配的词组',
    answer: [
      { title: 'A.不受约束', value: 'P' },
      { title: 'B.预先安排', value: 'J' },
    ],
  },

  {
    title: '42、在下列每一对词语中，哪一个词语更合你心意？',
    answer: [
      { title: 'A.矜持', value: 'I' },
      { title: 'B.健谈', value: 'E' },
    ],
  },

  {
    title: '43、下列两组词，你更倾向于哪一个',
    answer: [
      { title: 'A.有条不紊', value: 'J' },
      { title: 'B.不拘小节', value: 'P' },
    ],
  },

  {
    title: '44、下列哪组词能更好的描述你',
    answer: [
      { title: 'A.意念', value: 'N' },
      { title: 'B.实况', value: 'S' },
    ],
  },

  {
    title: '45、选一个与你更匹配的词组',
    answer: [
      { title: 'A.同情怜悯', value: 'F' },
      { title: 'B.远见', value: 'T' },
    ],
  },

  {
    title: '46、下列哪组词与你匹配更高',
    answer: [
      { title: 'A.利益', value: 'T' },
      { title: 'B.祝福', value: 'F' },
    ],
  },

  {
    title: '47、下列两组词，你更倾向于哪一个',
    answer: [
      { title: 'A.务实的  ', value: 'S' },
      { title: 'B.理论的', value: 'N' },
    ],
  },

  {
    title: '48、下列哪组词能更好的描述你',
    answer: [
      { title: 'A.朋友不多 ', value: 'I' },
      { title: 'B.朋友众多', value: 'E' },
    ],
  },

  {
    title: '49、选一个与你更匹配的词组',
    answer: [
      { title: 'A.有系统   ', value: 'J' },
      { title: 'B.即兴', value: 'P' },
    ],
  },

  {
    title: '50、在下列每一对词语中，哪一个词语更合你心意？',
    answer: [
      { title: 'A.富想象的 ', value: 'N' },
      { title: 'B.以事论事', value: 'S' },
    ],
  },

  {
    title: '51、下列两组词，你更倾向于哪一个',
    answer: [
      { title: 'A.亲切的 ', value: 'F' },
      { title: 'B.客观的', value: 'T' },
    ],
  },

  {
    title: '52、下列哪组词能更好的描述你',
    answer: [
      { title: 'A.客观的', value: 'T' },
      { title: 'B.热情的', value: 'F' },
    ],
  },

  {
    title: '53、选一个与你更匹配的词组',
    answer: [
      { title: 'A.建造 ', value: 'S' },
      { title: 'B.发明', value: 'N' },
    ],
  },

  {
    title: '54、在下列每一对词语中，哪一个词语更合你心意？',
    answer: [
      { title: 'A.文静 ', value: 'I' },
      { title: 'B.爱合群', value: 'E' },
    ],
  },

  {
    title: '55、下列两组词，你更倾向于哪一个',
    answer: [
      { title: 'A.理论 ', value: 'N' },
      { title: 'B.事实', value: 'S' },
    ],
  },

  {
    title: '56、下列哪组词能更好的描述你？',
    answer: [
      { title: 'A.富同情 ', value: 'F' },
      { title: 'B.合逻辑', value: 'T' },
    ],
  },

  {
    title: '57、选一个与你更匹配的词组？',
    answer: [
      { title: 'A.具分析力 ', value: 'T' },
      { title: 'B.多愁善感', value: 'F' },
    ],
  },

  {
    title: '58、在下列每一对词语中，哪一个词语更合你心意？',
    answer: [
      { title: 'A.合情合理 ', value: 'S' },
      { title: 'B.令人着迷', value: 'N' },
    ],
  },
  {
    title: '59、下列两组词，你更倾向于哪一个',
    answer: [
      { title: 'A.想象的', value: 'N' },
      { title: 'B.真实的', value: 'S' },
    ],
  },

  {
    title: '60、下列哪组词能更好的描述你',
    answer: [
      { title: 'A.仁慈慷慨的', value: 'F' },
      { title: 'B.意志坚定的', value: 'T' },
    ],
  },

  {
    title: '61、选一个与你更匹配的词组',
    answer: [
      { title: 'A.公正的', value: 'T' },
      { title: 'B.有关怀心', value: 'F' },
    ],
  },

  {
    title: '62、在下列每一对词语中，哪一个词语更合你心意？',
    answer: [
      { title: 'A.制作', value: 'S' },
      { title: 'B.设计', value: 'N' },
    ],
  },

  {
    title: '63、下列两组词，你更倾向于哪一个',
    answer: [
      { title: 'A.可能性', value: 'N' },
      { title: 'B.必然性', value: 'S' },
    ],
  },

  {
    title: '64、下列哪组词能更好的描述你',
    answer: [
      { title: 'A.温柔', value: 'F' },
      { title: 'B.力量', value: 'T' },
    ],
  },

  {
    title: '65、选一个与你更匹配的词组',
    answer: [
      { title: 'A.实际', value: 'T' },
      { title: 'B.多愁善感', value: 'F' },
    ],
  },

  {
    title: '66、在下列每一对词语中，哪一个词语更合你心意？',
    answer: [
      { title: 'A.制造', value: 'S' },
      { title: 'B.创造', value: 'N' },
    ],
  },

  {
    title: '67、下列两组词，你更倾向于哪一个',
    answer: [
      { title: 'A.新颖的', value: 'N' },
      { title: 'B.已知的', value: 'S' },
    ],
  },

  {
    title: '68、下列哪组词能更好的描述你？',
    answer: [
      { title: 'A.同情', value: 'F' },
      { title: 'B.分析', value: 'T' },
    ],
  },

  {
    title: '69、选一个与你更匹配的词组',
    answer: [
      { title: 'A.坚持己见', value: 'T' },
      { title: 'B.温柔有爱心', value: 'F' },
    ],
  },

  {
    title: '70、在下列每一对词语中，哪一个词语更合你心意？',
    answer: [
      { title: 'A.具体的', value: 'S' },
      { title: 'B.抽象的', value: 'N' },
    ],
  },

  {
    title: '71、下列两组词，你更倾向于哪一个',
    answer: [
      { title: 'A.全心投入', value: 'F' },
      { title: 'B.有决心的', value: 'T' },
    ],
  },

  {
    title: '72、下列哪组词能更好的描述你',
    answer: [
      { title: 'A.能干', value: 'T' },
      { title: 'B.仁慈', value: 'F' },
    ],
  },

  {
    title: '73、在下列每一对词语中，哪一个词语更合你心意？',
    answer: [
      { title: 'A.实际', value: 'S' },
      { title: 'B.创新 ', value: 'N' },
    ],
  },

  {
    title: '您已经完成第二组测试，即将进入最后一组测试',
    type: 'tip',
    img: 'https://cdn.atzuche.com/act/iq/tip-3.png',
    answer: [{ title: 'A.好的，继续挑战', value: 'TIP', type: 'tip' }],
  },

  {
    title: '74、当你要在一个星期内完成一个大项目，你在开始的时候会 ',
    answer: [
      { title: 'A.把要做的不同工作依次列出', value: 'J' },
      { title: 'B.马上动工', value: 'P' },
    ],
  },

  {
    title: '75、在社交场合中，你经常会感到',
    answer: [
      { title: 'A.与某些人很难打开话匣儿和保持对话', value: 'I' },
      { title: 'B.与多数人都能从容地长谈', value: 'E' },
    ],
  },

  {
    title: '76、要做许多人也做的事，你比较喜欢',
    answer: [
      { title: 'A.按照一般认可的方法去做', value: 'S' },
      { title: 'B.构想一个自己的想法', value: 'N' },
    ],
  },

  {
    title: '77、你刚认识的朋友能否说出你的兴趣？',
    answer: [
      { title: 'A.马上可以', value: 'E' },
      { title: 'B.要待他们真正了解你之后才可以', value: 'I' },
    ],
  },

  {
    title: '78、你通常较喜欢的科目是',
    answer: [
      { title: 'A.讲授概念和原则的', value: 'N' },
      { title: 'B.讲授事实和数据的', value: 'S' },
    ],
  },

  {
    title: '79、哪个是较高的赞誉，或称许为？',
    answer: [
      { title: 'A.一贯感性的人', value: 'F' },
      { title: 'B.一贯理性的人', value: 'T' },
    ],
  },

  {
    title: '80、你认为按照程序表做事',
    answer: [
      { title: 'A.有时是需要的，但一般来说你不大喜欢这样做', value: 'P' },
      { title: 'B.大多数情况下是有帮助而,且是你喜欢做的', value: 'J' },
    ],
  },

  {
    title: '81、和一群人在一起，你通常会选',
    answer: [
      { title: 'A.跟你很熟悉的个别人谈话', value: 'I' },
      { title: 'B.参与大伙的谈话', value: 'E' },
    ],
  },

  {
    title: '82、在社交聚会上，你会',
    answer: [
      { title: 'A.是说话很多的一个', value: 'E' },
      { title: 'B.让别人多说话', value: 'I' },
    ],
  },

  {
    title: '83、把周末期间要完成的事列成清单，这个主意会',
    answer: [
      { title: 'A.合你意', value: 'J' },
      { title: 'B.使你提不起劲', value: 'P' },
    ],
  },

  {
    title: '84、哪个是较高的赞誉，或称许为？',
    answer: [
      { title: 'A.能干的', value: 'T' },
      { title: 'B.富有同情心', value: 'F' },
    ],
  },

  {
    title: '85、你通常喜欢',
    answer: [
      { title: 'A.事先安排你的社交约会', value: 'J' },
      { title: 'B.随兴之所至做事', value: 'P' },
    ],
  },

  {
    title: '86、总的说来，要做一个大型作业时，你会选',
    answer: [
      { title: 'A.边做边想该做什么', value: 'P' },
      { title: 'B.首先把工作按步细分', value: 'J' },
    ],
  },

  {
    title: '87、你能否滔滔不绝地与人聊天',
    answer: [
      { title: 'A.只限于跟你有共同兴趣的人', value: 'I' },
      { title: 'B.几乎跟任何人都可以', value: 'E' },
    ],
  },

  {
    title: '88、你会',
    answer: [
      { title: 'A.跟随一些证明有效的方法', value: 'S' },
      { title: 'B.分析还有什么毛病，及针对尚未解决的难题', value: 'N' },
    ],
  },

  {
    title: '89、为乐趣而阅读时，你会',
    answer: [
      { title: 'A.喜欢奇特或创新的表达方式', value: 'N' },
      { title: 'B.喜欢作者直话直说', value: 'S' },
    ],
  },

  {
    title: '90、你宁愿替哪一类上司（或者老师）工作？',
    answer: [
      { title: 'A.天性淳良，但常常前后不一的', value: 'T' },
      { title: 'B.言词尖锐但永远合乎逻辑的', value: 'N' },
    ],
  },

  {
    title: '91、你做事多数是',
    answer: [
      { title: 'A.按当天心情去做', value: 'P' },
      { title: 'B.照拟好的程序表去做', value: 'J' },
    ],
  },

  {
    title: '92、你是否',
    answer: [
      { title: 'A.可以和任何人按需求从容地交谈', value: 'E' },
      { title: 'B.只是对某些人或在某种情况下才可以畅所欲言', value: 'I' },
    ],
  },

  {
    title: '93、要作决定时，你认为比较重要的是',
    answer: [
      { title: 'A.据事实衡量，', value: 'T' },
      { title: 'B.考虑他人的感受和意见', value: 'F' },
    ],
  },
]

export const dataSource48 = [
  {
    title: '1、你认为自己是一个',
    answer: [
      {
        title: 'A.较为随兴所至的人',
        value: 'P',
      },
      {
        title: 'B.较为有条理的人',
        value: 'J',
      },
    ],
  },
  {
    title: '2、假如你是一位老师，你会选教',
    answer: [
      {
        title: 'A.以事实为主的课程',
        value: 'S',
      },
      {
        title: 'B.涉及理论的课程',
        value: 'N',
      },
    ],
  },
  {
    title: '3、你通常',
    answer: [
      {
        title: 'A.与人容易混熟',
        value: 'E',
      },
      {
        title: 'B.比较沉静或矜持',
        value: 'I',
      },
    ],
  },
  {
    title: '4、一般来说，你和哪些人比较合得来？',
    answer: [
      {
        title: 'A.富于想象力的人',
        value: 'N',
      },
      {
        title: 'B.现实的人',
        value: 'S',
      },
    ],
  },
  {
    title: '5、你是否经常让',
    answer: [
      {
        title: 'A.你的情感支配你的理智',
        value: 'F',
      },
      {
        title: 'B.你的理智主宰你的情感',
        value: 'T',
      },
    ],
  },
  {
    title: '6、按照程序表做事',
    answer: [
      {
        title: 'A.合你心意',
        value: 'J',
      },
      {
        title: 'B.令你感到束缚',
        value: 'P',
      },
    ],
  },
  {
    title: '7、大多数人会说你是一个',
    answer: [
      { title: 'A.重视自我隐私的人', value: 'I' },
      { title: 'B.非常坦率开放的人', value: 'E' },
    ],
  },

  {
    title: '8、你宁愿被人认为是一个',
    answer: [
      { title: 'A.实事求是的人', value: 'S' },
      { title: 'B.机灵的人', value: 'N' },
    ],
  },
  {
    title: '9、在一大群人当中，通常是',
    answer: [
      { title: 'A.你介绍大家认识', value: 'E' },
      { title: 'B.别人介绍你', value: 'I' },
    ],
  },

  {
    title: '10、你会跟哪些人做朋友？',
    answer: [
      { title: 'A.常提出新注意的', value: 'N' },
      { title: 'B.脚踏实地的', value: 'S' },
    ],
  },

  {
    title: '11、在社交聚会中，你',
    answer: [
      { title: 'A.有时感到郁闷', value: 'I' },
      { title: 'B.常常乐在其中', value: 'E' },
    ],
  },

  {
    title: '12、你通常',
    answer: [
      { title: 'A.和别人容易混熟', value: 'E' },
      { title: 'B.趋向自处一隅', value: 'I' },
    ],
  },

  {
    title: '13、哪些人会更吸引你？',
    answer: [
      { title: 'A.一个思想敏捷及非常聪颖的人', value: 'N' },
      { title: 'B.实事求是，具丰富常识的人', value: 'S' },
    ],
  },

  {
    title: '14、你认为别人一般',
    answer: [
      { title: 'A.要花很长时间才认识你', value: 'I' },
      { title: 'B.用很短的时间便认识你', value: 'E' },
    ],
  },

  {
    title: '您已经完成第一组测试，即将进入第二组测试',
    type: 'tip',
    img: 'https://cdn.atzuche.com/act/iq/tip-1.png',
    answer: [{ title: 'A.好的，继续挑战', value: 'TIP', type: 'tip' }],
  },

  {
    title: '15、在下列每一对词语中，哪一个词语更合你心意？',
    answer: [
      { title: 'A.预先安排的  ', value: 'J' },
      { title: 'B.无计划的', value: 'P' },
    ],
  },

  {
    title: '16、下列两组词，你更倾向于哪一个',
    answer: [
      { title: 'A.思考', value: 'T' },
      { title: 'B.感受', value: 'F' },
    ],
  },

  {
    title: '17、下列哪组词能更好的描述你',
    answer: [
      { title: 'A.有系统  ', value: 'J' },
      { title: 'B.随意', value: 'P' },
    ],
  },

  {
    title: '18、选一个与你更匹配的词组',
    answer: [
      { title: 'A.理论', value: 'N' },
      { title: 'B.肯定', value: 'S' },
    ],
  },

  {
    title: '19、在下列每一对词语中，哪一个词语更合你心意？',
    answer: [
      { title: 'A.敏感', value: 'F' },
      { title: 'B.公正', value: 'T' },
    ],
  },

  {
    title: '20、下列两组词，你更倾向于哪一个',
    answer: [
      { title: 'A.令人信服', value: 'T' },
      { title: 'B.感人的', value: 'F' },
    ],
  },

  {
    title: '21、在下列每一对词语中，哪一个词语更合你心意？',
    answer: [
      { title: 'A.声明', value: 'S' },
      { title: 'B.概念', value: 'N' },
    ],
  },

  {
    title: '22、选一个与你更匹配的词组',
    answer: [
      { title: 'A.不受约束', value: 'P' },
      { title: 'B.预先安排', value: 'J' },
    ],
  },

  {
    title: '23、下列哪组词与你匹配更高',
    answer: [
      { title: 'A.矜持', value: 'I' },
      { title: 'B.健谈', value: 'E' },
    ],
  },

  {
    title: '24、下列两组词，你更倾向于哪一个',
    answer: [
      { title: 'A.有条不紊', value: 'J' },
      { title: 'B.不拘小节', value: 'P' },
    ],
  },

  {
    title: '25、在下列每一对词语中，哪一个词语更合你心意？',
    answer: [
      { title: 'A.同情怜悯', value: 'F' },
      { title: 'B.远见', value: 'T' },
    ],
  },

  {
    title: '26、下列哪组词与你匹配更高？',
    answer: [
      { title: 'A.利益', value: 'T' },
      { title: 'B.祝福', value: 'F' },
    ],
  },

  {
    title: '27、在下列每一对词语中，哪一个词语更合你心意？',
    answer: [
      { title: 'A.务实的  ', value: 'S' },
      { title: 'B.理论的', value: 'N' },
    ],
  },

  {
    title: '28、下列两组词，你更倾向于哪一个',
    answer: [
      { title: 'A.朋友不多 ', value: 'I' },
      { title: 'B.朋友众多', value: 'E' },
    ],
  },

  {
    title: '29、 下列哪组词能更好的描述你',
    answer: [
      { title: 'A.有系统   ', value: 'J' },
      { title: 'B.即兴', value: 'P' },
    ],
  },

  {
    title: '30、在下列每一对词语中，哪一个词语更合你心意？',
    answer: [
      { title: 'A.富想象的 ', value: 'N' },
      { title: 'B.以事论事', value: 'S' },
    ],
  },

  {
    title: '31、 选一个与你更匹配的词组',
    answer: [
      { title: 'A.亲切的   ', value: 'F' },
      { title: 'B.客观的', value: 'T' },
    ],
  },

  {
    title: '32、下列哪组词与你匹配更高',
    answer: [
      { title: 'A.客观的   ', value: 'T' },
      { title: 'B.热情的', value: 'F' },
    ],
  },

  {
    title: '33、下列两组词，你更倾向于哪一个',
    answer: [
      { title: 'A.建造 ', value: 'S' },
      { title: 'B.发明', value: 'N' },
    ],
  },

  {
    title: '34、在下列每一对词语中，哪一个词语更合你心意？',
    answer: [
      { title: 'A.富同情   ', value: 'F' },
      { title: 'B.合逻辑', value: 'T' },
    ],
  },

  {
    title: '35、下列哪组词与你匹配更高',
    answer: [
      { title: 'A.具分析力 ', value: 'T' },
      { title: 'B.多愁善感', value: 'F' },
    ],
  },

  {
    title: '36、在下列每一对词语中，哪一个词语更合你心意？',
    answer: [
      { title: 'A.合情合理 ', value: 'S' },
      { title: 'B.令人着迷', value: 'N' },
    ],
  },

  {
    title: '37、在下列每一对词语中，哪一个词语更合你心意？',
    answer: [
      { title: 'A.制作', value: 'S' },
      { title: 'B.设计', value: 'N' },
    ],
  },

  {
    title: '您已经完成第二组测试，即将进入最后一组测试',
    type: 'tip',
    img: 'https://cdn.atzuche.com/act/iq/tip-3.png',
    answer: [{ title: 'A.好的，继续挑战', value: 'TIP', type: 'tip' }],
  },

  {
    title: '38、在社交场合中，你经常会感到',
    answer: [
      { title: 'A.与某些人很难打开话匣儿和保持对话', value: 'I' },
      { title: 'B.与多数人都能从容地长谈', value: 'E' },
    ],
  },

  {
    title: '39、你刚认识的朋友能否说出你的兴趣？',
    answer: [
      { title: 'A.马上可以', value: 'E' },
      { title: 'B.要待他们真正了解你之后才可以', value: 'I' },
    ],
  },

  {
    title: '40、你通常较喜欢的科目是',
    answer: [
      { title: 'A.讲授概念和原则的', value: 'N' },
      { title: 'B.讲授事实和数据的', value: 'S' },
    ],
  },

  {
    title: '41、在社交聚会上，你会',
    answer: [
      { title: 'A.是说话很多的一个', value: 'E' },
      { title: 'B.让别人多说话', value: 'I' },
    ],
  },

  {
    title: '42、把周末期间要完成的事列成清单，这个主意会',
    answer: [
      { title: 'A.合你意', value: 'J' },
      { title: 'B.使你提不起劲', value: 'P' },
    ],
  },

  {
    title: '43、哪个是较高的赞誉，或称许为',
    answer: [
      { title: 'A.能干的', value: 'T' },
      { title: 'B.富有同情心', value: 'F' },
    ],
  },

  {
    title: '44、你通常喜欢',
    answer: [
      { title: 'A.事先安排你的社交约会', value: 'J' },
      { title: 'B.随兴之所至做事', value: 'P' },
    ],
  },

  {
    title: '45、总的说来，要做一个大型作业时，你会选',
    answer: [
      { title: 'A.边做边想该做什么', value: 'P' },
      { title: 'B.首先把工作按步细分', value: 'J' },
    ],
  },

  {
    title: '46、你比较喜欢',
    answer: [
      { title: 'A.很早便把约会、社交聚集等事情安排妥当', value: 'J' },
      { title: 'B.无拘无束，看当时有什么好玩就做什么', value: 'P' },
    ],
  },

  {
    title: '47、你会',
    answer: [
      { title: 'A.跟随一些证明有效的方法', value: 'S' },
      { title: 'B.分析还有什么毛病，及针对尚未解决的难题', value: 'N' },
    ],
  },

  {
    title: '48、要作决定时，你认为比较重要的是',
    answer: [
      { title: 'A.据事实衡量，', value: 'T' },
      { title: 'B.考虑他人的感受和意见', value: 'F' },
    ],
  },
]
