import { Search } from 'auto-libs'
import { Alert, Layout, Loading, Toast } from 'auto-ui'
import { useEffect, useMemo } from 'react'
import { useSetState } from 'react-use'
import FeedBack from '../../components/feedback'
import Score from '../../components/score'
import { resultData } from '../../data/result'
import http from '../../utils/http'
import { aliPay, wxPay } from '../../utils/pay'
import './style.scss'

const PageIndex = () => {
  const orderNo = Search.get('orderNo') || ''

  const [state, setState] = useSetState({
    alipayForm: '',
    orderStatus: false,
    loading: false,
    result: '',
  })

  const typeX = useMemo(() => {
    const answer: any = state.result?.split('|')

    return {
      value: answer[0],
      J: answer[1],
      P: answer[2],
      T: answer[3],
      F: answer[4],
      S: answer[5],
      N: answer[6],
      E: answer[7],
      I: answer[8],
    }
  }, [state.result])

  let payWay = 'ALIPAY'

  // @ts-ignore
  const data = resultData[typeX.value] || {}

  const fetchMatchResult = async () => {
    try {
      if (!orderNo) {
        Toast('地址不正确，请回首页重新测试')
        return
      }

      setState({
        loading: true,
      })

      const payResult: any = await http.request({
        url: '/order/queryStatus',
        method: 'POST',
        data: {
          orderId: orderNo,
        },
      })

      let { status, payMethod, bizParams } = payResult.body
      if (payResult.code !== '00000000' || status !== 2) {
        payWay = payMethod
        checkPayStatus()
        return
      }

      setState({
        result: bizParams,
        orderStatus: status === 2 ? true : false,
      })
    } catch (e) {
      Toast('系统异常，请重试')
    } finally {
      setState({
        loading: false,
      })
    }
  }

  const onWechatPay = async () => {
    const result = await wxPay(orderNo, state.result)
    window.location.href = result
  }

  const onAlipay = async () => {
    const result = await aliPay(orderNo, state.result)

    setState({
      alipayForm: result,
    })

    setTimeout(() => {
      document.forms[0].submit()
    }, 100)
  }

  const toPay = async () => {
    try {
      Loading()
      if (payWay === 'WXPAY') {
        await onWechatPay()
      } else {
        await onAlipay()
      }
    } catch (error: any) {
      Toast(error.message || '系统异常，请重新再试')
    } finally {
      Loading.hide()
    }
  }

  const checkPayStatus = () => {
    Alert({
      title: '请确认支付是否完成',
      btnDirection: 'column',
      btns: [
        {
          name: '已完成支付',
          type: 'danger',
          hollow: true,
          onClick: () => {
            fetchMatchResult()
          },
        },
        {
          name: '支付遇到问题，重新支付',
          type: 'danger',
          onClick: () => {
            toPay()
          },
        },
      ],
    })
  }

  useEffect(() => {
    fetchMatchResult()
  }, [])

  return (
    <Layout className="page-result">
      <Layout.Body className={`body ${!state.orderStatus ? 'blur' : ''}`} loading={state.loading}>
        <div className="banner" />

        <div className="cell c1">
          <div className="title">你是MBTI十六种类型中的</div>
          <div className="content">
            <div className="img">
              {typeX.value && <img src={require(`../../imgs/tt/${typeX.value}.png`)} />}
            </div>
            <div className="slogan" dangerouslySetInnerHTML={{ __html: data.tag }} />
          </div>
        </div>

        <div className="cell">
          <div className="title">这四个字母代表什么</div>
          <div className="content">
            <div className="img">
              <img src={require('../../imgs/intj.jpeg')} />
            </div>
          </div>
        </div>

        <div className="cell score">
          <div className="title">你的得分高低</div>
          <div className="content">
            <Score type={['E', 'I']} values={[typeX.E, typeX.I]} />
            <Score type={['S', 'N']} values={[typeX.S, typeX.N]} />
            <Score type={['T', 'F']} values={[typeX.T, typeX.F]} />
            <Score type={['J', 'P']} values={[typeX.J, typeX.P]} />
          </div>
        </div>

        <div className="cell">
          <div className="title">你是什么样的人</div>
          <div className="content" dangerouslySetInnerHTML={{ __html: data.subscribe }} />
        </div>

        <div className="cell">
          <div className="title">你在他人眼中的样子</div>
          <div className="content" dangerouslySetInnerHTML={{ __html: data.ms }} />
        </div>

        <div className="cell">
          <div className="title">你的优势是什么</div>
          <div className="content" dangerouslySetInnerHTML={{ __html: data.ys }} />
        </div>

        <div className="cell">
          <div className="title">你的劣势是什么</div>
          <div className="content" dangerouslySetInnerHTML={{ __html: data.ls }} />
        </div>

        <div className="cell">
          <div className="title">适合的岗位特质</div>
          <div className="content" dangerouslySetInnerHTML={{ __html: data.gwtz }} />
        </div>

        <div className="cell">
          <div className="title">什么样的职业最合适你</div>
          <div className="content" dangerouslySetInnerHTML={{ __html: data.zy }} />
        </div>
        <div className="cell">
          <div className="title">如何更好地解决问题</div>
          <div className="content" dangerouslySetInnerHTML={{ __html: data.jjwt }} />
        </div>
        <div className="cell">
          <div className="title">你在工作生活中可能忽略的问题</div>
          <div className="content" dangerouslySetInnerHTML={{ __html: data.md }} />
        </div>
        <div className="cell">
          <div className="title">如何更好地发挥你的潜能</div>
          <div className="content" dangerouslySetInnerHTML={{ __html: data.qn }} />
        </div>

        <div className="cell">
          <div className="title">个人发展建议</div>
          <div className="content" dangerouslySetInnerHTML={{ __html: data.jy }} />
        </div>

        <div className="chengxin">
          <img src={require('../../imgs/chengxin.png')} />
        </div>

        <div className="bannerx namematch">
          <a href="https://l1.ijianshui.com?channel=nqqe$39">
            <img src={require('../../imgs/banner-name.jpg')} />
          </a>
        </div>
        <div className="bannerx iq">
          <a href="https://iqt.ijianshui.com?channel=ucq5$38">
            <img src={require('../../imgs/banner-iq.jpg')} />
          </a>
        </div>

        <FeedBack orderNo={orderNo} />
      </Layout.Body>
      <div
        id="alipay_form"
        style={{ display: 'none' }}
        dangerouslySetInnerHTML={{ __html: state.alipayForm }}
      />
    </Layout>
  )
}

export default PageIndex
