import { Button, Layout, Loading, Popup, Toast } from 'auto-ui'
import { useEffect, useMemo, useState } from 'react'
import { useLocation } from 'react-router-dom'
import Score from '../../components/score'
import { resultData } from '../../data/result'
import { types } from '../../data/types'
import { wxPay } from '../../utils/pay'
import './style.scss'

const PageIndex = () => {
  // const [alipayForm, setAlipayForm] = useState<any>(null)
  const [typeCurrent, setTypeCurrent] = useState(0)
  const locationHook = useLocation()
  const [isShowPayPop, setIsShowPayPop] = useState(false)

  const locationState: any = locationHook.state || {}
  const { answer, orderNo } = locationState

  const typeX = useMemo(() => {
    const values = [...answer.values()]

    const J = values.filter((item) => item === 'J').length
    const P = values.filter((item) => item === 'P').length
    const T = values.filter((item) => item === 'T').length
    const F = values.filter((item) => item === 'F').length
    const S = values.filter((item) => item === 'S').length
    const N = values.filter((item) => item === 'N').length
    const E = values.filter((item) => item === 'E').length
    const I = values.filter((item) => item === 'I').length

    return {
      value: `${I >= E ? 'I' : 'E'}${N >= S ? 'N' : 'S'}${F >= T ? 'F' : 'T'}${P >= J ? 'P' : 'J'}`,
      J,
      P,
      T,
      F,
      S,
      N,
      E,
      I,
    }
  }, [answer])

  // @ts-ignore
  const data = resultData['ISTJ']

  const onWechatPay = async () => {
    try {
      Loading()
      const r = `${typeX.value}|${typeX.J}|${typeX.P}|${typeX.T}|${typeX.F}|${typeX.S}|${typeX.N}|${typeX.E}|${typeX.I}`
      const result = await wxPay(orderNo, r)
      window.location.href = result
    } catch (error: any) {
      Toast(error.message || '系统异常，请重试')
    } finally {
      Loading.hide()
    }
  }

  // const onAlipay = async () => {
  //   try {
  //     Loading()
  //     const r = `${typeX.value}|${typeX.J}|${typeX.P}|${typeX.T}|${typeX.F}|${typeX.S}|${typeX.N}|${typeX.E}|${typeX.I}`
  //     const result = await aliPay(orderNo, r)
  //     setAlipayForm(result)
  //     setTimeout(() => {
  //       document.forms[0].submit()
  //     }, 100)
  //   } catch (error) {
  //     Toast('系统异常，请重试')
  //   } finally {
  //     Loading.hide()
  //   }
  // }

  useEffect(() => {
    const timer = setInterval(() => {
      setTypeCurrent((typeCurrent) => {
        return typeCurrent >= 15 ? 0 : typeCurrent + 1
      })
    }, 2000)

    return () => {
      clearInterval(timer)
    }
  }, [])

  const onClosePayPop = () => {
    setIsShowPayPop((visible) => !visible)
  }

  const onPay = () => {
    setIsShowPayPop((visible) => true)
  }

  return (
    <Layout className="page-pay" bottomSafeAreaColor="#f90">
      <Layout.Body className="body blur">
        <div className="banner" />

        <div className="desc">
          <p>
            您的<span>专属测评报告</span>已生成
          </p>
          <p>
            解锁查看<span>完整版报告</span>
          </p>
          <p>
            已有<span>269980</span>人参与测试并查看了报告
          </p>
        </div>

        <div className="cell c1">
          <div className="title">你是MBTI十六种类型中的哪一种</div>
          <div className="content">
            <div className="img">
              <img src={require(`../../imgs/tt/${types[typeCurrent].type}.png`)} />
            </div>
            <div className="type">{types[typeCurrent].type}</div>
            <div className="name">{types[typeCurrent].name}</div>
          </div>
          <Button className="pay-rightnow" onClick={onPay}>
            立即解锁
            <span className="icon" />
          </Button>
        </div>

        <div className="cell">
          <div className="title">这四个字母代表什么</div>
          <div className="content">
            <div className="img">
              <img src={require('../../imgs/intj.jpeg')} />
            </div>
          </div>
          <Button className="pay-rightnow" onClick={onPay}>
            立即解锁
            <span className="icon" />
          </Button>
        </div>

        <div className="cell score">
          <div className="title">你的得分高低</div>
          <div className="content">
            <Score type={['E', 'I']} values={[typeX.E, typeX.I]} />
            <Score type={['S', 'N']} values={[typeX.S, typeX.N]} />
            <Score type={['T', 'F']} values={[typeX.T, typeX.F]} />
            <Score type={['J', 'P']} values={[typeX.J, typeX.P]} />
          </div>
          <Button className="pay-rightnow" onClick={onPay}>
            立即解锁
            <span className="icon" />
          </Button>
        </div>

        <div className="cell">
          <div className="title">你是什么样的人</div>
          <div className="content" dangerouslySetInnerHTML={{ __html: data.subscribe }} />
          <Button className="pay-rightnow" onClick={onPay}>
            立即解锁
            <span className="icon" />
          </Button>
        </div>

        <div className="cell">
          <div className="title">你在他人眼中的样子</div>
          <div className="content" dangerouslySetInnerHTML={{ __html: data.ms }} />
          <Button className="pay-rightnow" onClick={onPay}>
            立即解锁
            <span className="icon" />
          </Button>
        </div>

        <div className="cell">
          <div className="title">你的优势是什么</div>
          <div className="content" dangerouslySetInnerHTML={{ __html: data.ys }} />
          <Button className="pay-rightnow" onClick={onPay}>
            立即解锁
            <span className="icon" />
          </Button>
        </div>

        <div className="cell">
          <div className="title">你的劣势是什么</div>
          <div className="content" dangerouslySetInnerHTML={{ __html: data.ls }} />
          <Button className="pay-rightnow" onClick={onPay}>
            立即解锁
            <span className="icon" />
          </Button>
        </div>

        <div className="cell">
          <div className="title">适合的岗位特质</div>
          <div className="content" dangerouslySetInnerHTML={{ __html: data.gwtz }} />
          <Button className="pay-rightnow" onClick={onPay}>
            立即解锁
            <span className="icon" />
          </Button>
        </div>

        <div className="cell">
          <div className="title">什么样的职业最合适你</div>
          <div className="content" dangerouslySetInnerHTML={{ __html: data.zy }} />
          <Button className="pay-rightnow" onClick={onPay}>
            立即解锁
            <span className="icon" />
          </Button>
        </div>
        <div className="cell">
          <div className="title">如何更好地解决问题</div>
          <div className="content" dangerouslySetInnerHTML={{ __html: data.jjwt }} />
          <Button className="pay-rightnow" onClick={onPay}>
            立即解锁
            <span className="icon" />
          </Button>
        </div>
        <div className="cell">
          <div className="title">你在工作生活中可能忽略的问题</div>
          <div className="content" dangerouslySetInnerHTML={{ __html: data.md }} />
          <Button className="pay-rightnow" onClick={onPay}>
            立即解锁
            <span className="icon" />
          </Button>
        </div>
        <div className="cell">
          <div className="title">如何更好地发挥你的潜能</div>
          <div className="content" dangerouslySetInnerHTML={{ __html: data.qn }} />
          <Button className="pay-rightnow" onClick={onPay}>
            立即解锁
            <span className="icon" />
          </Button>
        </div>

        <div className="cell">
          <div className="title">个人发展建议</div>
          <div className="content" dangerouslySetInnerHTML={{ __html: data.jy }} />
          <Button className="pay-rightnow" onClick={onPay}>
            立即解锁
            <span className="icon" />
          </Button>
        </div>

        <div className="chengxin">
          <img src={require('../../imgs/chengxin.png')} />
        </div>
      </Layout.Body>

      <Layout.Footer className="footer" onClick={onPay}>
        <div className="btn" />
      </Layout.Footer>

      {/* <div
        id="alipay_form"
        style={{ display: 'none' }}
        dangerouslySetInnerHTML={{ __html: alipayForm }}
      /> */}

      <Popup className="pop-pay" height={60} visible={isShowPayPop} onMaskClick={onClosePayPop}>
        <div className="close" onClick={onClosePayPop} />
        <div className="desc-tip">
          <h2>完整报告解读</h2>
          <p>
            包括MBTI类型、维度得分情况、字母含义、我是什么样的人、我在他人眼中的样、优劣势分析、职业推荐
          </p>

          <div className="price">
            <del>￥59.9</del>
            <span>29.9元</span>
          </div>
        </div>
        <div className="pay-button">
          <Button className="btn wechat" onClick={onWechatPay}>
            <span className="icon" />
            微信支付
          </Button>
          {/* <Button className="btn alipay" onClick={onAlipay}>
            <span className="icon" />
            支付宝支付
          </Button> */}
        </div>

        <div className="tip">已有269980支付</div>
      </Popup>
    </Layout>
  )
}

export default PageIndex
