import { useMemo } from 'react'
import './style.scss'

const typeMap = new Map([
  ['E', '外向'],
  ['I', '内向'],
  ['S', '实感'],
  ['N', '直觉'],
  ['T', '思考'],
  ['F', '情感'],
  ['J', '判断'],
  ['P', '认知'],
])

interface IProps {
  type: [string, string]
  values: [number, number]
}

const Score = (props: IProps) => {
  const { type, values } = props
  const result = useMemo(() => {
    const [t1, t2] = type
    let [v1, v2] = values
    v1 = Number(v1)
    v2 = Number(v2)

    const v1Percent = Math.round((v1 / (v1 + v2)) * 100)
    const v2Percent = Math.round((v2 / (v1 + v2)) * 100)

    return {
      win: v1 <= v2 ? t2 : t1,
      difference: Math.abs(v1Percent - v2Percent),
      v1Percent,
      v2Percent,
    }
  }, [type, values])

  return (
    <div className={`com-score ${result.v2Percent >= result.v1Percent ? 'left' : 'right'}`}>
      <div className={`type ${result.win === type[0] ? 'win' : ''}`}>
        <div className="percent">{result.v1Percent}%</div>
        <div className="name">
          {typeMap.get(type[0])}
          {type[0]}
        </div>
      </div>
      <div className="bar">
        <div className="divider" />
        <div className="bar-scoller" style={{ width: result.difference + '%' }}>
          {result.win}
        </div>
      </div>
      <div className={`type ${result.win === type[1] ? 'win' : ''}`}>
        <div className="percent">{result.v2Percent}%</div>
        <div className="name">
          {typeMap.get(type[1])}
          {type[1]}
        </div>
      </div>
    </div>
  )
}

export default Score
