import { Alert, Button, Layout, Toast } from 'auto-ui'
import { useLocation, useNavigate } from 'react-router-dom'
import { useSetState } from 'react-use'
import { dataSource48, dataSource93 } from '../../data/data'
import './style.scss'
import { IQA, IState } from './types'

const PageQA = () => {
  const navigateHook = useNavigate()
  const location: any = useLocation()
  const { orderNo, type } = location.state
  const qaList: any = type === 'full' ? dataSource93 : dataSource48
  const [state, setState] = useSetState<IState>({ current: 1, myAnswers: new Map() })
  const currentQA: IQA = qaList[state.current - 1]

  const onPrev = () => {
    setState({
      current: state.current - 1,
    })
  }

  const onNext = () => {
    const isFinished = onCheckedCurrentIsFinished()

    if (!isFinished) {
      Toast('请完成当前题目')
      return
    }

    setState({
      current: state.current + 1,
    })
  }

  const onCheckedCurrentIsFinished = (): boolean => {
    if (!state.myAnswers.get(state.current - 1)) {
      return false
    }

    return true
  }

  const onAnswerClick = async (value: string, type?: string) => {
    setState({
      myAnswers: state.myAnswers.set(state.current, value),
      current: state.current + 1 > qaList.length ? qaList.length : state.current + 1,
    })

    if (state.current === qaList.length) {
      Alert({
        title: '恭喜您，您的测评已完成',
        btns: [
          {
            name: '查看报告',
            className: 'yellow',
            onClick: () => {
              navigateHook('/pay', { state: { orderNo, type, answer: state.myAnswers } })
            },
          },
        ],
      })
    }
  }

  const onBack = () => {
    navigateHook(-1)
  }

  const onFinished = () => {
    const isFinished = onCheckedCurrentIsFinished()

    if (!isFinished) {
      Toast('请完成当前题目')
      return
    }

    navigateHook('/pay', { state: { orderNo, type, answer: state.myAnswers } })
  }

  return (
    <Layout className="page-qa" useBottomSafeArea={false}>
      <Layout.Body className="body">
        <h1>MBTI职业性格测试题</h1>
        <h6>每年近3000万人参与</h6>
        <h6>被广泛应用于恋爱社交和职业发展等领域</h6>

        <div className="cell question">
          <div className="process">
            <div className="bar" style={{ width: `${(state.current / qaList.length) * 100}%` }} />
            <div className="total" style={{ left: `${(state.current / qaList.length) * 100}%` }}>
              {state.current}/{qaList.length}
            </div>
          </div>

          <div className="qa">
            <div className="title">{currentQA.title}</div>
            {currentQA.type === 'tip' && currentQA.img && (
              <div className="tip">
                <img src={currentQA.img} />
              </div>
            )}
            <div className="answers">
              {currentQA.answer.map((item) => (
                <div
                  className={`answer ${
                    state.myAnswers.get(state.current) === item.value ? 'checked' : ''
                  }`}
                  onClick={() => onAnswerClick(item.value, item.type)}
                  key={item.value}
                >
                  <div className="text">{item.title}</div>
                  <div className="bubble" />
                </div>
              ))}
            </div>
          </div>

          <div className="btns">
            {state.current === 1 ? (
              <Button type="secondary" className="btn" shrink onClick={onBack}>
                返回
              </Button>
            ) : (
              <Button type="secondary" className="btn" shrink onClick={onPrev}>
                上一题
              </Button>
            )}

            {state.current === 60 ? (
              <Button type="secondary" className="btn" shrink onClick={onFinished}>
                查看测试结果
              </Button>
            ) : (
              state.myAnswers.get(state.current) && (
                <Button type="secondary" className="btn" shrink onClick={onNext}>
                  下一题
                </Button>
              )
            )}
          </div>
        </div>

        <div className="cell">
          <strong>MBTI测试前须知：</strong>
          <p>1、参加测试的人员请务必诚实、独立地回答问题，只有如此，才能得到有效的结果。</p>
          <p>2、《性格分析报告》展示的是你的性格倾向，而不是你的知识、技能、经验。</p>
          <p>
            3、MBTI提供的性格类型描述仅供测试者确定自己的性格类型之用，性格类型没有好坏，只有不同。每一种性格特征都有其价值和优点，也有缺点和需要注意的地方。清楚地了解自己的性格优劣势，有利于更好地发挥自己的特长，而尽可能的在为人处事中避免自己性格中的劣势，更好地和他人相处，更好地作重要的决策。
          </p>
          <p>
            4、只要你是认真、真实地填写了测试问卷，那么通常情况下你都能得到一个确实和你的性格相匹配的类型。希望你能从中或多或少地获得一些有益的信息。
          </p>
        </div>
      </Layout.Body>
    </Layout>
  )
}

export default PageQA
