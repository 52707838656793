export const types = [
  { type: 'ISTJ', name: '侦探' },
  { type: 'ISFJ', name: '守护者' },
  { type: 'INFJ', name: '导师' },
  { type: 'INTJ', name: '专家' },
  { type: 'ISTP', name: '设计师' },
  { type: 'ISFP', name: '艺术家' },
  { type: 'INFP', name: '治愈者' },
  { type: 'INTP', name: '手艺人' },
  { type: 'ESTP', name: '引领者' },
  { type: 'ESFP', name: '表演者' },
  { type: 'ENFP', name: '演讲家' },
  { type: 'ENTP', name: '智多星' },
  { type: 'ESTJ', name: '管家' },
  { type: 'ESFJ', name: '调解者' },
  { type: 'ENFJ', name: '教练' },
  { type: 'ENTJ', name: '统帅' },
]
